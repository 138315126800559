/*global Blob */
import {Tooltip} from "bootstrap";
import {saveAs} from "file-saver";
import Logger from "js-logger";
import ko from "knockout";
import logging from "meta-client/modules/logging";
import objects from "meta-client/modules/objects";
import metaProtocol from "meta-core/modules/protocol";
import model from "portal-core/modules/model";
import coreViewmodel from "portal-core/modules/viewmodel";
import _ from "underscore";
import dates from "util-web/modules/dates";
import i18next from "util-web/modules/i18next";
import nav from "util-web/modules/nav";
import ui from "util-web/modules/ui";
import template from "./beleg.html";

const LOG = Logger.get("portal-web/component/beleg");
const COLORS = ["#0000CD", "#008000", "#008B8B", "#6B8E23", "#800000", "#8B008B", "#A0522D", "#C71585", "#B22222", "#FF7F50"];
const CSV_SEPARATOR = ";";
const PDF_CANVAS_ID = "belegPdf";
let colorIndex = 0;
const getNextColor = function () {
    const color = COLORS[colorIndex];
    colorIndex = colorIndex + 1;
    if (COLORS.length <= colorIndex) {
        colorIndex = 0;
    }
    return color;
};
const sanitizeCsv = function (s) {
    if (_.isEmpty(s)) {
        return "";
    }
    return s.replace(/[,’]/g, "");
};

export default Object.freeze({
    template,
    viewModel: {
        createViewModel: function ({viewmodel}) {
            const downloadTimer = logging.newMetricTimer(viewmodel.client, "portal-web.beleg.download");
            const searchTimer = logging.newMetricTimer(viewmodel.client, "portal-web.beleg.search");
            const zipDownloadTimer = logging.newMetricTimer(viewmodel.client, "portal-web.beleg.zipDownload");
            const vm = {
                activeTab: ko.observable(),
                closeTitle: i18next.pureComputedT("beleg_close"),
                i18next,
                navigateToTab(tab, state = null) {
                    return viewmodel.navigateToBeleg({state, tab});
                },
                pageNavLabel: i18next.pureComputedT("page_nav"),
                viewmodel
            };

            vm.reset = function () {
                vm.belegObjects.clear();
                vm.buchungObjects.clear();
                vm.resetSearchQuery();
                vm.closeBeleg();
            };
            viewmodel.onEigbLoad(vm.reset);

            vm.belegObjects = objects({
                toVm: (meta) => coreViewmodel.toBelegVm(meta, vm.viewmodel.resolveBuchhaltung),
                typeId: model.BELEG_TYPE_ID
            });
            vm.buchungObjects = objects({
                toVm: (meta) => coreViewmodel.toBuchungVm(getNextColor, meta, vm.viewmodel.resolveBuchhaltung),
                typeId: model.BUCHUNG_TYPE_ID
            });

            vm.isSearching = ko.pureComputed(function () {
                return vm.belegObjects.isSearching() || vm.buchungObjects.isSearching();
            });

            vm.hasSearchQueryFocus = ko.observable(false);
            vm.searchQuery = ko.observable();
            vm.isSearchQueryValid = ko.pureComputed(() => _.isEmpty(vm.searchQuery()) === false);
            vm.resetSearchQuery = () => vm.searchQuery(undefined);

            vm.searchIncludeBelegnr = ko.observable(true);
            vm.searchIncludeBewegungstext = ko.observable(true);
            vm.searchIncludeBuchId = ko.observable(true);
            vm.searchIncludeKonto = ko.observable(true);
            vm.searchIncludeKg2 = ko.observable(true);
            vm.searchIncludeKreditor = ko.observable(true);
            vm.searchQueryPlaceholder = ko.pureComputed(function () {
                const fields = [];
                if (vm.searchIncludeBelegnr()) {
                    fields.push("belegnr");
                }
                if (vm.searchIncludeBewegungstext()) {
                    fields.push("bewegungstext");
                }
                if (vm.searchIncludeBuchId()) {
                    fields.push("buchId");
                }
                if (vm.searchIncludeKonto()) {
                    fields.push("konto");
                }
                if (vm.searchIncludeKg2()) {
                    fields.push("kg2");
                }
                if (vm.searchIncludeKreditor()) {
                    fields.push("kreditor");
                }
                if (fields.length <= 0) {
                    return i18next.pureComputedT("search_placeholder_invalid");
                }
                return i18next.pureComputedT("search_placeholder", {fields: fields.map((f) => `$t(${f})`).join(", ")})();
            });

            vm.generateUserQuery = function () {
                const query = vm.searchQuery()?.trim();
                if (_.isEmpty(query)) {
                    return;
                }
                const queryParts = query.split(",").map((s) => s.trim());
                const isFiniteNumber = queryParts.length === queryParts.filter((s) => _.isFinite(parseInt(queryParts[0]))).length;
                const fields = [];
                if (isFiniteNumber && vm.searchIncludeBelegnr()) {
                    fields.push("belNr");
                }
                if (vm.searchIncludeBewegungstext()) {
                    fields.push("text");
                }
                if (isFiniteNumber && vm.searchIncludeBuchId()) {
                    fields.push("buchId");
                }
                if (vm.searchIncludeKonto()) {
                    fields.push("kontoBez");
                    fields.push("kontoNr");
                }
                if (vm.searchIncludeKg2()) {
                    fields.push("kg2");
                    fields.push("kg2Bez");
                }
                if (vm.searchIncludeKreditor()) {
                    fields.push("kreditor");
                    fields.push("kredRef");
                }
                if (_.isEmpty(fields)) {
                    return;
                }
                const fieldValue = queryParts.map(function (queryPart) {
                    const escaped = (
                        (isFiniteNumber === false && 0 < queryPart.indexOf(" ") && queryPart.indexOf("\"") < 0)
                            ? `"${queryPart}"`
                            : queryPart
                    );
                    return (isFiniteNumber || escaped.indexOf("\"") === 0)
                        ? escaped
                        : `${escaped}*`;
                }).join(" OR ");
                const userQuery = "(" + fields.map((f) => `${f}:(${fieldValue})`).join(" OR ") + ")";
                LOG.debug(`generateUserQuery, userQuery=${userQuery}`);
                return userQuery;
            };

            vm.hasEigbFilterFocus = ko.observable(false);
            vm.focusEigbFilter = function () {
                vm.hasEigbFilterFocus(true);
                return true;
            };

            vm.hasLiegbFilterFocus = ko.observable(false);
            vm.focusLiegbFilter = function () {
                vm.hasLiegbFilterFocus(true);
                return true;
            };

            vm.filterGeschaeftsjahr = ko.observable();
            vm.validatedFilterGeschaeftsjahr = ko.pureComputed(function () {
                const filterGeschaeftsjahr = vm.filterGeschaeftsjahr();
                if (_.isFinite(filterGeschaeftsjahr)) {
                    return filterGeschaeftsjahr;
                }
            });
            vm.filterGeschaeftsjahrClear = function () {
                vm.filterGeschaeftsjahr(undefined);
            };

            vm.filterDateFrom = ko.observable();
            vm.validatedFilterDateFrom = ko.pureComputed(function () {
                const filterDateFrom = dates.parseDateTimeInputs(vm.filterDateFrom, undefined, true);
                if (dates.isValidDateTime(filterDateFrom)) {
                    return dates.toDateString(filterDateFrom);
                }
            });
            vm.filterDateFromClear = function () {
                vm.filterDateFrom(undefined);
            };

            vm.filterDateTo = ko.observable();
            vm.validatedFilterDateTo = ko.pureComputed(function () {
                const filterDateTo = dates.parseDateTimeInputs(vm.filterDateTo, undefined, true);
                if (dates.isValidDateTime(filterDateTo)) {
                    return dates.toDateString(filterDateTo);
                }
            });
            vm.filterDateToClear = function () {
                vm.filterDateTo(undefined);
            };

            vm.filterBetragFrom = ko.observable();
            vm.validatedFilterBetragFrom = ko.pureComputed(function () {
                const filterBetragFrom = vm.filterBetragFrom();
                if (_.isEmpty(filterBetragFrom) === false) {
                    return filterBetragFrom;
                }
            });
            vm.filterBetragFromClear = function () {
                vm.filterBetragFrom(undefined);
            };

            vm.filterBetragTo = ko.observable();
            vm.validatedFilterBetragTo = ko.pureComputed(function () {
                const filterBetragTo = vm.filterBetragTo();
                if (_.isEmpty(filterBetragTo) === false) {
                    return filterBetragTo;
                }
            });
            vm.filterBetragToClear = function () {
                vm.filterBetragTo(undefined);
            };

            vm.searchParamsBeleg = ko.observable();
            vm.searchParamsBuchung = ko.observable();

            vm.processSearch = function (activeObjects, queryParams) {
                let timerContext;
                return searchTimer.time().then(function (context) {
                    timerContext = context;
                    return activeObjects.processSearch(vm.viewmodel.executeSearch(
                        activeObjects.searchCursor,
                        queryParams
                    ));
                }).catch(function (exc) {
                    LOG.warn("processSearch failed", exc);
                }).then(function (reply) {
                    timerContext.stop();
                    return reply?.metas?.length ?? 0;
                });
            };

            vm.searchInternal = function () {
                vm.belegObjects.clear();
                vm.buchungObjects.clear();
                const buchId = _.get(nav.currentState(), "buchId");
                const hasBuchId = Boolean(buchId);
                const queryParts = [];
                if (hasBuchId) {
                    queryParts.push(`buchId:${buchId}`); // TODO only search other tab?
                } else {
                    queryParts.push(vm.generateUserQuery());
                    queryParts.push(vm.viewmodel.generateFilterLiegbQuery());
                    const filterGeschaeftsjahr = vm.validatedFilterGeschaeftsjahr();
                    if (filterGeschaeftsjahr) {
                        queryParts.push(`geschaeftsjahr:${filterGeschaeftsjahr}`);
                    }
                    const filterBetragFrom = vm.validatedFilterBetragFrom();
                    const filterBetragTo = vm.validatedFilterBetragTo();
                    if (filterBetragFrom || filterBetragTo) {
                        const betragRange = `[${filterBetragFrom || 0} TO ${filterBetragTo || Number.MAX_SAFE_INTEGER}]`;
                        queryParts.push(`(betrag:${betragRange} OR soll:${betragRange} OR haben:${betragRange})`);
                    }
                }
                let finalBelegQuery = vm.belegObjects.newSearchQuery(queryParts);
                finalBelegQuery = (
                    _.isEmpty(finalBelegQuery)
                        ? undefined
                        : finalBelegQuery
                );
                vm.searchParamsBeleg({
                    dateField: "belDatum",
                    dateFrom: vm.validatedFilterDateFrom(),
                    dateTo: vm.validatedFilterDateTo(),
                    query: finalBelegQuery
                });
                let finalBuchungQuery = vm.buchungObjects.newSearchQuery(queryParts);
                finalBuchungQuery = (
                    _.isEmpty(finalBuchungQuery)
                        ? undefined
                        : finalBuchungQuery
                );
                vm.searchParamsBuchung({
                    dateField: "buchDatum",
                    dateFrom: vm.validatedFilterDateFrom(),
                    dateTo: vm.validatedFilterDateTo(),
                    query: finalBuchungQuery
                });
                return Promise.all([
                    vm.processSearch(vm.belegObjects, vm.searchParamsBeleg()),
                    vm.processSearch(vm.buchungObjects, vm.searchParamsBuchung())
                ]).then(function (results) {
                    const belegCount = results[0];
                    const buchungCount = results[1];
                    if (vm.viewmodel.BELEG_TABS.BELEGE === vm.activeTab() && 0 < buchungCount && belegCount === 0) {
                        return vm.navigateToTab(vm.viewmodel.BELEG_TABS.BUCHUNGEN);
                    }
                    if (vm.viewmodel.BELEG_TABS.BUCHUNGEN === vm.activeTab() && buchungCount === 0 && 0 < belegCount) {
                        return vm.navigateToTab(vm.viewmodel.BELEG_TABS.BELEGE);
                    }
                });
            };

            vm.search = function (form) {
                if (ui.validateForm(form) === false) {
                    return;
                }
                return vm.searchInternal().then(function () {
                    ui.resetForm(form);
                });
            };

            vm.loadMore = function () {
                if (vm.viewmodel.BELEG_TABS.BELEGE === vm.activeTab()) {
                    return vm.processSearch(vm.belegObjects, vm.searchParamsBeleg());
                }
                return vm.processSearch(vm.buchungObjects, vm.searchParamsBuchung());
            };

            vm.generateCsvHeader = function () {
                if (vm.viewmodel.BELEG_TABS.BELEGE === vm.activeTab()) {
                    return [
                        i18next.t("belegnr"),
                        i18next.t("belegdatum"),
                        i18next.t("eigb"),
                        i18next.t("liegb"),
                        i18next.t("kreditor"),
                        i18next.t("betrag")
                    ].join(CSV_SEPARATOR);
                }
                return [
                    i18next.t("buchId"),
                    i18next.t("buchungsdatum"),
                    i18next.t("eigb"),
                    i18next.t("liegb"),
                    i18next.t("kreditor"),
                    i18next.t("kg2"),
                    i18next.t("konto"),
                    i18next.t("bewegungstext"),
                    i18next.t("soll"),
                    i18next.t("haben")
                ].join(CSV_SEPARATOR);
            };

            vm.generateCsvRecord = function (objectVm) {
                if (vm.viewmodel.BELEG_TABS.BELEGE === vm.activeTab()) {
                    return [
                        objectVm.belNr,
                        objectVm.belDatumFormatted,
                        objectVm.eigb,
                        objectVm.liegb,
                        sanitizeCsv(objectVm.kreditor),
                        sanitizeCsv(objectVm.betragFormatted)
                    ].join(CSV_SEPARATOR);
                }
                return [
                    objectVm.buchId,
                    objectVm.buchDatumFormatted,
                    objectVm.eigb,
                    objectVm.liegb,
                    sanitizeCsv(objectVm.kreditor),
                    objectVm.kg2,
                    objectVm.kontoNr,
                    sanitizeCsv(objectVm.text),
                    sanitizeCsv(objectVm.sollFormatted),
                    sanitizeCsv(objectVm.habenFormatted)
                ].join(CSV_SEPARATOR);
            };

            vm.downloadCsv = function () {
                const activeObjects = (
                    vm.viewmodel.BELEG_TABS.BELEGE === vm.activeTab()
                        ? vm.belegObjects
                        : vm.buchungObjects
                );
                const fileName = `${vm.activeTab()}_export-${dates.now().toMillis()}.csv`;
                const csvRecords = [vm.generateCsvHeader()];
                activeObjects.objects().forEach(function (objectVm) {
                    csvRecords.push(vm.generateCsvRecord(objectVm));
                });
                const csvContent = csvRecords.join("\n");
                saveAs(new Blob([csvContent], {type: "text/csv;charset=utf-8"}), fileName);
            };

            vm.downloadZip = function () {
                const activeObjects = (
                    vm.viewmodel.BELEG_TABS.BELEGE === vm.activeTab()
                        ? vm.belegObjects
                        : vm.buchungObjects
                );
                Tooltip.getInstance(document.getElementById("belegeDownloadZip"))?.hide();
                Tooltip.getInstance(document.getElementById("buchungenDownloadZip"))?.hide();
                return zipDownloadTimer.time().then(function (context) {
                    const keys = activeObjects.objects().map((objectVm) => objectVm.fileKey).filter((key) => _.isEmpty(key) === false);
                    return vm.viewmodel.zipFiles(context, keys);
                });
            };

            vm.searchBelegBuchung = function (beleg) {
                return vm.navigateToTab(vm.viewmodel.BELEG_TABS.BUCHUNGEN, {buchId: beleg.buchId, search: true});
            };

            vm.searchBuchungBeleg = function (buchung) {
                return vm.navigateToTab(vm.viewmodel.BELEG_TABS.BELEGE, {buchId: buchung.buchId, search: true});
            };

            vm.betragTotal = ko.pureComputed(function () {
                const vms = vm.belegObjects.objects();
                if (vms.length <= 0) {
                    return;
                }
                return coreViewmodel.formatCurrency(vms.reduce((total, belegVm) => total + belegVm.betrag, 0));
            });

            vm.habenTotal = ko.pureComputed(function () {
                const vms = vm.buchungObjects.objects();
                if (vms.length <= 0) {
                    return;
                }
                return coreViewmodel.formatCurrency(vms.reduce((total, buchungVm) => total + buchungVm.haben, 0));
            });

            vm.sollTotal = ko.pureComputed(function () {
                const vms = vm.buchungObjects.objects();
                if (vms.length <= 0) {
                    return;
                }
                return coreViewmodel.formatCurrency(vms.reduce((total, buchungVm) => total + buchungVm.soll, 0));
            });

            vm.isLoadingBeleg = ko.observable(false);
            vm.activeBeleg = ko.observable();
            vm.activeBuchId = ko.pureComputed(function () {
                const activeBeleg = vm.activeBeleg();
                if (activeBeleg) {
                    return activeBeleg.buchId;
                }
                return -1;
            });
            vm.hasBelegPdf = ko.observable(false);
            vm.pdfPages = ko.observableArray();
            vm.downloadCache = new Map();
            vm.showFullscreen = ko.observable(false);

            vm.renderActiveBelegPdf = function () {
                const download = vm.downloadCache.get(vm.activeBeleg()?.fileKey);
                if (download) {
                    return vm.viewmodel.renderPdfDocument(download.buffer, PDF_CANVAS_ID, vm.pdfPages);
                }
            };

            const lazyRender = _.debounce(() => vm.renderActiveBelegPdf(), 500);
            window.addEventListener("resize", () => lazyRender());

            vm.showBeleg = function (beleg) {
                vm.activeBeleg(beleg);
                vm.hasBelegPdf(_.isEmpty(beleg.fileKey) === false);
                if (vm.hasBelegPdf() === false) {
                    return Promise.resolve(undefined);
                }
                if (vm.downloadCache.has(beleg.fileKey)) {
                    return vm.renderActiveBelegPdf();
                }
                vm.isLoadingBeleg(true);
                let timerContext;
                return downloadTimer.time().then(function (context) {
                    timerContext = context;
                    return vm.viewmodel.client.downloadAwait(metaProtocol.downloadRequest({
                        key: beleg.fileKey
                    }));
                }).then(function (downloadReply) {
                    vm.viewmodel.errorReply(undefined);
                    vm.downloadCache.set(beleg.fileKey, downloadReply);
                    return vm.renderActiveBelegPdf();
                }, function (exc) {
                    vm.viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
                    LOG.warn("showBeleg failed", exc);
                }).then(function () {
                    vm.isLoadingBeleg(false);
                    return timerContext.stop();
                });
            };

            vm.toggleFullscreen = function () {
                vm.showFullscreen(vm.showFullscreen() === false);
                return vm.renderActiveBelegPdf();
            };

            vm.scrollToPage = function (page) {
                document.getElementById(page.pageId).scrollIntoView();
            };

            vm.closeBeleg = function () {
                vm.activeBeleg(undefined);
                vm.showFullscreen(false);
            };

            vm.downloadBeleg = function () {
                const download = vm.downloadCache.get(vm.activeBeleg().fileKey);
                saveAs(new Blob([download.buffer], {type: "application/pdf"}), download.file.name);
            };

            vm.activateAnsprechpartner = function () {
                const beleg = vm.activeBeleg();
                const ansprechpartner = vm.viewmodel.resolveAnsprechpartner(beleg);
                if (ansprechpartner) {
                    vm.viewmodel.activeAnsprechpartner(ansprechpartner);
                }
            };

            vm.onNavUpdate = function (tab) {
                const activeTab = (
                    _.isEmpty(tab)
                        ? vm.viewmodel.BELEG_TABS.BELEGE
                        : tab
                );
                LOG.debug(`onNavUpdate, activeTab=${activeTab}`);
                vm.activeTab(activeTab);
                ko.tasks.schedule(() => vm.hasSearchQueryFocus(true));
                if (_.has(nav.currentState(), "search")) {
                    return vm.searchInternal();
                }
            };

            nav.register({
                id: vm.viewmodel.COMPONENTS.BELEG,
                onUpdate: vm.onNavUpdate
            });

            ko.when(() => 0 < vm.viewmodel.eigBuchhaltungen().length && 0 < vm.viewmodel.geschaeftsjahre().length).then(function () {
                vm.filterGeschaeftsjahr(viewmodel.geschaeftsjahre()[0]);
                vm.hasSearchQueryFocus(true);
            });

            return vm;
        }
    }
});
