/*global */
import {DateTime} from "luxon";
import metaModel from "meta-core/modules/model";
import ext from "util-web/modules/ext";

const model = {};

model.ADMIN_LOAD_EIGB_ACTION_ID = "admeigb";
model.ADMIN_LOAD_USER_ACTION_ID = "admuser";
model.BELEGE_IMPORT_ACTION_ID = "impbelege";
model.BUCHHALTUNGEN_IMPORT_ACTION_ID = "impbuchh";
model.DOKUMENTE_IMPORT_ACTION_ID = "impdokumente";
model.FREIGABE_APPROVE_ACTION_ID = "freiapp";
model.FREIGABE_CREATE_ACTION_ID = "freicrt";
model.FREIGABE_EXPORT_ACTION_ID = "freiexp";
model.FREIGABE_RESEND_ACTION_ID = "freires";
model.FREIGABE_REVOKE_ACTION_ID = "freirvk";
model.FREIGABE_VALIDATE_ACTION_ID = "freival";
model.FREIGABECODE_GENERATE_ACTION_ID = "freigen";
model.PORTALPLUS_CANCEL_ACTION_ID = "arcancel";
model.PORTALPLUS_ENABLE_ACTION_ID = "arcenable";
model.PORTALPLUS_EXPORT_ACTION_ID = "arexp";
model.READ_DOKUMENT_ACTION_ID = "read";
model.SEARCH_ACTION_ID = "search";
model.SEND_IMPORT_EMAIL_ACTION_ID = "sndimp";
model.UPDATE_DOK_TYPES_ACTION_ID = "impdoktypes";
model.ZIP_FILES_ACTION_ID = "zipfiles";
model.ZIP_GESCHAEFTSJAHR_ACTION_ID = "zipjahr";

model.BELEG_TYPE_ID = "beleg";
model.BUCHUNG_TYPE_ID = "buchung";
model.DOKUMENT_TYPE_ID = "dokument";
model.EIGBUCHHALTUNG_TYPE_ID = "eigb";
model.FREIGABE_TYPE_ID = "frei";
model.PORTAL_PLUS_ABO_TYPE_ID = "ppabo";
model.ZIPGESCHAEFTSJAHR_TYPE_ID = "zipy";

// Actions

model.adminLoadEigbActionParam = function ({groups}) {
    return Object.freeze(
        {groups}
    );
};

model.adminLoadUserActionParam = function ({user}) {
    return Object.freeze(
        {user}
    );
};

model.freigabeApproveActionParam = function ({
    codes = null,
    locale,
    token = null,
    user = null
}) {
    return Object.freeze(
        {codes, locale, token, user}
    );
};

model.freigabeApproveActionCreateUser = function ({
    email,
    otp = null,
    otpType,
    password,
    user
}) {
    return Object.freeze(
        {email, otp, otpType, password, user}
    );
};

model.freigabeCreateActionParam = function ({description, eigbIds, email, locale}) {
    return Object.freeze(
        {description, eigbIds, email, locale}
    );
};

model.freigabeResendActionParam = function ({email, locale, metaId}) {
    return Object.freeze(
        {email, locale, metaId}
    );
};

model.freigabeRevokeActionParam = function ({metaId}) {
    return Object.freeze(
        {metaId}
    );
};

model.freigabeValidateActionParam = function ({
    codes = null,
    token = null
}) {
    return Object.freeze(
        {codes, token}
    );
};

model.portalPlusCancelActionParam = function ({
    eigbIds
}) {
    return Object.freeze(
        {eigbIds}
    );
};

model.portalPlusEnableActionParam = function ({
    eigbIds
}) {
    return Object.freeze(
        {eigbIds}
    );
};

model.readDokumentActionParam = function ({metaIds}) {
    return Object.freeze(
        {metaIds}
    );
};

model.searchActionParam = function ({
    cursor = null,
    dateField,
    dateFrom = null,
    dateTo = null,
    eigbIds,
    persIds = null,
    query = null
}) {
    return Object.freeze(
        {cursor, dateField, dateFrom, dateTo, eigbIds, persIds, query}
    );
};

model.searchActionResult = function ({reply}) {
    return Object.freeze(
        {reply}
    );
};

model.zipFilesActionParam = function ({keys}) {
    return Object.freeze(
        {keys}
    );
};

model.zipGeschaeftsjahrActionParam = function ({eigbId, persId, year}) {
    return Object.freeze(
        {eigbId, persId, year}
    );
};

// Types

model.ansprechpartner = function ({rolleAdr, rolleAnr, rolleBez, rolleEmail, rolleId, rolleName, rolleTel}) {
    return Object.freeze(
        {rolleAdr, rolleAnr, rolleBez, rolleEmail, rolleId, rolleName, rolleTel}
    );
};

model.beleg = function ({
    belArt,
    belArtCd,
    belDatum,
    belNr = 0,
    betrag,
    betragTotal,
    buchId,
    eigbId,
    fileKey = null,
    geschaeftsjahr,
    kredRef = null,
    kreditor = null,
    liegbId = 0
}) {
    return Object.freeze(
        {belArt, belArtCd, belDatum, belNr, betrag, betragTotal, buchId, eigbId, fileKey, geschaeftsjahr, kredRef, kreditor, liegbId}
    );
};

model.bewegung = function ({
    eigbId,
    haben = 0,
    kg1,
    kg1Bez,
    kg2,
    kg2Bez,
    kontoBez,
    kontoNr,
    liegbId = 0,
    soll = 0,
    text = null
}) {
    return Object.freeze(
        {eigbId, haben, kg1, kg1Bez, kg2, kg2Bez, kontoBez, kontoNr, liegbId, soll, text}
    );
};

model.buchung = function ({
    belNr = 0,
    bewegungen,
    buchDatum,
    buchId,
    fileKey = null,
    geschaeftsjahr,
    kredRef = null,
    kreditor = null
}) {
    return Object.freeze(
        {belNr, bewegungen, buchDatum, buchId, fileKey, geschaeftsjahr, kredRef, kreditor}
    );
};

model.dokument = function ({
    bez,
    dokDatum,
    dokId,
    dokType,
    eigbId,
    fileKey,
    geschaeftsjahr,
    liegbId = 0,
    persId,
    read
}) {
    return Object.freeze(
        {bez, dokDatum, dokId, dokType, eigbId, fileKey, geschaeftsjahr, liegbId, persId, read}
    );
};

model.eigBuchhaltung = function ({
    ansprechpartner,
    belegTimestamp = null,
    bez,
    bhTeam = null,
    bhType,
    buchhaltungen,
    dokumentTimestamp = null,
    eigbId,
    geschaeftsjahre,
    gueltigAb,
    gueltigBis = null,
    importMessage = null,
    importNotification = false,
    personen,
    ppOverride,
    ref,
    vwStandort = null,
    vwTeam = null
}) {
    return Object.freeze(
        {
            ansprechpartner,
            belegTimestamp,
            bez,
            bhTeam,
            bhType,
            buchhaltungen,
            dokumentTimestamp,
            eigbId,
            geschaeftsjahre,
            gueltigAb,
            gueltigBis,
            importMessage,
            importNotification,
            personen,
            ppOverride,
            ref,
            vwStandort,
            vwTeam
        }
    );
};

model.eigBuchhaltungType = Object.freeze({
    AEG: "AEG",
    MEG: "MEG",
    STEWE: "STEWE"
});

model.geschaeftsjahr = function ({
    geschaeftsjahr,
    geschaeftsjahrAbgeschlossenBis,
    geschaeftsjahrBegin,
    geschaeftsjahrEnde,
    geschaeftsjahrUploads
}) {
    return Object.freeze(
        {geschaeftsjahr, geschaeftsjahrAbgeschlossenBis, geschaeftsjahrBegin, geschaeftsjahrEnde, geschaeftsjahrUploads}
    );
};

model.freigabe = function ({
    admin,
    code,
    createSource,
    description = null,
    entries,
    revokeSource,
    state,
    timestamp,
    truster = null,
    user = null
}) {
    return Object.freeze(
        {admin, code, createSource, description, entries, revokeSource, state, timestamp, truster, user}
    );
};

model.freigabeEntry = function ({eigbId, persId}) {
    return Object.freeze(
        {eigbId, persId}
    );
};

model.freigabe.source = Object.freeze({
    ADMIN: "ADMIN",
    SYSTEM: "SYSTEM",
    USER: "USER"
});

model.freigabe.state = Object.freeze({
    APPROVED: "APPROVED",
    PENDING: "PENDING",
    REVOKED: "REVOKED"
});

model.i18nBezeichnung = function ({de, en}) {
    return Object.freeze(
        {de, en}
    );
};

model.liegBuchhaltung = function ({
    ansprechpartner,
    bez,
    gueltigAb,
    gueltigBis = null,
    liegbId,
    ref
}) {
    return Object.freeze(
        {ansprechpartner, bez, gueltigAb, gueltigBis, liegbId, ref}
    );
};

model.person = function ({gueltigAb, gueltigBis, persId, persName, persRef}) {
    return Object.freeze(
        {gueltigAb, gueltigBis, persId, persName, persRef}
    );
};

model.portalPlusAbo = function ({
    beginDate,
    cancelDate = null,
    eigbId,
    endeDate = null,
    user = null
}) {
    return Object.freeze(
        {beginDate, cancelDate, eigbId, endeDate, user}
    );
};

model.zipGeschaeftsjahr = function ({
    eigbId,
    expiration = null,
    fileKey = null,
    persId,
    size = null,
    state,
    timestamp,
    url = null,
    user,
    year
}) {
    return Object.freeze(
        {eigbId, expiration, fileKey, persId, size, state, timestamp, url, user, year}
    );
};

model.zipGeschaeftsjahr.state = Object.freeze({
    EMPTY: "EMPTY",
    ERROR: "ERROR",
    PENDING: "PENDING",
    PROCESSING: "PROCESSING",
    READY: "READY"
});

model.EIGBUCHHALTUNG_GROUP_PREFIX = model.EIGBUCHHALTUNG_TYPE_ID;
model.EIGBUCHHALTUNG_ADMIN_GROUP_PREFIX = model.EIGBUCHHALTUNG_GROUP_PREFIX + "adm";
model.SCHAEPI_MA_GROUP = "schaeppima";
model.PERSON_GROUP_PREFIX = "pers";
model.PP_MIN_DATE = DateTime.utc(1, 1, 1);

model.getAccessibleGeschaeftsjahrBegin = function (eigb) {
    const match = eigb.geschaeftsjahre.sort(ext.simpleSort.bind({
        property: "geschaeftsjahr",
        reverse: true
    })).find((geschaeftsjahr) => geschaeftsjahr.geschaeftsjahrUploads && geschaeftsjahr.geschaeftsjahrAbgeschlossenBis && geschaeftsjahr.geschaeftsjahrEnde.equals(geschaeftsjahr.geschaeftsjahrAbgeschlossenBis));
    return match
        ? match.geschaeftsjahrBegin
        : model.PP_MIN_DATE;
};

model.portalUserConfig = function ({
    email,
    ignoredDokTypes = null,
    locale
}) {
    return Object.freeze(
        {email, ignoredDokTypes, locale}
    );
};

const lucene = {
    EIGB_PURGE: ["value.ansprechpartner", "value.buchhaltungen", "value.geschaeftsjahre", "value.portalPlus"],
    EIGBUCHHALTUNG_QUERY: `${metaModel.lucene.META_OBJECT_QUERY} AND ${metaModel.OBJECT_TYPE_ID}:${model.EIGBUCHHALTUNG_TYPE_ID}`,
    FREIGABE_QUERY: `${metaModel.lucene.META_OBJECT_QUERY} AND ${metaModel.OBJECT_TYPE_ID}:${model.FREIGABE_TYPE_ID}`,
    newEigbIdQueryPart(eigbIds) {
        return metaModel.lucene.newMultiValueQuery(0, "eigbId", eigbIds)[0];
    },
    newEigbQuery(eigbIds) {
        const eigbIdQuery = (
            (0 < eigbIds.length && eigbIds.length + 2 <= metaModel.lucene.MAX_CLAUSE_COUNT)
                ? " AND " + lucene.newEigbIdQueryPart(eigbIds)
                : ""
        );
        return lucene.EIGBUCHHALTUNG_QUERY + eigbIdQuery;
    }
};

model.lucene = Object.freeze(lucene);

export default Object.freeze(model);
