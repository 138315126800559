<!-- portal-web/modules/component/freigabe -->
<div id="freigabe">
    <h1 class="display-6" data-bind="i18n: 'freigabe'"></h1>
    <p class="lead mb-3" data-bind="i18n: 'freigabe_intro'"></p>
    <div class="row">
        <div class="col-12 col-xxl-8 pe-xxl-5">
            <form class="mb-3 pe-1" data-bind="submit: search" id="freigabeSearch" novalidate>
                <div class="row g-3 mb-3">
                    <div class="col-12 col-lg-4">
                        <div class="d-flex justify-content-between mb-2">
                            <label class="form-label text-truncate" for="freigabeFilterEigb">
                                <i class="bi bi-journals"></i>
                                <span data-bind="i18n: 'filter_eigb'"></span>
                            </label>
                            <div data-bind="visible: 0 < viewmodel.filterEigbSelectedIds().length">
                                <button class="btn btn-schaeppi btn-sm"
                                    data-bind="click: viewmodel.resetEigbFilter"
                                    id="freigabeFilterEigbReset"
                                    type="button">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                        </div>
                        <div class="dropdown d-grid">
                            <button aria-expanded="false"
                                class="btn btn-secondary dropdown-toggle"
                                data-bind="click: focusEigbFilter"
                                data-bs-auto-close="outside"
                                data-bs-toggle="dropdown"
                                id="freigabeFilterEigb"
                                type="button">
                                <span data-bind="i18n: 'filter_selection_none', visible: viewmodel.filterEigbSelectedIds().length <= 0"></span>
                                <span data-bind="visible: 0 < viewmodel.filterEigbSelectedIds().length">
                                    <span class="badge rounded-pill bg-schaeppi"
                                        data-bind="text: viewmodel.filterEigbSelectedIds().length"></span>
                                    <span data-bind="i18n: 'filter_selection'"></span>
                                </span>
                            </button>
                            <div aria-labelledby="freigabeFilterEigb" class="dropdown-menu dropdown-menu-end p-3" style="min-width: 20rem;">
                                <a data-bind="click: viewmodel.selectAllEigbVisible, i18n: 'filter_select_all'"
                                    href="#"
                                    id="freigabeFilterEigbSelectAll"></a>
                                <div class="input-group mt-3">
                                    <input autocomplete="off" class="form-control" data-bind="
                                        attr: {placeholder: i18next.pureComputedT('filter_search')},
                                        hasFocus: hasEigbFilterFocus,
                                        textInput: viewmodel.filterEigbQuery
                                    " id="freigabeFilterEigbSearch" type="text">
                                    <button class="btn btn-outline-secondary"
                                        data-bind="click: viewmodel.resetEigbQuery"
                                        id="freigabeFilterEigbSearchReset"
                                        type="button">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                </div>
                                <div class="overflow-y-auto" data-bind="foreach: viewmodel.filterEigbVisible" style="max-height: 21rem;">
                                    <div class="form-check mb-0 mt-2">
                                        <input class="form-check-input" data-bind="
                                            attr: {id: 'freigabeFilterEigb' + eigbId},
                                            checked: selected
                                        " type="checkbox">
                                        <label class="form-check-label text-nowrap"
                                            data-bind="text: bez, attr: {for: 'freigabeFilterEigb' + eigbId}"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-4">
                        <div class="d-flex justify-content-between mb-2">
                            <label class="form-label" data-bind="i18n: 'freigabe_filter_state'" for="freigabeFilterState"></label>
                            <div data-bind="visible: validatedFilterState">
                                <button class="btn btn-schaeppi btn-sm"
                                    data-bind="click: filterStateClear"
                                    id="freigabeFilterStateReset"
                                    type="button">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                        </div>
                        <select class="form-select" data-bind="
                            options: stateOptions,
                            optionsCaption: '',
                            optionsText: 'name',
                            optionsValue: 'state',
                            value: filterState
                        " id="freigabeFilterState"></select>
                    </div>
                    <div class="col-6 col-lg-4">
                        <div class="d-flex justify-content-between mb-2">
                            <label class="form-label text-truncate"
                                data-bind="i18n: 'freigabe_filter_truster'"
                                for="freigabeFilterTruster"></label>
                            <div data-bind="visible: validatedFilterTruster()">
                                <button class="btn btn-schaeppi btn-sm"
                                    data-bind="click: filterTrusterClear"
                                    id="freigabeFilterTrusterReset"
                                    type="button">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                        </div>
                        <select class="form-select" data-bind="
                            options: trusterOptions,
                            optionsText: 'name',
                            optionsValue: 'truster',
                            value: filterTruster
                        " id="freigabeFilterTruster"></select>
                    </div>
                </div>
                <button class="btn btn-schaeppi" data-bind="busyButton: isLoadingFreigaben" type="submit">
                    <i class="bi bi-search"></i>
                    <span data-bind="i18n: 'freigabe_load'"></span>
                </button>
            </form>
            <div data-bind="if: 0 < freigabeNonAdminObjects().length">
                <h2 class="h4" data-bind="i18n: 'freigabe_non_admin'"></h2>
                <div class="table-responsive mb-5">
                    <table class="portal-table table table-bordered table-hover align-middle">
                        <thead>
                        <tr>
                            <th data-bind="i18n: 'freigabe_state'" style="width: 9rem;"></th>
                            <th data-bind="i18n: 'freigabe_timestamp'" style="width: 8rem;"></th>
                            <th data-bind="i18n: 'freigabe_truster'" style="width: 10rem;"></th>
                            <th>
                                <span data-bind="i18n: 'freigabe_description'"></span> &
                                <span data-bind="i18n: 'freigabe_entries'"></span>
                            </th>
                            <th data-bind="i18n: 'freigabe_user'" style="width: 10rem;"></th>
                            <th style="width: 11rem;"></th>
                        </tr>
                        </thead>
                        <tbody data-bind="foreach: freigabeNonAdminObjects">
                        <tr class="freigabe-row">
                            <td data-bind="css: {
                                    'text-success': $component.freigabeState.APPROVED === state(),
                                    'text-schaeppi': $component.freigabeState.PENDING === state(),
                                    'text-secondary': $component.freigabeState.REVOKED === state()
                            }">
                                <i class="bi" data-bind="css: {
                                    'bi-person-check-fill': $component.freigabeState.APPROVED === state(),
                                    'bi-person-plus-fill': $component.freigabeState.PENDING === state(),
                                    'bi-person-fill-slash': $component.freigabeState.REVOKED === state()
                                }"></i>
                                <span data-bind="i18n: 'freigabe_state_' + state()"></span>
                            </td>
                            <td data-bind="text: timestampFormatted"></td>
                            <td class="text-break" data-bind="text: truster"></td>
                            <td>
                                <p>
                                    <mark data-bind="text: description"></mark>
                                </p>
                                <ul class="list-unstyled mb-0" data-bind="foreach: entries">
                                    <li class="pb-2">
                                        <i class="bi bi-journals"></i>
                                        <span data-bind="attr: {title: eigbRef}, text: eigbBez"></span>
                                        <br>
                                        <small class="text-muted">
                                            <i class="bi bi-people"></i>
                                            <span data-bind="attr: {title: pers.persRef}, text: pers.persName"></span>
                                            (<span data-bind="i18n: {key: 'freigabe_person_gueltig_ab', options: {date: pers.gueltigAbFormatted}}"></span><span
                                            data-bind="
                                                i18n: {key: 'freigabe_person_gueltig_bis', options: {date: pers.gueltigBisFormatted}},
                                                visible: pers.gueltigBisFormatted
                                            "></span>)
                                        </small>
                                    </li>
                                </ul>
                            </td>
                            <td class="text-break" data-bind="text: user"></td>
                            <td>
                                <div class="d-flex flex-column">
                                    <button class="btn btn-outline-secondary btn-sm mb-2" data-bind="
                                        attr: {id: 'freigabeResend' + id},
                                        busyButton: $component.isResendingFreigabe,
                                        click: $component.activateFreigabeResend,
                                        visible: truster() === $component.user() && ($component.freigabeState.PENDING === state() || ($component.freigabeState.REVOKED === state() && Boolean(user()) === false))
                                    " type="button">
                                        <i class="bi bi-envelope-at"></i>
                                        <span data-bind="i18n: 'freigabe_resend'"></span>
                                    </button>
                                    <button class="btn btn-outline-secondary btn-sm" data-bind="
                                        attr: {id: 'freigabeRevoke' + id},
                                        busyButton: isRevoking,
                                        click: $component.revokeFreigabe,
                                        visible: $component.freigabeState.REVOKED !== state() && admin() === false
                                    " type="button">
                                        <i class="bi bi-person-x-fill"></i>
                                        <span data-bind="i18n: 'freigabe_revoke'"></span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div data-bind="if: 0 < freigabeAdminObjects().length">
                <h2 class="h4" data-bind="i18n: 'freigabe_admin'"></h2>
                <div class="table-responsive mb-0 mb-xxl-3">
                    <table class="portal-table table table-bordered table-hover align-middle">
                        <thead>
                        <tr>
                            <th data-bind="i18n: 'freigabe_state'" style="width: 9rem;"></th>
                            <th data-bind="i18n: 'freigabe_timestamp'" style="width: 18rem;"></th>
                            <th data-bind="i18n: 'freigabe_entries'"></th>
                            <th data-bind="i18n: 'freigabe_user'" style="width: 20rem;"></th>
                        </tr>
                        </thead>
                        <tbody data-bind="foreach: freigabeAdminObjects">
                        <tr class="freigabe-row">
                            <td data-bind="css: {
                                'text-success': $component.freigabeState.APPROVED === state(),
                                'text-schaeppi': $component.freigabeState.PENDING === state(),
                                'text-secondary': $component.freigabeState.REVOKED === state()
                            }">
                                <i class="bi" data-bind="css: {
                                    'bi-person-check-fill': $component.freigabeState.APPROVED === state(),
                                    'bi-person-plus-fill': $component.freigabeState.PENDING === state(),
                                    'bi-person-fill-slash': $component.freigabeState.REVOKED === state()
                                }"></i>
                                <span class="fw-bold" data-bind="i18n: 'freigabe_state_' + state()"></span>
                            </td>
                            <td data-bind="text: timestampFormatted"></td>
                            <td>
                                <ul class="list-unstyled mb-0" data-bind="foreach: entries">
                                    <li class="pb-2">
                                        <i class="bi bi-journals"></i>
                                        <span data-bind="attr: {title: eigbRef}, text: eigbBez"></span>
                                        <br>
                                        <small class="text-muted">
                                            <i class="bi bi-people"></i>
                                            <span data-bind="attr: {title: pers.persRef}, text: pers.persName"></span>
                                            (<span data-bind="i18n: {key: 'freigabe_person_gueltig_ab', options: {date: pers.gueltigAbFormatted}}"></span><span
                                            data-bind="
                                                i18n: {key: 'freigabe_person_gueltig_bis', options: {date: pers.gueltigBisFormatted}},
                                                visible: pers.gueltigBisFormatted
                                            "></span>)
                                        </small>
                                    </li>
                                </ul>
                            </td>
                            <td class="text-break" data-bind="text: user"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-12 col-xxl-4 mt-5 mt-xxl-0">
            <h2 class="h4" data-bind="i18n: 'freigabe_create'"></h2>
            <form class="mb-5" data-bind="submit: createFreigabe" id="freigabeCreate" novalidate>
                <fieldset data-bind="attr: {disabled: viewmodel.isSchaeppiUser()}">
                    <label class="form-label" data-bind="i18n: 'freigabe_eigb'"></label>
                    <div class="overflow-auto mb-2" data-bind="foreach: freigabeEigb" style="max-height: 10rem;">
                        <div class="form-check">
                            <input class="form-check-input" data-bind="
                                attr: {id: 'freigabeCreateFilterEigb' + eigbId},
                                checked: selected,
                                validity: 0 < $component.selectedEigb().length
                            " type="checkbox">
                            <label class="form-check-label text-nowrap" data-bind="
                                attr: {for: 'freigabeCreateFilterEigb' + eigbId},
                                text: bez
                            "></label>
                        </div>
                    </div>
                    <div class="mb-2">
                        <label class="form-label" data-bind="i18n: 'freigabe_email'" for="freigabeCreateEmail"></label>
                        <input aria-describedby="freigabeCreateEmailHelp"
                            class="form-control"
                            data-bind="textInput: freigabeEmail"
                            id="freigabeCreateEmail"
                            required
                            type="email">
                        <div class="form-text" data-bind="i18n: 'freigabe_email_help'" id="freigabeCreateEmailHelp"></div>
                    </div>
                    <div class="mb-2">
                        <label class="form-label" data-bind="i18n: 'freigabe_locale'" for="freigabeCreateLocale"></label>
                        <select class="form-select" data-bind="
                            options: viewmodel.localeOptions,
                            optionsText: 'text',
                            optionsValue: 'locale',
                            value: freigabeLocale
                        " id="freigabeCreateLocale"></select>
                    </div>
                    <div class="mb-2">
                        <label class="form-label" data-bind="i18n: 'freigabe_description'" for="freigabeCreateDescription"></label>
                        <input aria-describedby="freigabeCreateDescriptionHelp"
                            class="form-control"
                            data-bind="textInput: freigabeDescription"
                            id="freigabeCreateDescription"
                            required
                            type="text">
                        <div class="form-text"
                            data-bind="i18n: 'freigabe_create_description_help'"
                            id="freigabeCreateDescriptionHelp"></div>
                    </div>
                    <button class="btn btn-schaeppi" data-bind="busyButton: isCreatingFreigabe" type="submit">
                        <span data-bind="i18n: 'freigabe_create_submit'"></span>
                    </button>
                </fieldset>
            </form>
        </div>
    </div>
</div>
<div class="modal" data-bind="modal: activeFreigabeResend" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" id="freigabeResendModal">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title h4" data-bind="i18n: 'freigabe_resend'"></h1>
                <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
            </div>
            <form data-bind="submit: resendFreigabe" id="freigabeResend" novalidate>
                <div class="modal-body">
                    <p data-bind="i18n: 'freigabe_resend_lead'"></p>
                    <div class="mb-2">
                        <label class="form-label" data-bind="i18n: 'freigabe_email'" for="freigabeResendEmail"></label>
                        <input aria-describedby="freigabeResendEmailHelp" class="form-control" data-bind="
                            hasFocus: hasFreigabeResendEmailFocus,
                            textInput: freigabeResendEmail
                        " id="freigabeResendEmail" required type="email">
                        <div class="form-text" data-bind="i18n: 'freigabe_email_help'" id="freigabeResendEmailHelp"></div>
                    </div>
                    <div class="mb-2">
                        <label class="form-label" data-bind="i18n: 'freigabe_locale'" for="freigabeResendLocale"></label>
                        <select class="form-select" data-bind="
                            options: viewmodel.localeOptions,
                            optionsText: 'text',
                            optionsValue: 'locale',
                            value: freigabeResendLocale
                        " id="freigabeResendLocale"></select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-schaeppi" data-bind="busyButton: isResendingFreigabe" type="submit">
                        <span data-bind="i18n: 'freigabe_resend_submit'"></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
