/*global Blob */
import {Tooltip} from "bootstrap";
import {saveAs} from "file-saver";
import Logger from "js-logger";
import ko from "knockout";
import logging from "meta-client/modules/logging";
import objects from "meta-client/modules/objects";
import metaModel from "meta-core/modules/model";
import metaProtocol from "meta-core/modules/protocol";
import model from "portal-core/modules/model";
import coreViewmodel from "portal-core/modules/viewmodel";
import _ from "underscore";
import dates from "util-web/modules/dates";
import i18next from "util-web/modules/i18next";
import nav from "util-web/modules/nav";
import ui from "util-web/modules/ui";
import template from "./dokument.html";

const LOG = Logger.get("portal-web/component/dokument");
const PDF_CANVAS_ID = "dokumentPdf";
const READ_OPTIONS = Object.freeze({
    ALL: "ALL",
    READ: "READ",
    UNREAD: "UNREAD"
});

export default Object.freeze({
    template,
    viewModel: {
        createViewModel: function ({viewmodel}) {
            const downloadTimer = logging.newMetricTimer(viewmodel.client, "portal-web.dokument.download");
            const newDokumenteByMonthVm = function (dokumente, month) {
                return {
                    dokumente,
                    isSelectVisible: ko.observable(false),
                    month,
                    selected: ko.pureComputed({
                        read: function () {
                            return dokumente.some((dokVm) => dokVm.selected() === false) === false;
                        },
                        write: function (checked) {
                            dokumente.forEach(function (dokVm) {
                                dokVm.selected(checked);
                            });
                        }
                    })
                };
            };
            const searchTimer = logging.newMetricTimer(viewmodel.client, "portal-web.dokument.search");
            const zipDownloadTimer = logging.newMetricTimer(viewmodel.client, "portal-web.dokument.zipDownload");
            const vm = {
                closeTitle: i18next.pureComputedT("dokument_close"),
                i18next,
                pageNavLabel: i18next.pureComputedT("page_nav"),
                selectLabel: i18next.pureComputedT("dokument_select_month"),
                selectMonthLabel: i18next.pureComputedT("dokument_select"),
                viewmodel
            };

            vm.reset = function () {
                vm.dokumentObjects.clear();
                vm.closeDokument();
            };
            viewmodel.onEigbLoad(vm.reset);

            vm.dokumentObjects = objects({
                toVm: (meta) => coreViewmodel.toDokumentVm(meta, vm.viewmodel.resolveBuchhaltung),
                typeId: model.DOKUMENT_TYPE_ID
            });

            vm.dokumentObjectsByMonth = ko.pureComputed(function () {
                const dokumenteByMonth = new Map();
                vm.dokumentObjects.objects().forEach(function (dokVm) {
                    const dokDatum = dates.parseDateTime(dokVm.dokDatum);
                    const key = `${dokDatum.monthLong} ${dokDatum.year}`; // TODO i18n changes
                    if (dokumenteByMonth.has(key) === false) {
                        dokumenteByMonth.set(key, []);
                    }
                    dokumenteByMonth.get(key).push(dokVm);
                });
                const groupedDokumente = [];
                dokumenteByMonth.forEach(function (value, key) {
                    groupedDokumente.push(newDokumenteByMonthVm(value, key));
                });
                return groupedDokumente;
            });

            vm.hasSubmitFocus = ko.observable(false);

            vm.hasEigbFilterFocus = ko.observable(false);
            vm.focusEigbFilter = function () {
                vm.hasEigbFilterFocus(true);
                return true;
            };

            vm.hasLiegbFilterFocus = ko.observable(false);
            vm.focusLiegbFilter = function () {
                vm.hasLiegbFilterFocus(true);
                return true;
            };

            vm.hasPersFilterFocus = ko.observable(false);
            vm.focusPersFilter = function () {
                vm.hasPersFilterFocus(true);
                return true;
            };

            vm.filterGeschaeftsjahr = ko.observable();
            vm.validatedFilterGeschaeftsjahr = ko.pureComputed(function () {
                const filterGeschaeftsjahr = vm.filterGeschaeftsjahr();
                if (_.isFinite(filterGeschaeftsjahr)) {
                    return filterGeschaeftsjahr;
                }
            });
            vm.filterGeschaeftsjahrClear = () => vm.filterGeschaeftsjahr(undefined);

            vm.filterDateFrom = ko.observable();
            vm.validatedFilterDateFrom = ko.pureComputed(function () {
                const filterDateFrom = dates.parseDateTimeInputs(vm.filterDateFrom, undefined, true);
                if (dates.isValidDateTime(filterDateFrom)) {
                    return dates.toDateString(filterDateFrom);
                }
            });
            vm.filterDateFromClear = () => vm.filterDateFrom(undefined);

            vm.filterDateTo = ko.observable();
            vm.validatedFilterDateTo = ko.pureComputed(function () {
                const filterDateTo = dates.parseDateTimeInputs(vm.filterDateTo, undefined, true);
                if (dates.isValidDateTime(filterDateTo)) {
                    return dates.toDateString(filterDateTo);
                }
            });
            vm.filterDateToClear = () => vm.filterDateTo(undefined);

            vm.dokTypes = ko.pureComputed(function () {
                const dokTypes = vm.viewmodel.config.dokTypes;
                const ignoredDokTypes = vm.viewmodel.ignoredDokTypes();
                return dokTypes.filter((dokType) => ignoredDokTypes.includes(dokType) === false);
            });
            vm.filterDokType = ko.observable();
            vm.validatedFilterDokType = ko.pureComputed(function () {
                const filterDokType = vm.filterDokType();
                if (_.isEmpty(filterDokType) === false) {
                    return filterDokType;
                }
            });
            vm.filterDokTypeClear = () => vm.filterDokType(undefined);

            vm.readOptions = Object.keys(READ_OPTIONS).map(function (key) {
                return {
                    name: i18next.computedT("dokument_filter_read_" + key),
                    read: key
                };
            });
            vm.filterRead = ko.observable();
            vm.validatedFilterRead = ko.pureComputed(function () {
                const filterRead = vm.filterRead();
                if (_.isEmpty(filterRead) === false && filterRead !== READ_OPTIONS.ALL) {
                    return filterRead;
                }
            });
            vm.filterReadClear = () => vm.filterRead(READ_OPTIONS.ALL);

            vm.searchParams = ko.observable();

            vm.processSearch = function () {
                let timerContext;
                return searchTimer.time().then(function (context) {
                    timerContext = context;
                    return vm.dokumentObjects.processSearch(vm.viewmodel.executeSearch(
                        vm.dokumentObjects.searchCursor,
                        vm.searchParams()
                    ));
                }).catch(function (exc) {
                    LOG.warn("processSearch failed", exc);
                }).then(function () {
                    return timerContext.stop();
                });
            };

            vm.searchInternal = function () {
                vm.dokumentObjects.clear();
                const queryParts = [];
                queryParts.push(vm.viewmodel.generateFilterLiegbQuery());
                const filterGeschaeftsjahr = vm.validatedFilterGeschaeftsjahr();
                if (filterGeschaeftsjahr) {
                    queryParts.push(`geschaeftsjahr:${filterGeschaeftsjahr}`);
                }
                const filterDokType = vm.validatedFilterDokType();
                const ignoredDokTypes = vm.viewmodel.ignoredDokTypes();
                let ignoredDokTypesQueryPart = "";
                if (filterDokType) {
                    queryParts.push(`dokType:"${filterDokType}"`);
                } else if (_.isEmpty(ignoredDokTypes) === false) {
                    ignoredDokTypesQueryPart = " NOT " + metaModel.lucene.newMultiValueQuery(0, "dokType", ignoredDokTypes)[0];
                }
                const filterRead = vm.validatedFilterRead();
                if (filterRead) {
                    queryParts.push("read:" + (
                        READ_OPTIONS.READ === filterRead
                            ? "true"
                            : "false"
                    ));
                }
                let finalQuery = vm.dokumentObjects.newSearchQuery(queryParts) + ignoredDokTypesQueryPart;
                finalQuery = (
                    _.isEmpty(finalQuery)
                        ? undefined
                        : finalQuery
                );
                vm.searchParams({
                    dateField: "dokDatum",
                    dateFrom: vm.validatedFilterDateFrom(),
                    dateTo: vm.validatedFilterDateTo(),
                    persIds: vm.viewmodel.filterPersSelectedIds(),
                    query: finalQuery
                });
                return vm.processSearch();
            };

            vm.search = function (form) {
                if (ui.validateForm(form) === false) {
                    return;
                }
                return vm.searchInternal().then(function () {
                    ui.resetForm(form);
                });
            };
            vm.loadMore = function () {
                return vm.processSearch();
            };

            vm.downloadZip = function () {
                LOG.debug("downloadZip");
                Tooltip.getInstance(document.getElementById("dokumentDownloadZip"))?.hide();
                return zipDownloadTimer.time().then(function (context) {
                    const keys = vm.dokumentObjects.objects().map((dokVm) => dokVm.fileKey).filter((key) => _.isEmpty(key) === false);
                    return vm.viewmodel.zipFiles(context, keys);
                });
            };

            vm.noOfSelectedUnreadDocuments = ko.pureComputed(function () {
                const selected = vm.dokumentObjects.objects().filter((dokVm) => dokVm.selected() && dokVm.read() === false).length;
                return (
                    0 < selected
                        ? selected
                        : vm.dokumentObjects.objects().filter((dokVm) => dokVm.read() === false).length
                );
            });

            vm.noOfSelectedDocuments = ko.pureComputed(function () {
                const selected = vm.dokumentObjects.objects().filter((dokVm) => dokVm.selected()).length;
                return (
                    0 < selected
                        ? selected
                        : vm.dokumentObjects.objects().length
                );
            });

            vm.enableMonthSelect = function (dokumenteByMonth) {
                dokumenteByMonth.isSelectVisible(true);
            };

            vm.disableMonthSelect = function (dokumenteByMonth) {
                dokumenteByMonth.isSelectVisible(false);
            };

            vm.enableSelect = function (dokument) {
                dokument.isSelectVisible(true);
            };

            vm.disableSelect = function (dokument) {
                dokument.isSelectVisible(false);
            };

            vm.executeReadDokumenteAction = function (metaIds) {
                return vm.viewmodel.client.execute(metaProtocol.executeRequest({
                    actionId: model.READ_DOKUMENT_ACTION_ID,
                    param: model.readDokumentActionParam({metaIds})
                }));
            };

            vm.markDokumenteRead = function () {
                let markRead = vm.dokumentObjects.objects().filter(function (dokVm) {
                    return dokVm.selected();
                });
                if (markRead.length <= 0) {
                    markRead = vm.dokumentObjects.objects().filter(function (dokVm) {
                        return dokVm.read() === false;
                    });
                }
                const metaIds = [];
                markRead.forEach(function (dokVm) {
                    dokVm.read(true);
                    dokVm.selected(false);
                    metaIds.push(dokVm.id);
                });
                return vm.executeReadDokumenteAction(metaIds);
            };

            vm.isLoadingDokument = ko.observable(false);
            vm.activeDokument = ko.observable();
            vm.activeDokumentId = ko.pureComputed(function () {
                const activeDokument = vm.activeDokument();
                if (activeDokument) {
                    return activeDokument.id;
                }
                return -1;
            });
            vm.hasDokumentPdf = ko.observable(false);
            vm.pdfPages = ko.observableArray();
            vm.downloadCache = new Map();
            vm.showFullscreen = ko.observable(false);

            vm.renderActiveDokumentPdf = function () {
                const download = vm.downloadCache.get(vm.activeDokument().fileKey);
                return vm.viewmodel.renderPdfDocument(download.buffer, PDF_CANVAS_ID, vm.pdfPages);
            };

            vm.showDokument = function (dokument) {
                LOG.debug("showDokument");
                vm.activeDokument(dokument);
                vm.hasDokumentPdf(_.isEmpty(dokument.fileKey) === false);
                if (vm.hasDokumentPdf() === false) {
                    return Promise.resolve(undefined);
                }
                if (vm.downloadCache.has(dokument.fileKey)) {
                    return vm.renderActiveDokumentPdf();
                }
                vm.isLoadingDokument(true);
                let timerContext;
                return downloadTimer.time().then(function (context) {
                    timerContext = context;
                    return vm.viewmodel.client.downloadAwait(metaProtocol.downloadRequest({
                        key: dokument.fileKey
                    }));
                }).then(function (downloadReply) {
                    vm.viewmodel.errorReply(undefined);
                    vm.downloadCache.set(dokument.fileKey, downloadReply);
                    return vm.renderActiveDokumentPdf();
                }).then(function () {
                    dokument.read(true);
                    return vm.executeReadDokumenteAction([dokument.id]);
                }).catch(function (exc) {
                    vm.viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
                    LOG.warn("showDokument failed", exc);
                }).then(function () {
                    vm.isLoadingDokument(false);
                    return timerContext.stop();
                });
            };

            vm.toggleFullscreen = function () {
                LOG.debug("toggleFullscreen");
                vm.showFullscreen(vm.showFullscreen() === false);
                return vm.renderActiveDokumentPdf();
            };

            vm.scrollToPage = function (page) {
                document.getElementById(page.pageId).scrollIntoView();
            };

            vm.closeDokument = function () {
                LOG.debug("closeDokument");
                vm.activeDokument(undefined);
                vm.showFullscreen(false);
            };

            vm.downloadDokument = function () {
                const download = vm.downloadCache.get(vm.activeDokument().fileKey);
                saveAs(new Blob([download.buffer], {type: "application/pdf"}), download.file.name);
            };

            vm.activateAnsprechpartner = function () {
                const dokument = vm.activeDokument();
                const ansprechpartner = vm.viewmodel.resolveAnsprechpartner(dokument);
                if (ansprechpartner) {
                    vm.viewmodel.activeAnsprechpartner(ansprechpartner);
                }
            };

            vm.onNavUpdate = function () {
                LOG.debug("onNavUpdate");
                vm.hasSubmitFocus(true);
                if (_.has(nav.currentState(), "search")) {
                    return vm.searchInternal();
                }
            };

            nav.register({
                id: vm.viewmodel.COMPONENTS.DOKUMENT,
                onUpdate: vm.onNavUpdate
            });

            ko.when(() => 0 < vm.viewmodel.eigBuchhaltungen().length && 0 < vm.viewmodel.geschaeftsjahre().length).then(
                () => vm.searchInternal()
            );

            return vm;
        }
    }
});
