/*global */
const protocol = {};

protocol.ADS_FEED = "ads";
protocol.NEWS_FEED = "news";
protocol.STAMMDATEN_RESOURCE = "stammdaten";
protocol.ONBOARDING_PARAM = "onboarding";
protocol.ONBOARDING_TOKEN_PARAM = "token";

protocol.FREIGABE_CHANNEL = "freigabe";
protocol.PORTALPLUS_CHANNEL = "archive";
protocol.ZIP_CHANNEL = "zipchnl";
protocol.ZIP_ERROR_NOTIFICATION_ACTION = "ZIPERR";
protocol.ZIP_READY_NOTIFICATION_ACTION = "ZIPRDY";

protocol.ONBOARDING_USER_ERROR = 2000;
protocol.ONBOARDING_OTP_ERROR = 2001;
protocol.VALIDATE_FREIGABE_ERROR = 2049;
protocol.ZIP_GESCHAEFTSJAHR_PENDING_ERROR = 2050;

export default Object.freeze(protocol);
