/*global */
export default {
    de: {
        translation: {
            admin_eigb_deselect_all_one: "Alle Buchhaltungen entfernen",
            admin_eigb_deselect_all_other: "Alle {{count}} Buchhaltungen entfernen",
            admin_eigb_query: "Suche",
            admin_eigb_query_eigbliegb: "Bh./Liegensch. Bez./Ref.",
            admin_eigb_query_pers: "Person Name/Ref.",
            admin_eigb_query_placeholder: "{{fields}}",
            admin_eigb_query_placeholder_invalid: "Bitte min. ein Suchfeld auswählen!",
            admin_eigb_query_verw: "Bh./Verw. Team/Standort",
            admin_eigb_results_none: "Keine Buchhaltungen für Suchkriterien gefunden.",
            admin_eigb_select_all: "Alle Buchhaltungen selektieren",
            admin_eigb_selected: "Selektierte Buchhaltungen (max. 50)",
            admin_eigb_selected_none: "Keine Buchhaltungen selektiert.",
            admin_load_eigb: "Buchhaltung suchen",
            admin_load_eigb_submit: "Selektierte Buchhaltungen laden",
            admin_load_own_groups_submit: "Meine Buchhaltungen laden",
            admin_load_user_groups: "Buchhaltungen von Benutzer",
            admin_load_user_groups_submit: "Buchhaltungen von Benutzer laden",
            admin_load_user_name: "Benutzer",
            admin_load_user_name_help: "Zum Beispiel 'til_gehrke' oder 'sophia.wolff@schaeppi.ch'",
            admin_own_groups_hint: "Admin",
            admin_own_groups_hint_help: "Es sind aktuell nicht alle der ihnen zugeteilten Buchhaltungen geladen, oder die Zuteilung hat sich geändert. Möchten Sie alle ihre Buchhaltungen laden?",
            admin_settings: "Einstellungen",
            ansprechpartner: "Ansprechpartner",
            ansprechpartner_missing: "Leider sind keine Ansprechpartner für diese Buchhaltung und Liegenschaft hinterlegt.",
            beleg: "Belege und Buchungen",
            beleg_belege: "Belege",
            beleg_buchungen: "Buchungen",
            beleg_close: "Beleg Details schliessen",
            beleg_details: "Beleg {{nr}}",
            beleg_details_btn: "Beleg",
            beleg_filter_betrag_from: "Betrag von",
            beleg_filter_betrag_to: "Betrag bis",
            beleg_filter_date_from: "Datum von",
            beleg_filter_date_to: "Datum bis",
            beleg_intro: "Es werden nur Buchungen aufgeführt, für welche ein Beleg (Kreditorenrechnung, Buchungsbeleg) existiert. Buchungen ohne Beleg sind im Eigentümerportal nicht enthalten und nur in der Liegenschaftsrechnung/Stewe-Abrechnung zu finden.",
            beleg_load: "Suchen",
            beleg_search_query: "Suche",
            beleg_search_total: "{{count}} von {{total}} Resultaten geladen",
            beleg_search_total_approx: "{{count}} von ca. {{total}} Resultaten geladen",
            beleg_zip_files_tooltip: "PDF Belege als ZIP herunterladen. Es werden nur die geladenen Resultate heruntergeladen. Maximal können 200 PDF-Belege in einem ZIP heruntergeladen werden.",
            belegdatum: "Belegdat.",
            belegnr: "Belegnr.",
            betrag: "Betrag CHF",
            bewegungstext: "Bewegungstext",
            brand: "Eigentümerportal",
            buchId: "Buch. ID",
            buchhaltung: "Buchhaltungen",
            buchhaltung_admin: "Administrator",
            buchhaltung_beleg: "Belegsuche",
            buchhaltung_beleg_timestamp: "Belege/Buchungen {{timestamp}}",
            buchhaltung_data: "Buchhaltungsdaten",
            buchhaltung_dokument: "Dokumentsuche",
            buchhaltung_dokument_timestamp: "Dokumente {{timestamp}}",
            buchhaltung_download: "ZIP-Download",
            buchhaltung_download_geschaeftsjahr: "Geschäftsjahr auswählen",
            buchhaltung_download_person: "Person auswählen",
            buchhaltung_filter_geschaeftsjahr_help: "Es sind nur die Geschäftsjahre aufgeführt, für die ein Upload der Belege und Abschlussdokumente stattgefunden hat.",
            buchhaltung_filter_geschaeftsjahr_standard_help: "$t(buchhaltung_filter_geschaeftsjahr_help) Mit Portal Standard wird nur das laufende und das zuletzt abgeschlossene Geschäftsjahr aufgeführt.",
            buchhaltung_filter_person_help: "Wählen Sie für welchen Eigentümer die Unterlangen für den Download zusammengestellt werden sollen.",
            buchhaltung_freigabe: "Freigaben",
            buchhaltung_glossary: "Glossar",
            buchhaltung_glossary_vm: "VM",
            buchhaltung_glossary_vm_dd: "Vermietetes Stockwerkeigentums Objekt",
            buchhaltung_glossary_vmgmo: "VM-GMO",
            buchhaltung_glossary_vmgmo_dd: "Vermietetes gemeinschaftliches Stockwerkeigentums Objekt",
            buchhaltung_gueltig_ab: "Gültig ab {{date}}",
            buchhaltung_gueltig_bis: " bis {{date}}",
            buchhaltung_import_message: "Upload Mitteilung/Hinweis",
            buchhaltung_liegenschaften: "Liegenschaften ({{count}})",
            buchhaltung_new_data: "Neue Daten verfügbar",
            buchhaltung_no_data: "Keine Informationen verfügbar.",
            buchhaltung_non_admin: "Freigegeben durch Dritte",
            buchhaltung_pending_error: "ZIP-Download für die ausgewählte Buchhaltung/Geschäftsjahr befindet sich bereits in Bearbeitung.",
            buchhaltung_personen: "Personen ({{count}})",
            buchhaltung_personen_lead: "Aufgeführte Personen sind verbunden mit dieser Eigentümerbuchhaltung. Sie sind berechtigt für die farblich gekennzeichneten Personen.",
            buchhaltung_portalplus: "Portal Plus",
            buchhaltung_portalplus_activate_history: "Abo Historie anzeigen",
            buchhaltung_portalplus_active: "Aktiv",
            buchhaltung_portalplus_begin_date: "Begindatum",
            buchhaltung_portalplus_cancel: "Abo kündigen",
            buchhaltung_portalplus_cancel_date: "Kündigungsdatum",
            buchhaltung_portalplus_cancel_lead: "Abo per Ende Geschäftsjahr kündigen. Ab dem nächsten Geschäftsjahr wird Portal Standard aktiv. Die Kündigung kann jederzeit zurückgezogen werden.",
            buchhaltung_portalplus_cancel_submit: "Abo kündigen",
            buchhaltung_portalplus_canceled: "Portal Plus Abo am {{cancelDate}} gekündigt und endet mit dem Geschäftsjahr per {{endeDate}}.",
            buchhaltung_portalplus_compare: "Vergleich Portal Standard zu Portal Plus",
            buchhaltung_portalplus_compare_access: "Zugriff Eigentümerportal",
            buchhaltung_portalplus_compare_download: "ZIP-Download von Belegen und Dokumenten",
            buchhaltung_portalplus_compare_geschaeftsjahre: "Daten aller Geschäftsjahre einsehbar",
            buchhaltung_portalplus_compare_geschaeftsjahre_help: "Ab Einführung Eigentümerportal im Jahr 2021, sofern die Liegenschaft in Verwaltung bei Schaeppi Grundstücke war.",
            buchhaltung_portalplus_compare_geschaeftsjahre_standard: "Daten vom letzten abgeschlossenen und aktuellen Geschäftsjahr einsehbar",
            buchhaltung_portalplus_compare_price: "CHF {{price}} pro Jahr und Liegenschaft, exklusive MWST",
            buchhaltung_portalplus_compare_price_help: "Betrag wird automatisch der Jahresabrechnung belastet. Preis- und Leistungsänderungen bleiben vorbehalten.",
            buchhaltung_portalplus_compare_price_standard: "Kostenlos",
            buchhaltung_portalplus_compare_search: "Suche und Anzeigen von Belegen und Dokumenten",
            buchhaltung_portalplus_compare_storage: "Gesetzliche Aufbewahrungspflicht sichergestellt durch Schaeppi Grundstücke",
            buchhaltung_portalplus_compare_storage_standard: "Gesetzliche Aufbewahrungspflicht sichergestellt durch Eigentümer",
            buchhaltung_portalplus_delayed: "Portal Plus Abo wird ab Beginn vom neuen Geschäftsjahr per {{date}} aktiviert.",
            buchhaltung_portalplus_delayed_toast: "Portal Plus Abo wird mit dem Beginn vom neuen Geschäftsjahr für folgende Buchhaltungen aktiviert:",
            buchhaltung_portalplus_disabled: "Es besteht kein Portal Plus Abo. Portal Standard ist aktiv.",
            buchhaltung_portalplus_enable: "Portal Plus Abo abschliessen",
            buchhaltung_portalplus_enable_date: "Aktivierungsdatum",
            buchhaltung_portalplus_enable_eigb_one: "Eine aktive Liegenschaft - CHF {{price}} pro Jahr",
            buchhaltung_portalplus_enable_eigb_other: "{{count}} aktive Liegenschaften - CHF {{price}} pro Jahr",
            buchhaltung_portalplus_enable_lead: "<strong>Bitte beachten Sie:</strong><ul><li>Das Abo gilt für ein Jahr ab Beginn des Geschäftsjahres und verlängert sich jeweils automatisch um ein Jahr, wenn es nicht vor Ablauf gekündigt wird.</li><li>Wird das Abo nach dem Abschluss vom Geschäftsjahr geändert, werden diese Änderungen es erst per Anfang vom nächsten Geschäftsjahr aktiv.</li><li>Mit '$t(buchhaltung_portalplus_enable_pay)' bestätigen Sie die Aktivierung des Portal Plus Abos inkl. jährlicher automatischer Verrechnung der aufgeführten Jahresgebühr pro aktiver Liegenschaft in den ausgewählten Buchhaltungen.</li></ul>",
            buchhaltung_portalplus_enable_meg: "Miteigentümer müssen sich vor der Aktivierung des Portal Plus Abos absprechen, ob eine Aktivierung gewünscht wird. Bei einer Aktivierung geht Schaeppi Grundstücke davon aus, dass sich die Miteigentümergemeinschaft rechtsgültig für eine Aktivierung des Abos entschieden hat.",
            buchhaltung_portalplus_enable_pay: "Kostenpflichtig abschliessen",
            buchhaltung_portalplus_enable_stewe: "$t(buchhaltung_portalplus_enable_meg)",
            buchhaltung_portalplus_enable_submit: "$t(buchhaltung_portalplus_enable_pay) - Total CHF {{price}} pro Jahr",
            buchhaltung_portalplus_enabled: "Portal Plus Abo aktiv seit {{date}}.",
            buchhaltung_portalplus_enabled_help: "Abo wird am Beginn vom neuen Geschäftsjahr automatisch verlängert.",
            buchhaltung_portalplus_ende_date: "Endedatum",
            buchhaltung_portalplus_header: "Portal Plus Abo",
            buchhaltung_portalplus_history: "Portal Plus Abo Historie",
            buchhaltung_portalplus_override: "Änderung vom Portal Plus Abo ist durch das System gesperrt. Bitte wenden Sie sich an ihre Ansprechperson in der Bewirtschaftung.",
            buchhaltung_portalplus_override_stewe: "Portal Plus Abo kann nur mit einem Beschluss an der Stockwerkeigentümerversammlung aktiviert werden.",
            buchhaltung_portalplus_select: "Buchhaltungen auswählen",
            buchhaltung_portalplus_standard: "Portal Standard",
            buchhaltung_portalplus_subscription: "Abostatus",
            buchhaltung_portalplus_user: "Benutzer",
            buchhaltung_portalplus_withdraw_cancelation: "Kündigung zurückziehen",
            buchhaltung_search_query: "Suche",
            buchhaltung_search_query_placeholder: "Buchhaltung, Liegenschaft",
            buchhaltung_shortcuts: "Schnellzugriff",
            buchhaltung_upload: "Letzte Aktualisierung/Upload",
            buchhaltung_zip: "ZIP Aufbereiten",
            buchhaltung_zip_delete: "ZIP löschen",
            buchhaltung_zip_delete_confirmation: "Möchten Sie dieses ZIP wirklich löschen?",
            buchhaltung_zip_download: "ZIP herunterladen",
            buchhaltung_zip_geschaeftsjahr_EMPTY: "Keine Daten vorhanden",
            buchhaltung_zip_geschaeftsjahr_ERROR: "Aufbereitung fehlgeschlagen",
            buchhaltung_zip_geschaeftsjahr_PENDING: "In Warteschlange...",
            buchhaltung_zip_geschaeftsjahr_PROCESSING: "$t(zip_files_progress)",
            buchhaltung_zip_geschaeftsjahr_READY: "{{-persName}}",
            buchhaltung_zip_geschaeftsjahr_info: "Aufbereitet {{timestamp}} von Benutzer {{user}}. Verfällt {{expiration}}",
            buchhaltung_zip_geschaeftsjahr_info_error: "Keine Belege/Dokumente gefunden oder es ist sonst ein Fehler aufgetreten",
            buchhaltung_zip_help: "Das erstellte ZIP enthält alle Belege und Dokumente aller Liegenschaften dieser Buchhaltung für die ausgewählte Person und Geschäftsjahr.",
            buchhaltung_zip_outdated: "Veraltet",
            buchhaltung_zip_outdated_tooltip: "Seit der Aufbereitung von diesem ZIP sind neue Belege/Dokumente verfügbar.",
            buchhaltung_zip_share: "ZIP teilen",
            buchhaltung_zip_share_copy: "Link kopieren",
            buchhaltung_zip_share_lead: "Das ZIP kann mit diesem Link bis am {{expiration}} heruntergeladen werden. Der Link ist als vertraulich zu behandeln, bitte teilen Sie diesen nur mit ihnen vertrauten Personen.",
            buchungsdatum: "Buchdat.",
            csv: "CSV herunterladen",
            csv_tooltip: "Es werden nur die geladenen Resultate in der Tabelle heruntergeladen. Informationen zum Import der CSV Datei in Excel finden Sie in der Hilfe.",
            dokument: "Dokumente",
            dokument_close: "Dokument Details schliessen",
            dokument_details: "Dokument",
            dokument_details_btn: "Anzeigen",
            dokument_filter_date_from: "Datum von",
            dokument_filter_date_to: "Datum bis",
            dokument_filter_dokumenttyp: "Dokumenttyp",
            dokument_filter_read: "Gelesene/Ungelesene",
            dokument_filter_read_ALL: "Alle",
            dokument_filter_read_READ: "Gelesene",
            dokument_filter_read_UNREAD: "Ungelesene",
            dokument_load: "Suchen",
            dokument_mark_read_one: "{{count}} Dokument gelesen",
            dokument_mark_read_other: "{{count}} Dokumente gelesen",
            dokument_search_total: "{{count}} von {{total}} Resultaten geladen",
            dokument_search_total_approx: "{{count}} von ca. {{total}} Resultaten geladen",
            dokument_select: "Dokument selektieren",
            dokument_select_month: "Alle Dokumente im Monat selektieren",
            dokumentbezeichnung: "Bezeichnung",
            dokumentdatum: "Datum",
            dokumente_zip_files_tooltip: "PDF Dokumente als ZIP herunterladen. Selektieren Sie einzelne Dokumente, ansonsten werden alle geladenen Resultate heruntergeladen. Maximal können 200 PDF-Dokumente in einem ZIP heruntergeladen werden.",
            dokumenttyp: "Typ",
            download: "Herunterladen",
            eigb: "Buchhaltung",
            eigb_type_aeg: "Alleineigentum",
            eigb_type_meg: "Miteigentum",
            eigb_type_stewe: "Stockwerkeigentum",
            email_help: "Wir benachrichtigen Sie via E-Mail, sobald neue Daten für Ihre Buchhaltungen verfügbar sind.",
            enforce_2fa: "Bitte richten Sie eine zweifaktor Authentifizierung mit E-Mail oder TOTP ein. Anschliessend können Sie das Eigentümerportal wieder nutzen.",
            error_reply: "Ein Fehler ist aufgetreten",
            filter_eigb: "$t(eigb)",
            filter_geschaeftsjahr: "Geschäftsjahr",
            filter_liegb: "$t(liegb)",
            filter_no_match: "Keine Buchhaltungen gefunden",
            filter_person: "$t(person)",
            filter_search: "Suche...",
            filter_select_all: "Alle selektieren",
            filter_selection: "ausgewählt",
            filter_selection_none: "Keine ausgewählt",
            freigabe: "Freigaben",
            freigabe_admin: "Systemfreigaben",
            freigabe_approve: "Freigabecode registrieren",
            freigabe_approve_code: "Freigabecode",
            freigabe_approve_help: "Registrieren Sie einen zusätzlichen Freigabecode auf ihr Benutzerkonto {{user}}.",
            freigabe_approve_submit: "Registrieren",
            freigabe_create: "Freigabe erstellen",
            freigabe_create_description_help: "Freitextfeld. Zum Beispiel der Grund für die Freigabe, Name der eingeladenen Person, etc. Wird nur intern verwendet und ist für die eingeladene Person nicht sichtbar.",
            freigabe_create_submit: "Freigabe erstellen",
            freigabe_description: "Beschreibung",
            freigabe_eigb: "Freigegebene Buchhaltungen",
            freigabe_email: "E-Mail",
            freigabe_email_help: "E-Mail der eingeladenen Person.",
            freigabe_entries: "Freigaben",
            freigabe_filter_state: "Status",
            freigabe_filter_truster: "Erstellt von",
            freigabe_filter_truster_ALL: "Benutzer und System",
            freigabe_filter_truster_NON_ADMIN: "Benutzer",
            freigabe_filter_truster_OWN: "Nur eigener Benutzer",
            freigabe_intro: "Freigaben ermöglicht es ihnen externen Personen Zugriff auf ihre Daten im Eigentümerportal zu geben. Um eine neue Freigabe zu erstellen, wählen Sie die freizugebenden Buchhaltungen aus und geben Sie die E-Mail der einzuladenden Person ein. Die Person erhält dann via E-Mail eine Einladung und kann sich damit beim Eigentümerportal registrieren. Sie können der Person die Freigabe jederzeit wieder entziehen.",
            freigabe_load: "Suchen",
            freigabe_locale: "E-Mail Sprache",
            freigabe_non_admin: "Erstellte Freigaben",
            freigabe_person_gueltig_ab: "Gültig ab {{date}}",
            freigabe_person_gueltig_bis: " bis {{date}}",
            freigabe_resend: "Erneut einladen",
            freigabe_resend_lead: "Es wird nochmals eine Einladung via E-Mail an die angegebene E-Mail verschickt. Abgelaufene Einladungen werden per heutigem Datum nochmals erneuert.",
            freigabe_resend_submit: "Einladung erneut versenden",
            freigabe_revoke: "Widerrufen",
            freigabe_revoke_confirmation: "Freigabe widerrufen?",
            freigabe_state: "Status",
            freigabe_timestamp: "Erstellt am",
            freigabe_truster: "Von",
            freigabe_user: "Freigegeben an",
            fullscreen_toggle: "Vollbild",
            geschaeftsjahr: "Geschäftsjahr",
            haben: "Haben CHF",
            kg2: "Kontogruppe",
            konto: "Konto",
            kreditor: "Kreditor",
            liegb: "Liegenschaft",
            loading_stammdaten: "Stammdaten werden geladen...",
            loading_zips: "ZIPs werden geladen...",
            new_password_help: "Mindestens 10 Zeichen. Wir empfehlen mindestens eine Zahl und ein Sonderzeichen.",
            news: "News",
            news_read: "Gelesene Beiträge",
            news_read_link: "Beitrag lesen",
            news_read_new: "Neu",
            onboarding: "Registrierung",
            onboarding_account: "Haben Sie bereits ein Benutzerkonto?",
            onboarding_account_existing: "Ja. Fortfahren mit bestehendem Konto",
            onboarding_account_new: "Nein. Neues Konto registrieren",
            onboarding_account_register: "Neues Benutzerkonto registrieren",
            onboarding_code: "Freigabecode {{nr}}",
            onboarding_code_add: "Weitere Codes eingeben",
            onboarding_code_invalid: "Freigabecode ungültig",
            onboarding_eigb: "Ihre Freigaben",
            onboarding_existing_failed: "Freigabe fehlgeschlagen. Bitte versuchen Sie es erneut. Falls das Problem weiterhin besteht, kontaktieren Sie bitte mit Ihrer Ansprechperson in der Bewirtschaftung.",
            onboarding_intro: "Registrieren Sie sich mit einem Freigabecode. Falls Sie noch nicht registriert sind und keinen Freigabecode erhalten haben, nehmen Sie bitte mit Ihrer Ansprechperson in der Bewirtschaftung Kontakt auf.",
            onboarding_invalid: "Leider konnte kein Freigabecode generiert werden. Nehmen Sie bitte mit Ihrer Ansprechperson in der Bewirtschaftung Kontakt auf.",
            onboarding_login_required: "Bitte melden Sie sich mit ihrem Benutzer an, um den Freigabeprozess abzuschliessen.",
            onboarding_new: "Konto registrieren",
            onboarding_new_2fa: "Zweifaktor Authentifizierung",
            onboarding_new_2fa_email: "Nach der Registrierung erhalten Sie ein Einmalkennwort zur Verifizierung an die oben angegebene Adresse",
            onboarding_new_2fa_totp: "Nach der Registrierung wird der TOTP QR-Code angezeigt. Scannen Sie diesen mit einer entsprechenden TOTP fähigen App wie Google Authenticator, FreeOTP Authenticator, Microsoft Authenticator und schliessen Sie die Verifizierung ab.",
            onboarding_new_email: "$t(user_setting_email)",
            onboarding_new_email_help: "$t(email_help)",
            onboarding_new_user: "Benutzername",
            onboarding_new_user_help: "Mindestens 3 Zeichen. Es sind Buchstaben (a-z, A-Z), Zahlen (0-9) und gewissen Sonderzeichen ('-', '_', '@', '+') erlaubt.",
            onboarding_new_user_invalid: "Benutzername ungültig oder bereits vergeben",
            onboarding_start: "Registrieren",
            onboarding_success: "Freigabeprozess erfolgreich abgeschlossen.",
            onboarding_token_invalid: "Token ist ungültig.",
            onboarding_validate_code: "Validierung starten",
            onboarding_verify_failed: "$t(save_user_verify_failed) Falls das Problem weiterhin besteht, kontaktieren Sie bitte mit Ihrer Ansprechperson in der Bewirtschaftung.",
            page_nav: "Seitennavigation",
            person: "Person",
            search: "Suche",
            search_dropdown: "Aktiviere Dropdown für Suche",
            search_placeholder: "{{fields}}",
            search_placeholder_invalid: "Bitte min. ein Suchfeld auswählen!",
            settings: "Einstellungen",
            soll: "Soll CHF",
            title: "Schaeppi Eigentümerportal",
            user_setting_email: "Kontakt E-Mail",
            user_setting_email_help: "$t(email_help). Diese E-Mail ist unabhängig von ihren Login-Optionen, welche Sie via Login Seite anpassen können.",
            user_setting_ignored_dok_types: "Ignorierte Dokumenttypen",
            user_setting_ignored_dok_types_help: "Ausgewählte Typen werden bei der Dokumentsuche und bei der Aufbereitung vom ZIP der Buchhaltung ignoriert.",
            user_setting_locale: "Korrespondenzsprache",
            user_setting_locale_help: "Sprache für E-Mail Benachrichtigungen.",
            user_setting_save: "Einstellungen speichern",
            user_setting_save_success: "Einstellungen gespeichert.",
            version: "Portal Version {{version}}",
            zip_files_download: "Herunterladen ({{size}})",
            zip_files_error: "Erstellung vom ZIP fehlgeschlagen.",
            zip_files_exceeded: "Anzahl Suchresultate übersteigt die maximal erlaubten 200. Bitte schränken Sie die Suchkriterien ein.",
            zip_files_help: "Temporäres ZIP aufbereitet. Diesen Dialog bitte schliessen, bevor Sie versuchen das nächste ZIP aufzubereiten. Download verfällt am {{expiration}}.",
            zip_files_one: "{{count}} Dokument herunterladen",
            zip_files_other: "{{count}} Dokumente herunterladen",
            zip_files_progress: "PDF-Dateien werden gesammelt und gezippt"
        }
    },
    en: {
        translation: {
            admin_eigb_deselect_all_one: "Remove all accountings",
            admin_eigb_deselect_all_other: "Remove all {{count}} accountings",
            admin_eigb_query: "Search",
            admin_eigb_query_eigbliegb: "Acc./Prop. description/ref.",
            admin_eigb_query_pers: "Individual name/ref.",
            admin_eigb_query_placeholder: "{{fields}}",
            admin_eigb_query_placeholder_invalid: "Please select at least one search field!",
            admin_eigb_query_verw: "Acc./Adm. team/location",
            admin_eigb_results_none: "No accountigs for search query found.",
            admin_eigb_select_all: "Select all accountings",
            admin_eigb_selected: "Accounting selection (max. 50)",
            admin_eigb_selected_none: "No accountings selected.",
            admin_load_eigb: "Search accounting",
            admin_load_eigb_submit: "Load selected accountings",
            admin_load_own_groups_submit: "Load my accountings",
            admin_load_user_groups: "Accountings from user",
            admin_load_user_groups_submit: "Load accountings from user",
            admin_load_user_name: "User",
            admin_load_user_name_help: "For example 'til_gehrke' or 'sophia.wolff@schaeppi.ch'",
            admin_own_groups_hint: "Admin",
            admin_own_groups_hint_help: "Not all of the accountings assigned to you are currently loaded, or the assignment has changed. Would you like to load all your accountings?",
            admin_settings: "Settings",
            ansprechpartner: "Contact person",
            ansprechpartner_missing: "Unfortunately there are no contacts provided for this accounting and property.",
            beleg: "Receipts and Entries",
            beleg_belege: "Receipts",
            beleg_buchungen: "Entries",
            beleg_close: "Close receipt details",
            beleg_details: "Receipt {{nr}}",
            beleg_details_btn: "Receipt",
            beleg_filter_betrag_from: "Amount From",
            beleg_filter_betrag_to: "Amount To",
            beleg_filter_date_from: "Date From",
            beleg_filter_date_to: "Date To",
            beleg_intro: "Only entries with a corresponding receipt (vendor invoice, accounting record) are listed. Entries without a receipt are not available in the Eigentümerportal and only visible in the closing statements/condominium statements.",
            beleg_load: "Search",
            beleg_search_query: "Search",
            beleg_search_total: "{{count}} of {{total}} results loaded",
            beleg_search_total_approx: "{{count}} of approx. {{total}} results loaded",
            beleg_zip_files_tooltip: "Download PDF receipts as a ZIP file. Only the loaded results are downloaded. A maximum of 200 PDF receipts can be downloaded in one ZIP.",
            belegdatum: "Receipt Dt",
            belegnr: "Receipt No",
            betrag: "Amount CHF",
            bewegungstext: "Movement text",
            brand: "Eigentümerportal",
            buchId: "Entry ID",
            buchhaltung: "Accountings",
            buchhaltung_admin: "Admin",
            buchhaltung_beleg: "Receipt search",
            buchhaltung_beleg_timestamp: "Receipts/entries {{timestamp}}",
            buchhaltung_data: "Accounting information",
            buchhaltung_dokument: "Document search",
            buchhaltung_dokument_timestamp: "Documents {{timestamp}}",
            buchhaltung_download: "ZIP download",
            buchhaltung_download_geschaeftsjahr: "Select business year",
            buchhaltung_download_person: "Select person",
            buchhaltung_filter_geschaeftsjahr_help: "Only those business years are listed for which an upload has taken place.",
            buchhaltung_filter_geschaeftsjahr_standard_help: "$t(buchhaltung_filter_geschaeftsjahr_help) With Portal Standard, only the current and most recently completed business year is listed.",
            buchhaltung_filter_person_help: "Select for which owner the documents are to be compiled for download.",
            buchhaltung_freigabe: "Approvals",
            buchhaltung_glossary: "Glossary",
            buchhaltung_glossary_vm: "VM",
            buchhaltung_glossary_vm_dd: "Rented condominium object",
            buchhaltung_glossary_vmgmo: "VM-GMO",
            buchhaltung_glossary_vmgmo_dd: "Rented common condominium object",
            buchhaltung_gueltig_ab: "Valid from {{date}}",
            buchhaltung_gueltig_bis: " to {{date}}",
            buchhaltung_import_message: "Upload message/notice",
            buchhaltung_liegenschaften: "Properties ({{count}})",
            buchhaltung_new_data: "New data available",
            buchhaltung_no_data: "No information available.",
            buchhaltung_non_admin: "Approved by third party",
            buchhaltung_pending_error: "ZIP download for selected accounting and business year is already in process.",
            buchhaltung_personen: "Individuals ({{count}})",
            buchhaltung_personen_lead: "Listed individuals are associated with this accounting. You are authorized for the color-marked individuals.",
            buchhaltung_portalplus: "Portal Plus",
            buchhaltung_portalplus_activate_history: "Show subscription history",
            buchhaltung_portalplus_active: "Active",
            buchhaltung_portalplus_begin_date: "Begin date",
            buchhaltung_portalplus_cancel: "Cancel subscription",
            buchhaltung_portalplus_cancel_date: "Cancel date",
            buchhaltung_portalplus_cancel_lead: "Cancel subscription at the end of the business year. Portal Standard will be active from the next business year. The termination can be withdrawn at any time.",
            buchhaltung_portalplus_cancel_submit: "Cancel subscription",
            buchhaltung_portalplus_canceled: "Portal Plus subscription canceled on {{cancelDate}} and will end with the business year on {{endeDate}}.",
            buchhaltung_portalplus_compare: "Compare Portal Standard to Portal Plus",
            buchhaltung_portalplus_compare_access: "Access Eigentümerportal",
            buchhaltung_portalplus_compare_download: "ZIP download for receipts and documents",
            buchhaltung_portalplus_compare_geschaeftsjahre: "Access data from all business years",
            buchhaltung_portalplus_compare_geschaeftsjahre_help: "Starting in 2021 with the introduction of the Eigentümerportal, in case the property at that time was managed by Schaeppi Grundstücke.",
            buchhaltung_portalplus_compare_geschaeftsjahre_standard: "Access data from last completed and current business year",
            buchhaltung_portalplus_compare_price: "CHF {{price}} per year and property, excluding VAT",
            buchhaltung_portalplus_compare_price_help: "The amount is automatically charged to the annual statement. We reserve the right to change prices and services.",
            buchhaltung_portalplus_compare_price_standard: "Free",
            buchhaltung_portalplus_compare_search: "Search and display receipts and documents",
            buchhaltung_portalplus_compare_storage: "Legal retention requirements ensured by Schaeppi Grundstücke",
            buchhaltung_portalplus_compare_storage_standard: "Legal retention requirements ensured by owners",
            buchhaltung_portalplus_delayed: "Portal Plus subscription will be activated from the beginning of the new business year on {{date}}.",
            buchhaltung_portalplus_delayed_toast: "Portal Plus subscription will be activated at the beginning of the new business year for the following accountings:",
            buchhaltung_portalplus_disabled: "No Portal Plus subscription. Portal Standard is active.",
            buchhaltung_portalplus_enable: "Subscribe to Portal Plus",
            buchhaltung_portalplus_enable_date: "Activation date",
            buchhaltung_portalplus_enable_eigb_one: "One active property - CHF {{price}} per year",
            buchhaltung_portalplus_enable_eigb_other: "{{count}} active properties - CHF {{price}} per year",
            buchhaltung_portalplus_enable_lead: "<strong>Please note:</strong><ul><li>The subscription is valid for one year from the beginning of the business year and is automatically extended by one year if it is not canceled before it expires.</li><li>If the subscription is changed after the business year is completed, it will not be adjusted until the beginning of the next business year.</li><li>By clicking '$t(buchhaltung_portalplus_enable_pay)' you confirm the activation of the Portal Plus subscription including annual automatic billing of the listed annual fee per active property in the selected accounts.</li></ul>",
            buchhaltung_portalplus_enable_meg: "Before activating the Portal Plus subscription, co-owners must agree whether activation is desired. In the event of activation, Schaeppi Grundstücke assumes that the co-ownership association has made a legally valid decision to activate the subscription.",
            buchhaltung_portalplus_enable_pay: "Confirm subscription",
            buchhaltung_portalplus_enable_stewe: "$t(buchhaltung_portalplus_enable_meg)",
            buchhaltung_portalplus_enable_submit: "$t(buchhaltung_portalplus_enable_pay) - Total CHF {{price}} per year",
            buchhaltung_portalplus_enabled: "Portal Plus subscription enabled on {{date}}.",
            buchhaltung_portalplus_enabled_help: "Subscription is automatically renewed at the beginning of the new business year.",
            buchhaltung_portalplus_ende_date: "End date",
            buchhaltung_portalplus_header: "Portal Plus subscription",
            buchhaltung_portalplus_history: "Portal Plus subscription history",
            buchhaltung_portalplus_override: "Change of Portal Plus subscription is locked by the system. Please get in touch with your property manager.",
            buchhaltung_portalplus_override_stewe: "Portal Plus subscription can only be activated with a decision at the condominium owners' meeting.",
            buchhaltung_portalplus_select: "Select accountings",
            buchhaltung_portalplus_standard: "Portal Standard",
            buchhaltung_portalplus_subscription: "Subscription status",
            buchhaltung_portalplus_user: "User",
            buchhaltung_portalplus_withdraw_cancelation: "Withdraw cancellation",
            buchhaltung_search_query: "Search",
            buchhaltung_search_query_placeholder: "Accounting, Property",
            buchhaltung_shortcuts: "Shortcuts",
            buchhaltung_upload: "Last update/upload",
            buchhaltung_zip: "Create ZIP",
            buchhaltung_zip_delete: "Delete ZIP",
            buchhaltung_zip_delete_confirmation: "Are you sure to delete this ZIP?",
            buchhaltung_zip_download: "Download ZIP",
            buchhaltung_zip_geschaeftsjahr_EMPTY: "No data available",
            buchhaltung_zip_geschaeftsjahr_ERROR: "ZIP creation failed",
            buchhaltung_zip_geschaeftsjahr_PENDING: "In waiting queue...",
            buchhaltung_zip_geschaeftsjahr_PROCESSING: "$t(zip_files_progress)",
            buchhaltung_zip_geschaeftsjahr_READY: "{{-persName}}",
            buchhaltung_zip_geschaeftsjahr_info: "Created {{timestamp}} by user {{user}}. Expires {{expiration}}",
            buchhaltung_zip_geschaeftsjahr_info_error: "No receipts/documents found or something went wrong",
            buchhaltung_zip_help: "The created ZIP contains all receipts and documents of all properties of this accounting for the selected person and business year.",
            buchhaltung_zip_outdated: "Outdated",
            buchhaltung_zip_outdated_tooltip: "Since the creation of this ZIP, new receipts/documents have been added.",
            buchhaltung_zip_share: "Share ZIP",
            buchhaltung_zip_share_copy: "Copy link",
            buchhaltung_zip_share_lead: "ZIP can be downloaded with this link until {{expiration}}. The link is considered to be confidental, please only share it with people familiar to you.",
            buchungsdatum: "Entry Dt",
            csv: "Download CSV",
            csv_tooltip: "Only loaded results show in the table are downloaded. See help section for more information about importing the CSV file in Excel.",
            dokument: "Documents",
            dokument_close: "Close document details",
            dokument_details: "Document",
            dokument_details_btn: "Show",
            dokument_filter_date_from: "Date From",
            dokument_filter_date_to: "Date To",
            dokument_filter_dokumenttyp: "Document type",
            dokument_filter_read: "Read/Unread",
            dokument_filter_read_ALL: "All",
            dokument_filter_read_READ: "Read",
            dokument_filter_read_UNREAD: "Unread",
            dokument_load: "Search",
            dokument_mark_read_one: "Mark {{count}} document read",
            dokument_mark_read_other: "Mark {{count}} documents read",
            dokument_search_total: "{{count}} of {{total}} results loaded",
            dokument_search_total_approx: "{{count}} of approx. {{total}} results loaded",
            dokument_select: "Select document",
            dokument_select_month: "Select all documents from month",
            dokumentbezeichnung: "Description",
            dokumentdatum: "Date",
            dokumente_zip_files_tooltip: "Download PDF documents as a ZIP file. Either select individual documents, otherwise all loaded results are downloaded. A maximum of 200 PDF documents can be downloaded in one ZIP.",
            dokumenttyp: "Type",
            download: "Download",
            eigb: "Accounting",
            eigb_type_aeg: "Sole ownership",
            eigb_type_meg: "Co-ownership",
            eigb_type_stewe: "Condominium",
            email_help: "Get notified via email when new data for your accountings is available.",
            enforce_2fa: "Please setup two factor authentication using email or TOTP. You can then use the Eigentümerportal again.",
            error_reply: "Error occurred",
            filter_eigb: "$t(eigb)",
            filter_geschaeftsjahr: "Business year",
            filter_liegb: "$t(liegb)",
            filter_no_match: "No accountings found",
            filter_person: "$t(person)",
            filter_search: "Search...",
            filter_select_all: "Select all",
            filter_selection: "selected",
            filter_selection_none: "None selected",
            freigabe: "Approvals",
            freigabe_admin: "System Approvals",
            freigabe_approve: "Register access code",
            freigabe_approve_code: "Access code",
            freigabe_approve_help: "Register another access code to your user account {{user}}.",
            freigabe_approve_submit: "Register",
            freigabe_create: "Create Approval",
            freigabe_create_description_help: "Free text field. For example the reason for this approval, name of invited person, etc. Only used internally and is never visible for the invited person.",
            freigabe_create_submit: "Create approval",
            freigabe_description: "Description",
            freigabe_eigb: "Approved accountings",
            freigabe_email: "Email",
            freigabe_email_help: "Email of the person to invite.",
            freigabe_entries: "Approvals",
            freigabe_filter_state: "State",
            freigabe_filter_truster: "Created by",
            freigabe_filter_truster_ALL: "User and System",
            freigabe_filter_truster_NON_ADMIN: "Users",
            freigabe_filter_truster_OWN: "Only own user",
            freigabe_intro: "You can approve any external person access your data available in the Eigentümerportal. To create a new approval, select the accountings you want the person to access and enter their email. The person will receive an email invitation with a link and can then register themselves on the Eigentümerportal. You can revoke the person's approval at anytime.",
            freigabe_load: "Search",
            freigabe_locale: "Email language",
            freigabe_non_admin: "Created Approvals",
            freigabe_person_gueltig_ab: "Valid from {{date}}",
            freigabe_person_gueltig_bis: " to {{date}}",
            freigabe_resend: "Resend invite",
            freigabe_resend_lead: "An invitation will be sent again via email to the specified email address. Expired invitations will be renewed again as of today.",
            freigabe_resend_submit: "Resend invitation email",
            freigabe_revoke: "Revoke",
            freigabe_revoke_confirmation: "Revoke approval?",
            freigabe_state: "State",
            freigabe_timestamp: "Created at",
            freigabe_truster: "By",
            freigabe_user: "Approved for",
            fullscreen_toggle: "Fullscreen",
            geschaeftsjahr: "Acc. Year",
            haben: "Credit CHF",
            kg2: "Account group",
            konto: "Account",
            kreditor: "Creditor",
            liegb: "Property",
            loading_stammdaten: "Loading data...",
            loading_zips: "Loading ZIPs...",
            new_password_help: "At least 10 characters. We recommend at least one digit and one special character.",
            news: "News",
            news_read: "Read posts",
            news_read_link: "Read post",
            news_read_new: "New",
            onboarding: "Registration",
            onboarding_account: "Do you already have an user account?",
            onboarding_account_existing: "Yes. Proceed with existing account",
            onboarding_account_new: "No. Register new account",
            onboarding_account_register: "Register new user account",
            onboarding_code: "Access code {{nr}}",
            onboarding_code_add: "Input additional codes",
            onboarding_code_invalid: "Access code invalid",
            onboarding_eigb: "Your approvals",
            onboarding_existing_failed: "Approval failed. Please try again. If the problem persists, please get in touch with your property manager.",
            onboarding_intro: "Register using an access code. If you aren't registered and didn't receive a code, please get in touch with your property manager.",
            onboarding_invalid: "We were unable to generate a access code. Please get in touch with your property manager.",
            onboarding_login_required: "Please login with your user to finish the approval process.",
            onboarding_new: "Register account",
            onboarding_new_2fa: "Two factor authentication",
            onboarding_new_2fa_email: "After this registration, you receive a one-time password for verification to the address entered above",
            onboarding_new_2fa_totp: "After this registration, a QR code is shown. Scan the code with a TOTP compatible app like Google Authenticator, FreeOTP Authenticator, Microsoft Authenticator and complete the verification process.",
            onboarding_new_email: "$t(user_setting_email)",
            onboarding_new_email_help: "$t(email_help)",
            onboarding_new_user: "User name",
            onboarding_new_user_help: "At least 3 characters. Letters (a-z, A-Z), numbers (0-9) and certain special characters ('.', '-', '_', '@', '+') are allowed.",
            onboarding_new_user_invalid: "Invalid or already existing user name",
            onboarding_start: "Register",
            onboarding_success: "Approval process successfully completed.",
            onboarding_token_invalid: "Provided token is invalid.",
            onboarding_validate_code: "Start validation",
            onboarding_verify_failed: "$t(save_user_verify_failed) If the problem persists, please get in touch with your property manager.",
            page_nav: "Page navigation",
            person: "Individual",
            search: "Search",
            search_dropdown: "Toggle search dropdown",
            search_placeholder: "{{fields}}",
            search_placeholder_invalid: "Please select at least one search field!",
            settings: "Settings",
            soll: "Debit CHF",
            title: "Schaeppi Eigentümerportal",
            user_setting_email: "Contact email",
            user_setting_email_help: "$t(email_help). This email is not connected to your login options, which you can change on the login screen.",
            user_setting_ignored_dok_types: "Ignored document types",
            user_setting_ignored_dok_types_help: "Selected document types are ignored in document search and not included in accounting ZIP.",
            user_setting_locale: "Language of correspondence",
            user_setting_locale_help: "Language used for email notifications.",
            user_setting_save: "Save settings",
            user_setting_save_success: "Settings saved.",
            version: "Portal Version {{version}}",
            zip_files: "Download ZIP",
            zip_files_download: "Download ({{size}})",
            zip_files_error: "ZIP creation failed.",
            zip_files_exceeded: "Number of search results exceed the maximum allowed 200. Please narrow down the search criterias.",
            zip_files_help: "Temporary ZIP ready. Close this dialog before trying to create another ZIP. Download expires on {{expiration}}.",
            zip_files_one: "Download {{count}} document",
            zip_files_other: "Download {{count}} documents",
            zip_files_progress: "Collecting and zipping PDF files"
        }
    }
};
