/*global */
import template from "./ansprechpartner.html";

export default Object.freeze({
    template,
    viewModel: {
        createViewModel: function ({viewmodel}) {
            const vm = {};

            vm.viewmodel = viewmodel;

            return vm;
        }
    }
});
