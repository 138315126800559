<!-- portal-web/modules/component/ansprechpartner -->
<div class="modal" data-bind="modal: viewmodel.activeAnsprechpartner" role="dialog" tabindex="-1">
    <div class="modal-dialog" id="ansprechpartnerModal">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title h4" data-bind="i18n: 'ansprechpartner'"></h1>
                <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
            </div>
            <div class="modal-body" data-bind="if: viewmodel.activeAnsprechpartner">
                <p class="mb-0" data-bind="i18n: 'ansprechpartner_missing', visible: Object.keys($data).length <= 0"></p>
                <div data-bind="with: viewmodel.activeAnsprechpartner().eigb">
                    <h2 class="h5">
                        <i class="bi bi-journals"></i> <span data-bind="text: name"></span>
                    </h2>
                    <div data-bind="template: {name: 'ansprechpartner-template', data: ansprechpartner}"></div>
                </div>
                <div data-bind="with: viewmodel.activeAnsprechpartner().liegb">
                    <h2 class="h5">
                        <i class="bi bi-building"></i> <span data-bind="text: name"></span>
                    </h2>
                    <div data-bind="template: {name: 'ansprechpartner-template', data: ansprechpartner}"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<script id="ansprechpartner-template" type="text/html">
    <ul class="list-group" data-bind="foreach: $data">
        <li class="list-group-item">
            <address class="d-flex flex-column mb-0">
                <span class="fw-bold" data-bind="text: rolleBezI18n"></span>
                <span data-bind="text: rolleAnrI18n"></span>
                <span data-bind="text: rolleName"></span>
                <span data-bind="text: rolleAdr"></span>
                <a data-bind="attr: {href: 'mailto:' + rolleEmail}">
                    <span data-bind="text: rolleEmail"></span>
                </a>
                <a data-bind="attr: {href: 'tel:' + rolleTel}">
                    <span data-bind="text: rolleTel"></span>
                </a>
            </address>
        </li>
    </ul>
</script>
