/*global requestAnimationFrame */
import Logger from "js-logger";
import ko from "knockout";
import clientViewmodel from "meta-client/modules/viewmodel";
import metaModel from "meta-core/modules/model";
import metaProtocol from "meta-core/modules/protocol";
import model from "portal-core/modules/model";
import protocol from "portal-core/modules/protocol";
import _ from "underscore";
import i18next from "util-web/modules/i18next";
import nav from "util-web/modules/nav";
import ui from "util-web/modules/ui";
import template from "./onboarding.html";

const LOG = Logger.get("portal-web/component/onboarding");
const VALIDATION_STATE_OK = 1;
const VALIDATION_STATE_NOK = 2;

export default Object.freeze({
    template,
    viewModel: {
        createViewModel: function ({isLoginRequired, isOnboardingSuccess, viewmodel}) {
            const newCodeVm = function () {
                const codeVm = {
                    code: ko.observable(),
                    hasFocus: ko.observable(false),
                    validationState: ko.observable()
                };
                codeVm.isValidFormat = ko.pureComputed(function () {
                    const code = codeVm.code();
                    return _.isEmpty(code) || 14 === code.length;
                });
                codeVm.code.subscribe(function () {
                    codeVm.validationState(undefined);
                });
                codeVm.isValid = ko.pureComputed(function () {
                    if (codeVm.validationState()) {
                        return VALIDATION_STATE_OK === codeVm.validationState();
                    }
                    return codeVm.isValidFormat();
                });
                return codeVm;
            };
            const vm = {
                i18next,
                otpTypes: metaModel.metaUserOtpType,
                viewmodel,
                changeLocale(locale) {
                    i18next.setLocale(locale);
                }
            };

            vm.scrollBottom = function () {
                requestAnimationFrame(function () {
                    document.getElementById("onboardingBottom").scrollIntoView();
                });
            };

            vm.scrollTop = function () {
                requestAnimationFrame(function () {
                    document.getElementById("logoLogin").scrollIntoView(false);
                });
            };

            vm.isInvalidParam = ko.observable();

            vm.codes = ko.observableArray();

            vm.addCode = function (code = null) {
                vm.codes().forEach((codeVm) => codeVm.hasFocus(false));
                const codeVm = newCodeVm();
                if (code) {
                    codeVm.code(code);
                }
                vm.codes.push(codeVm);
                setTimeout(function () {
                    codeVm.hasFocus(true);
                }, 100);
                vm.scrollBottom();
            };

            vm.validEntries = ko.observableArray();
            vm.isValidatingFreigabe = ko.observable(false);
            vm.tokenValidationState = ko.observable();

            vm.hasValidFreigabe = ko.pureComputed(function () {
                return VALIDATION_STATE_OK === vm.tokenValidationState() || (0 < vm.codes().length && vm.codes().length === vm.codes().filter((codeVm) => VALIDATION_STATE_OK === codeVm.validationState()).length);
            });

            vm.validateFreigabeInternal = function (param) {
                LOG.debug("validateFreigabeInternal", param);
                vm.isValidatingFreigabe(true);
                vm.validEntries.removeAll();
                vm.validationParam = {};
                return vm.viewmodel.client.execute(metaProtocol.executeRequest({
                    actionId: model.FREIGABE_VALIDATE_ACTION_ID,
                    param
                })).then(function (reply) {
                    vm.validationParam = param;
                    return reply.result.entriesByCode;
                }).then(undefined, function (exc) {
                    LOG.warn("validateFreigabeInternal failed", exc);
                    return {};
                }).then(function (entriesByCode) {
                    vm.isValidatingFreigabe(false);
                    vm.scrollBottom();
                    return entriesByCode;
                });
            };

            vm.validateCode = function (form) {
                LOG.debug("validateCode");
                if (ui.validateForm(form) === false) {
                    return;
                }
                vm.codes.remove((codeVm) => _.isEmpty(codeVm.code()));
                const param = model.freigabeValidateActionParam({
                    codes: vm.codes().map((codeVm) => codeVm.code()).filter((code) => _.isEmpty(code) === false)
                });
                return vm.validateFreigabeInternal(param).then(function (entriesByCode) {
                    vm.codes().forEach(function (codeVm) {
                        const code = codeVm.code();
                        const entries = _.get(entriesByCode, code);
                        const isValid = _.isEmpty(entries) === false;
                        LOG.debug(`validateCode, code=${code}, isValid=${isValid}`);
                        codeVm.validationState(
                            isValid
                                ? VALIDATION_STATE_OK
                                : VALIDATION_STATE_NOK
                        );
                        if (isValid) {
                            ko.utils.arrayPushAll(vm.validEntries, entries);
                        }
                    });
                    vm.codes().forEach((codeVm) => codeVm.hasFocus(VALIDATION_STATE_NOK === codeVm.validationState()));
                    if (_.isEmpty(vm.codes())) {
                        vm.addCode();
                    }
                });
            };

            vm.isInvalidToken = ko.pureComputed(function () {
                return VALIDATION_STATE_NOK === vm.tokenValidationState();
            });

            vm.validateToken = function (token) {
                const param = model.freigabeValidateActionParam({token});
                return vm.validateFreigabeInternal(param).then(function (entriesByCode) {
                    const codes = Object.keys(entriesByCode);
                    let validationState = VALIDATION_STATE_NOK;
                    if (_.isEmpty(codes) === false) {
                        const entries = entriesByCode[codes[0]];
                        if (_.isEmpty(entries) === false) {
                            ko.utils.arrayPushAll(vm.validEntries, entriesByCode[codes[0]]);
                            validationState = VALIDATION_STATE_OK;
                        }
                    }
                    vm.tokenValidationState(validationState);
                });
            };

            vm.reset = function () {
                vm.codes.removeAll();
                vm.validEntries.removeAll();
                vm.tokenValidationState(undefined);
                vm.isApprovingNewStarted(false);
                vm.user(undefined);
                vm.email(undefined);
                vm.password(undefined);
                vm.passwordConfirm(undefined);
                vm.unmaskPasswords(false);
                vm.otpType(undefined);
            };

            vm.navigateToIndex = function () {
                vm.reset();
                const params = nav.newParams();
                params.set(vm.viewmodel.COMPONENTS.INDEX, "");
                return nav.replaceState(params);
            };

            vm.approveExisting = function () {
                LOG.debug("approveExisting");
                vm.viewmodel.authCallback(function () {
                    return vm.viewmodel.client.execute(metaProtocol.executeRequest({
                        actionId: model.FREIGABE_APPROVE_ACTION_ID,
                        param: model.freigabeApproveActionParam(
                            Object.assign({locale: i18next.currentLocale()}, vm.validationParam)
                        )
                    })).then(function (reply) {
                        vm.viewmodel.onboardingGroups(reply.result.groups);
                    });
                });
                isLoginRequired(true);
                return vm.navigateToIndex().then(() => vm.scrollTop());
            };

            vm.isApprovingNewStarted = ko.observable(false);

            vm.startApproveNew = function () {
                LOG.debug("startApproveNew");
                vm.isApprovingNewStarted(true);
                vm.scrollBottom();
                vm.hasUserFocus(true);
            };

            vm.otpTypeOptions = [vm.otpTypes.EMAIL, vm.otpTypes.TOTP].map(function (key) {
                return {
                    name: i18next.computedT("otp_type_" + key),
                    type: key
                };
            });
            vm.hasUserFocus = ko.observable(false);
            vm.isApprovingNew = ko.observable();
            vm.user = ko.observable();
            vm.isUserValid = ko.observable(true);
            vm.user.subscribe(function () {
                vm.isUserValid(true);
            });
            vm.email = ko.observable();
            vm.password = ko.observable();
            vm.passwordConfirm = ko.observable();
            vm.unmaskPasswords = ko.observable(false);
            vm.isPasswordValid = clientViewmodel.newPasswordValidator({
                passwordConfirm: vm.passwordConfirm,
                passwordNew: vm.password,
                regexString: vm.viewmodel.config.passwordRegex,
                unmask: vm.unmaskPasswords
            });
            vm.otpType = ko.observable();
            vm.onVerify = ko.observable();
            vm.onVerifyDone = ko.observable();
            vm.isVerifyFailed = ko.observable(false);

            vm.togglePasswordMasking = function () {
                vm.unmaskPasswords(!vm.unmaskPasswords());
            };

            vm.approveNew = function (form) {
                if (ui.validateForm(form) === false) {
                    return;
                }
                vm.isApprovingNew(true);
                const user = vm.user();
                LOG.debug(`approveNew, user=${user}`);
                const approveCreateUser = {
                    email: vm.email(),
                    otpType: vm.otpType(),
                    password: vm.password(),
                    user
                };
                const approveParam = Object.assign({locale: i18next.currentLocale()}, vm.validationParam);
                return vm.viewmodel.client.execute(metaProtocol.executeRequest({
                    actionId: model.FREIGABE_APPROVE_ACTION_ID,
                    param: model.freigabeApproveActionParam(Object.assign({
                        user: model.freigabeApproveActionCreateUser(approveCreateUser)
                    }, approveParam))
                })).then(function (reply) {
                    vm.viewmodel.errorReply(undefined);
                    const result = reply.result;
                    if (result.verifyOtp) {
                        LOG.debug("approveNew verifyOtp");
                        return new Promise(function (resolve, reject) {
                            const verifySub = vm.onVerifyDone.subscribe(function (result) {
                                verifySub.dispose();
                                const otp = _.get(result, "otp");
                                LOG.debug(`approveNew onVerifyDone, otp=${otp}`);
                                if (otp) {
                                    resolve(otp);
                                } else {
                                    reject();
                                }
                            });
                            vm.onVerify({
                                totpIssuer: vm.viewmodel.config.totpIssuer,
                                totpKey: result.totpKey,
                                user
                            });
                        }).then(function (otp) {
                            approveCreateUser.otp = otp;
                            return vm.viewmodel.client.execute(metaProtocol.executeRequest({
                                actionId: model.FREIGABE_APPROVE_ACTION_ID,
                                param: model.freigabeApproveActionParam(Object.assign({
                                    user: model.freigabeApproveActionCreateUser(approveCreateUser)
                                }, approveParam))
                            }));
                        });
                    }
                    return Promise.resolve(undefined);
                }).then(function () {
                    LOG.debug("approveNew success");
                    vm.isVerifyFailed(false);
                    ui.resetForm(form);
                    isOnboardingSuccess(true);
                    return vm.navigateToIndex();
                }, function (exc) {
                    const errorCode = _.get(exc, "code", 0);
                    if (protocol.ONBOARDING_USER_ERROR === errorCode) {
                        LOG.info(`approveNew ONBOARDING_USER_ERROR, user=${user}`);
                        vm.isUserValid(false);
                    } else if (protocol.ONBOARDING_OTP_ERROR === errorCode) {
                        LOG.info(`approveNew ONBOARDING_OTP_ERROR, user=${user}`);
                        vm.isVerifyFailed(true);
                    } else {
                        vm.viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
                        LOG.warn("approveNew failed", exc);
                    }
                }).then(function () {
                    vm.isApprovingNew(false);
                    vm.scrollTop();
                });
            };

            vm.onNavRemove = function () {
                LOG.debug("onNavRemove");
                viewmodel.activeComponent(undefined);
                vm.viewmodel.isActive(false);
                return vm.viewmodel.client.logout(metaProtocol.logoutRequest());
            };

            vm.onNavUpdate = function (param) {
                LOG.debug(`onNavUpdate, param=${param}`);
                let setupPromise = Promise.resolve(undefined);
                if (vm.viewmodel.client.isConnected()) {
                    if (vm.viewmodel.client.user()) {
                        setupPromise = vm.viewmodel.client.logout(metaProtocol.logoutRequest());
                    }
                } else {
                    setupPromise = vm.viewmodel.client.connect();
                }
                return setupPromise.then(function () {
                    return vm.viewmodel.client.login(metaProtocol.loginRequest({
                        client: "portal-web",
                        locale: i18next.currentLocale(),
                        user: vm.viewmodel.config.publicUser,
                        version: vm.viewmodel.version
                    }));
                }).then(function () {
                    const currentParams = nav.currentParams();
                    const tokenParam = currentParams.get(protocol.ONBOARDING_TOKEN_PARAM);
                    vm.viewmodel.activeComponent(vm.viewmodel.COMPONENTS.ONBOARDING);
                    vm.viewmodel.isActive(true);
                    if (tokenParam) {
                        LOG.debug(`onNavUpdate, tokenParam=${tokenParam}`);
                        return vm.validateToken(tokenParam);
                    }
                    if (param) {
                        if (vm.viewmodel.config.onboardingInvalidParam === param) {
                            vm.isInvalidParam(true);
                        } else {
                            vm.addCode(param);
                            return vm.validateCode(document.getElementById("validateCode"));
                        }
                    }
                    if (vm.codes().length <= 0) {
                        vm.addCode();
                    }
                });
            };

            nav.register({
                id: vm.viewmodel.COMPONENTS.ONBOARDING,
                onRemove: vm.onNavRemove,
                onUpdate: vm.onNavUpdate
            });

            return vm;
        }
    }
});
