<!-- portal-web/modules/component/buchhaltung -->
<div id="buchhaltung">
    <div class="row gx-4 gy-3">
        <div class="col-12 col-sm-4 col-xl-3">
            <h1 class="display-6" data-bind="i18n: 'buchhaltung'"></h1>
            <div class="d-grid gap-2" data-bind="if: viewmodel.isSchaeppiUser()">
                <button class="btn btn-schaeppi mb-2" data-bind="
                    click: adminLoadOwnGroups,
                    i18n: 'admin_load_own_groups_submit'
                " id="buchhaltungAdminLoadOwnGroupsBtn" type="button"></button>
            </div>
            <div class="vstack gap-3 mb-3">
                <div>
                    <div class="d-flex justify-content-between mb-2">
                        <label class="form-label text-truncate"
                            data-bind="i18n: 'buchhaltung_search_query'"
                            for="buchhaltungSearchQuery"></label>
                        <div data-bind="visible: isSearchQueryValid">
                            <button class="btn btn-schaeppi btn-sm"
                                data-bind="click: resetSearchQuery"
                                id="buchhaltungSearchQueryReset"
                                type="button">
                                <i class="bi bi-trash"></i>
                            </button>
                        </div>
                    </div>
                    <input class="form-control" data-bind="
                        attr: {placeholder: i18next.pureComputedT('buchhaltung_search_query_placeholder')},
                        hasFocus: hasSearchQueryFocus,
                        textInput: searchQuery
                    " id="buchhaltungSearchQuery" type="text">
                </div>
                <div data-bind="visible: 1 < viewmodel.userPersonen().length">
                    <div class="d-flex justify-content-between mb-2">
                        <label class="form-label text-truncate" for="buchhaltungFilterPers">
                            <i class="bi bi-people"></i>
                            <span data-bind="i18n: 'filter_person'"></span>
                        </label>
                        <div data-bind="visible: 0 < viewmodel.filterPersSelectedIds().length">
                            <button class="btn btn-schaeppi btn-sm"
                                data-bind="click: viewmodel.resetPersFilter"
                                id="buchhaltungFilterPersReset"
                                type="button">
                                <i class="bi bi-trash"></i>
                            </button>
                        </div>
                    </div>
                    <div class="dropdown d-grid">
                        <button aria-expanded="false"
                            class="btn btn-secondary dropdown-toggle"
                            data-bind="click: focusPersFilter"
                            data-bs-auto-close="outside"
                            data-bs-toggle="dropdown"
                            id="buchhaltungFilterPers"
                            type="button">
                            <span data-bind="i18n: 'filter_selection_none', visible: viewmodel.filterPersSelectedIds().length <= 0"></span>
                            <span data-bind="visible: 0 < viewmodel.filterPersSelectedIds().length">
                                <span class="badge rounded-pill bg-schaeppi"
                                    data-bind="text: viewmodel.filterPersSelectedIds().length"></span>
                                <span data-bind="i18n: 'filter_selection'"></span>
                            </span>
                        </button>
                        <div aria-labelledby="buchhaltungFilterPers" class="dropdown-menu dropdown-menu-end p-3" style="min-width: 20rem;">
                            <a data-bind="click: viewmodel.selectAllPersVisible, i18n: 'filter_select_all'"
                                href="#"
                                id="buchhaltungFilterPersSelectAll"></a>
                            <div class="input-group mt-3">
                                <input autocomplete="off" class="form-control" data-bind="
                                    attr: {placeholder: i18next.pureComputedT('filter_search')},
                                    hasFocus: hasPersFilterFocus,
                                    textInput: viewmodel.filterPersQuery
                                " id="buchhaltungFilterPersSearch" type="text">
                                <button class="btn btn-outline-secondary"
                                    data-bind="click: viewmodel.resetPersQuery"
                                    id="buchhaltungFilterPersSearchReset"
                                    type="button">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                            <div class="overflow-y-auto" data-bind="foreach: viewmodel.filterPersVisible" style="max-height: 21rem;">
                                <div class="form-check mb-0 mt-2">
                                    <input class="form-check-input" data-bind="
                                        attr: {id: 'buchhaltungFilterPers' + persId},
                                        checked: selected
                                    " type="checkbox">
                                    <label class="form-check-label text-nowrap"
                                        data-bind="text: persName, attr: {for: 'buchhaltungFilterPers' + persId}"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p class="text-muted" data-bind="i18n: 'filter_no_match', visible: hasNoEigbMatches()"></p>
            <div class="overflow-auto" data-bind="fixHeight: hasSearchQueryFocus, fixHeightMargin: 20">
                <ul class="d-grid gap-2 list-unstyled mb-0"
                    data-bind="foreach: buchhaltungenWithNewData, css: {'mb-0': 0 === buchhaltungenWithNewData().length, 'mb-2': 0 < buchhaltungenWithNewData().length}">
                    <li class="position-relative">
                        <a class="buchhaltung-link d-block rounded" data-bind="
                            attr: {id: 'buchhaltung' + eigbId},
                            click: $component.navigateToBuchhaltung,
                            css: {active: $component.activeBuchhaltung() && eigbId === $component.activeBuchhaltung().eigbId},
                        " href="#">
                            <div class="d-flex">
                                <span class="flex-grow-1 text-truncate" data-bind="text: bez"></span>
                                <span class="buchhaltung-highlight me-2" data-bind="attr: {'title': $component.newDataTitle}">
                                    <i class="bi bi-file-arrow-up"></i>
                                </span>
                                <!-- ko if: isAdmin() === false -->
                                <i class="bi bi-person-plus" data-bind="attr: {'title': $component.nonAdminTitle}"></i>
                                <!-- /ko -->
                            </div>
                            <span class="position-absolute top-0 start-0 translate-middle p-2 bg-success border border-light rounded-circle">
                                <span class="visually-hidden" data-bind="i18n: 'buchhaltung_new_data'"></span>
                            </span>
                        </a>
                    </li>
                </ul>
                <ul class="d-grid gap-2 list-unstyled mb-0" data-bind="foreach: buchhaltungenWithoutNewData">
                    <li class="position-relative">
                        <a class="buchhaltung-link d-block rounded" data-bind="
                            attr: {id: 'buchhaltung' + eigbId},
                            click: $component.navigateToBuchhaltung,
                            css: {active: $component.activeBuchhaltung() && eigbId === $component.activeBuchhaltung().eigbId}
                        " href="#">
                            <div class="d-flex">
                                <span class="flex-grow-1 text-truncate" data-bind="text: bez"></span>
                                <!-- ko if: isAdmin() === false -->
                                <i class="bi bi-person-plus" data-bind="attr: {'title': $component.nonAdminTitle}"></i>
                                <!-- /ko -->
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-12 col-sm-8 col-xl-9" data-bind="with: activeBuchhaltung">
            <div class="d-flex align-items-baseline pb-2">
                <h2 class="display-6 text-schaeppi mb-0" data-bind="text: bez"></h2>
                <small class="text-muted ms-2" data-bind="text: ref"></small>
            </div>
            <div class="row g-3" id="buchhaltungActive">
                <div class="col-12 col-xl-7">
                    <div class="card mb-3">
                        <div class="card-header text-bg-light">
                            <h3 class="card-title h4 mb-0" data-bind="i18n: 'buchhaltung_data'"></h3>
                        </div>
                        <div class="card-body">
                            <div class="alert alert-schaeppi shadow" data-bind="visible: importMessage" id="buchhaltungImportMessage">
                                <h4 class="h5" data-bind="i18n: 'buchhaltung_import_message'"></h4>
                                <p class="mb-0" data-bind="html: importMessage"></p>
                            </div>
                            <div data-bind="ifnot: belegTimestampFormatted || dokumentTimestampFormatted">
                                <p class="mb-0" data-bind="i18n: 'buchhaltung_no_data'"></p>
                            </div>
                            <div data-bind="if: belegTimestampFormatted || dokumentTimestampFormatted">
                                <h4 class="h5" data-bind="i18n: 'buchhaltung_upload'"></h4>
                                <div class="d-xl-flex justify-content-between">
                                    <span data-bind="visible: belegTimestampFormatted">
                                        <i class="bi bi-receipt-cutoff"></i>
                                        <span data-bind="
                                            i18n: {key: 'buchhaltung_beleg_timestamp', options: {timestamp: belegTimestampFormatted}}
                                        "></span>
                                        <br>
                                    </span>
                                    <span data-bind="visible: dokumentTimestampFormatted">
                                        <i class="bi bi-file-earmark"></i>
                                        <span data-bind="
                                            i18n: {key: 'buchhaltung_dokument_timestamp', options: {timestamp: dokumentTimestampFormatted}}
                                        "></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="d-flex flex-column flex-lg-row gap-2">
                                <button class="btn btn-outline-secondary btn-sm flex-lg-fill"
                                    data-bind="click: $component.showBelegeEigb"
                                    id="buchhaltungShortcutBeleg"
                                    type="button">
                                    <i class="bi bi-receipt-cutoff"></i>
                                    <span data-bind="i18n: 'buchhaltung_beleg'"></span>
                                </button>
                                <button class="btn btn-outline-secondary btn-sm flex-lg-fill"
                                    data-bind="click: $component.showDokumenteEigb"
                                    id="buchhaltungShortcutDokument"
                                    type="button">
                                    <i class="bi bi-file-earmark"></i>
                                    <span data-bind="i18n: 'buchhaltung_dokument'"></span>
                                </button>
                                <!-- ko if: isAdmin() -->
                                <button class="btn btn-outline-secondary btn-sm flex-lg-fill"
                                    data-bind="click: $component.showFreigabeEigb"
                                    id="buchhaltungShortcutFreigabe"
                                    type="button">
                                    <i class="bi bi-person"></i>
                                    <span data-bind="i18n: 'buchhaltung_freigabe'"></span>
                                </button>
                                <!-- /ko -->
                                <button class="btn btn-outline-secondary btn-sm flex-lg-fill"
                                    data-bind="click: $component.activateAnsprechpartnerEigb.bind(undefined, $data)"
                                    id="buchhaltungShortcutAnsprechpartner"
                                    type="button">
                                    <i class="bi bi-person-rolodex"></i>
                                    <span data-bind="i18n: 'ansprechpartner'"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- ko if: 0 < $component.activeBuchhaltungGeschaeftsjahre().length -->
                    <div class="card mb-3">
                        <div class="card-header text-bg-light">
                            <h3 class="card-title h4 mb-0" data-bind="i18n: 'buchhaltung_download'"></h3>
                        </div>
                        <div class="card-body">
                            <form class="row g-3" data-bind="submit: $component.executeZipGeschaeftsjahr" id="buchhaltungZip" novalidate>
                                <div class="col-12 col-lg-5">
                                    <label class="form-label text-truncate" for="buchhaltungZipPerson">
                                        <i class="bi bi-people"></i>
                                        <span data-bind="i18n: 'filter_person'"></span>
                                        <i class="bi bi-info-circle-fill text-schaeppi ms-2"
                                            data-bind="tooltip: 'buchhaltung_filter_person_help'"></i>
                                    </label>
                                    <select aria-describedby="buchhaltungZipHelp" class="form-select" data-bind="
                                        attr: {disabled: $component.activeBuchhaltungPersonen().length < 2},
                                        options: $component.activeBuchhaltungPersonen,
                                        optionsCaption: $component.zipPersonOptionsCaption,
                                        optionsText: 'persName',
                                        validity: $component.isZipPersonValid,
                                        value: $component.zipPerson,
                                    " id="buchhaltungZipPerson"></select>
                                </div>
                                <div class="col-12 col-lg-7">
                                    <label class="form-label text-truncate" for="buchhaltungZipGeschaeftsjahr">
                                        <span data-bind="i18n: 'filter_geschaeftsjahr'"></span>
                                        <i class="bi bi-info-circle-fill text-schaeppi ms-2"
                                            data-bind="tooltip: $component.portalPlusState.DISABLED === ppState() ? 'buchhaltung_filter_geschaeftsjahr_standard_help' : 'buchhaltung_filter_geschaeftsjahr_help'"></i>
                                    </label>
                                    <select aria-describedby="buchhaltungZipHelp" class="form-select" data-bind="
                                        options: $component.activeBuchhaltungGeschaeftsjahre,
                                        optionsText: 'text',
                                        optionsValue: 'geschaeftsjahr',
                                        validity: $component.isZipGeschaeftsjahrValid,
                                        value: $component.zipGeschaeftsjahr
                                    " id="buchhaltungZipGeschaeftsjahr"></select>
                                </div>
                                <div class="form-text" data-bind="i18n: 'buchhaltung_zip_help'" id="buchhaltungZipHelp"></div>
                                <button class="btn btn-schaeppi" type="submit">
                                    <span data-bind="i18n: 'buchhaltung_zip'"></span>
                                </button>
                            </form>
                        </div>
                        <div class="card-body text-center" data-bind="visible: $component.isLoadingZips">
                            <div class="spinner-border" role="status" style="width: 2rem; height: 2rem;">
                                <span class="visually-hidden" data-bind="i18n: 'loading_zips'"></span>
                            </div>
                        </div>
                        <!-- ko if: 0 < $component.zips.objects().length -->
                        <ul class="list-group list-group-flush" data-bind="foreach: $component.zips.objects">
                            <li class="list-group-item zip-item">
                                <div class="d-flex gap-1 align-items-center">
                                    <div class="flex-shrink-1 flex-fill text-truncate">
                                        <span class="fw-bold" data-bind="text: year"></span> -
                                        <span class="fw-bold"
                                            data-bind="i18n: {key: 'buchhaltung_zip_geschaeftsjahr_' + state(), options: {persName: persName}}"></span>
                                    </div>
                                    <small class="text-muted text-nowrap" data-bind="text: sizeFormatted"></small>
                                    <a class="btn btn-sm btn-schaeppi" data-bind="
                                        attr: {
                                            href: url,
                                            id: 'buchhaltungDownloadZipBtn' + id,
                                            title: $component.downloadZipTitle
                                        },
                                        css: {disabled: $component.zipState.READY !== state()}
                                    " role="button" target="_blank">
                                        <i class="bi bi-file-earmark-arrow-down"></i>
                                    </a>
                                    <button class="btn btn-sm btn-outline-secondary" data-bind="
                                        attr: {
                                            disabled: $component.zipState.READY !== state(),
                                            id: 'buchhaltungShareZipBtn' + id,
                                            title: $component.shareZipTitle
                                        },
                                        click: $component.activateShareZip
                                    " type="button">
                                        <i class="bi bi-share"></i>
                                    </button>
                                    <button class="btn btn-sm btn-outline-secondary" data-bind="
                                        attr: {
                                            disabled: $component.zipState.EMPTY !== state() && $component.zipState.ERROR !== state() && $component.zipState.READY !== state(),
                                            id: 'buchhaltungDeleteZipBtn' + id,
                                            title: $component.deleteZipTitle
                                        },
                                        click: $component.deleteZip
                                    " type="button">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                </div>
                                <div data-bind="visible: $component.zipState.PENDING === state() || $component.zipState.PROCESSING === state()">
                                    <div class="spinner-border spinner-border-sm" role="status"></div>
                                </div>
                                <small class="text-warning me-2" data-bind="
                                    tooltip: 'buchhaltung_zip_outdated_tooltip',
                                    visible: isOutdated
                                ">
                                    <i class="bi bi-exclamation-triangle-fill"></i>
                                    <span data-bind="i18n: 'buchhaltung_zip_outdated'"></span>
                                </small>
                                <small data-bind="
                                    i18n: {
                                        key: 'buchhaltung_zip_geschaeftsjahr_info',
                                        options: {expiration: expirationFormatted, timestamp: timestampFormatted, user}
                                    },
                                    visible: $component.zipState.READY === state()
                                "></small>
                                <small data-bind="
                                    i18n: 'buchhaltung_zip_geschaeftsjahr_info_error',
                                    visible: $component.zipState.ERROR === state()
                                "></small>
                            </li>
                        </ul>
                        <!-- /ko -->
                    </div>
                    <!-- /ko -->
                    <div class="card mb-xl-3">
                        <div class="card-header text-bg-light">
                            <h3 class="card-title h4 mb-0" data-bind="i18n: 'buchhaltung_portalplus_header'"></h3>
                        </div>
                        <div class="card-body text-center" data-bind="visible: $component.isLoadingPpAbos">
                            <div class="spinner-border" role="status" style="width: 2rem; height: 2rem;">
                                <span class="visually-hidden" data-bind="i18n: 'loading_pp_abos'"></span>
                            </div>
                        </div>
                        <div class="card-body">
                            <h4 class="h5" data-bind="i18n: 'buchhaltung_portalplus_subscription'"></h4>
                            <div class="alert alert-light bg-portalstandard"
                                data-bind="visible: $component.portalPlusState.DISABLED === ppState() && ppOverride === false"
                                id="buchhaltungPortalPlusDisabled">
                                <span data-bind="i18n: 'buchhaltung_portalplus_disabled'"></span>
                            </div>
                            <!-- ko if: 0 < $component.activeBuchhaltungPortalPlusAbos().length -->
                            <div class="alert alert-light bg-portalstandard"
                                data-bind="visible: $component.portalPlusState.CANCELED === ppState()"
                                id="buchhaltungPortalPlusCanceled">
                                <span data-bind="i18n: {key: 'buchhaltung_portalplus_canceled', options: {cancelDate: $component.activeBuchhaltungPortalPlusAbos()[0].cancelDateFormatted, endeDate: $component.activeBuchhaltungPortalPlusAbos()[0].endeDateFormatted}}"></span>
                            </div>
                            <div class="alert alert-light bg-portalstandard"
                                data-bind="visible: $component.portalPlusState.DELAYED === ppState()"
                                id="buchhaltungPortalPlusDelayed">
                                <i class="bi bi-hourglass-split"></i>
                                <span data-bind="i18n: {key: 'buchhaltung_portalplus_delayed', options: {date: $component.activeBuchhaltungPortalPlusAbos()[0].beginDateFormatted}}"></span>
                            </div>
                            <div class="alert alert-light bg-portalplus"
                                data-bind="visible: $component.portalPlusState.ENABLED === ppState()"
                                id="buchhaltungPortalPlusEnabled">
                                <span data-bind="i18n: {key: 'buchhaltung_portalplus_enabled', options: {date: $component.activeBuchhaltungPortalPlusAbos()[0].beginDateFormatted}}"></span>
                                <i class="bi bi-info-circle-fill text-schaeppi ms-2"
                                    data-bind="tooltip: 'buchhaltung_portalplus_enabled_help'"></i>
                            </div>
                            <!-- /ko -->
                            <!-- ko if: isAdmin() && ppOverride === false -->
                            <div class="d-grid gap-3">
                                <button class="btn btn-outline-secondary" data-bind="
                                    busyButton: $component.isPortalPlusProcessing,
                                    click: $component.activateCancelPortalPlus,
                                    visible: $component.portalPlusState.ENABLED === ppState()
                                " id="buchhaltungPortalPlusCancelBtn" type="button">
                                    <span data-bind="i18n: 'buchhaltung_portalplus_cancel'"></span>
                                </button>
                                <button class="btn btn-outline-secondary" data-bind="
                                    busyButton: $component.isPortalPlusProcessing,
                                    click: $component.withdrawCancellation,
                                    visible: $component.portalPlusState.CANCELED === ppState()
                                " id="buchhaltungPortalPlusWithdrawCancellationBtn" type="button">
                                    <span data-bind="i18n: 'buchhaltung_portalplus_withdraw_cancelation'"></span>
                                </button>
                                <button class="btn btn-outline-secondary" data-bind="
                                    click: $component.activatePortalPlusHistory,
                                    visible: 0 < $component.activeBuchhaltungPortalPlusAbos().length
                                " id="buchhaltungPortalPlusHistoryBtn" type="button">
                                    <span data-bind="i18n: 'buchhaltung_portalplus_activate_history'"></span>
                                </button>
                            </div>
                            <!-- /ko -->
                            <!-- ko if: ppOverride -->
                            <div class="alert alert-light d-flex mb-0" data-bind="
                                css: {'mt-3': $component.portalPlusState.ENABLED === ppState()}
                            " id="buchhaltungPortalPlusOverride">
                                <div class="me-2">
                                    <i class="bi bi-lock"></i>
                                </div>
                                <div>
                                    <p class="fw-bold" data-bind="
                                        i18n: 'buchhaltung_portalplus_override_stewe',
                                        visible: $component.eigBuchhaltungType.STEWE === bhType && $component.portalPlusState.DISABLED === ppState()
                                    "></p>
                                    <p class="mb-0" data-bind="i18n: 'buchhaltung_portalplus_override'"></p>
                                </div>
                            </div>
                            <!-- /ko -->
                            <div class="d-grid" data-bind="if: $component.canEnablePortalPlus($data) === false">
                                <button aria-controls="portalplusCompare"
                                    aria-expanded="false"
                                    class="btn btn-outline-secondary mt-3"
                                    data-bind="i18n: 'buchhaltung_portalplus_compare'"
                                    data-bs-target="#portalplusCompare"
                                    data-bs-toggle="collapse"
                                    id="portalPlusCompareBtn"
                                    type="button"></button>
                            </div>
                            <div class="collapse"
                                data-bind="css: {'mt-3': $component.canEnablePortalPlus($data) === false, show: $component.canEnablePortalPlus($data)}"
                                id="portalplusCompare">
                                <div class="row g-3">
                                    <div class="col-12 col-md-6">
                                        <div class="d-flex mb-2">
                                            <h4 class="h5 mb-0 pe-2" data-bind="i18n: 'buchhaltung_portalplus_standard'"></h4>
                                            <span class="badge bg-success" data-bind="
                                                i18n: 'buchhaltung_portalplus_active',
                                                visible: $component.portalPlusState.DISABLED === ppState()
                                            "></span>
                                        </div>
                                        <ul class="list-group">
                                            <li class="list-group-item d-flex">
                                                <div class="me-2">
                                                    <i class="bi bi-check-lg"></i>
                                                </div>
                                                <span data-bind="i18n: 'buchhaltung_portalplus_compare_access'"></span>
                                            </li>
                                            <li class="list-group-item d-flex">
                                                <div class="me-2">
                                                    <i class="bi bi-check-lg"></i>
                                                </div>
                                                <span data-bind="i18n: 'buchhaltung_portalplus_compare_search'"></span>
                                            </li>
                                            <li class="list-group-item d-flex">
                                                <div class="me-2">
                                                    <i class="bi bi-check-lg"></i>
                                                </div>
                                                <span data-bind="i18n: 'buchhaltung_portalplus_compare_download'"></span>
                                            </li>
                                            <li class="list-group-item bg-portalstandard d-flex">
                                                <div class="me-2">
                                                    <i class="bi bi-circle"></i>
                                                </div>
                                                <span data-bind="i18n: 'buchhaltung_portalplus_compare_geschaeftsjahre_standard'"></span>
                                            </li>
                                            <li class="list-group-item bg-portalstandard d-flex">
                                                <div class="me-2">
                                                    <i class="bi bi-circle"></i>
                                                </div>
                                                <span data-bind="i18n: 'buchhaltung_portalplus_compare_storage_standard'"></span>
                                            </li>
                                            <li class="list-group-item bg-portalstandard d-flex">
                                                <div class="me-2">
                                                    <i class="bi bi-piggy-bank"></i>
                                                </div>
                                                <span data-bind="i18n: 'buchhaltung_portalplus_compare_price_standard'"></span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="d-flex mb-2">
                                            <h4 class="h5 mb-0 pe-2 text-schaeppi" data-bind="i18n: 'buchhaltung_portalplus'"></h4>
                                            <span class="badge bg-success" data-bind="
                                                i18n: 'buchhaltung_portalplus_active',
                                                visible: $component.portalPlusState.DISABLED !== ppState()
                                            "></span>
                                        </div>
                                        <ul class="list-group">
                                            <li class="list-group-item d-flex">
                                                <div class="me-2">
                                                    <i class="bi bi-check-lg"></i>
                                                </div>
                                                <span data-bind="i18n: 'buchhaltung_portalplus_compare_access'"></span>
                                            </li>
                                            <li class="list-group-item d-flex">
                                                <div class="me-2">
                                                    <i class="bi bi-check-lg"></i>
                                                </div>
                                                <span data-bind="i18n: 'buchhaltung_portalplus_compare_search'"></span>
                                            </li>
                                            <li class="list-group-item d-flex">
                                                <div class="me-2">
                                                    <i class="bi bi-check-lg"></i>
                                                </div>
                                                <span data-bind="i18n: 'buchhaltung_portalplus_compare_download'"></span>
                                            </li>
                                            <li class="list-group-item bg-portalplus d-flex">
                                                <div class="text-schaeppi me-2">
                                                    <i class="bi bi-plus-circle"></i>
                                                </div>
                                                <span data-bind="i18n: 'buchhaltung_portalplus_compare_geschaeftsjahre'"></span>
                                                <i class="bi bi-info-circle-fill text-schaeppi ms-2"
                                                    data-bind="tooltip: 'buchhaltung_portalplus_compare_geschaeftsjahre_help'"></i>
                                            </li>
                                            <li class="list-group-item bg-portalplus d-flex">
                                                <div class="text-schaeppi me-2">
                                                    <i class="bi bi-plus-circle"></i>
                                                </div>
                                                <span data-bind="i18n: 'buchhaltung_portalplus_compare_storage'"></span>
                                            </li>
                                            <li class="list-group-item bg-portalplus d-flex">
                                                <div class="text-schaeppi me-2">
                                                    <i class="bi bi-piggy-bank"></i>
                                                </div>
                                                <span data-bind="i18n: {key: 'buchhaltung_portalplus_compare_price', options: {price: $component.price}}"></span>
                                                <i class="bi bi-info-circle-fill text-schaeppi ms-2"
                                                    data-bind="tooltip: 'buchhaltung_portalplus_compare_price_help'"></i>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- ko if: $component.canEnablePortalPlus($data) -->
                            <div class="d-grid mt-3">
                                <button class="btn btn-schaeppi" data-bind="
                                    busyButton: $component.isPortalPlusProcessing,
                                    buttonDisabled: $component.viewmodel.isSchaeppiUser(),
                                    click: $component.activateEnablePortalPlus
                                " id="buchhaltungPortalPlusEnableBtn" type="button">
                                    <span data-bind="i18n: 'buchhaltung_portalplus_enable'"></span>
                                </button>
                            </div>
                            <!-- /ko -->
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-5">
                    <div class="card mb-3">
                        <div class="card-header text-bg-light">
                            <h3 class="card-title h4 mb-0"
                                data-bind="i18n: {key: 'buchhaltung_liegenschaften', options: {count: buchhaltungen.length}}"></h3>
                        </div>
                        <ul class="buchhaltung-liegenschaften list-group list-group-flush" data-bind="foreach: buchhaltungen">
                            <li class="list-group-item">
                                <div class="d-flex gap-1 align-items-center">
                                    <div class="flex-shrink-1 flex-fill text-truncate">
                                        <span data-bind="text: bez"></span>
                                    </div>
                                    <small class="text-muted text-nowrap" data-bind="text: ref"></small>
                                    <button class="btn btn-outline-secondary btn-sm" data-bind="
                                        attr: {title: $component.belegeTitle},
                                        click: $component.showBelegeLiegb
                                    " type="button">
                                        <i class="bi bi-receipt-cutoff"></i>
                                    </button>
                                    <button class="btn btn-outline-secondary btn-sm" data-bind="
                                        attr: {title: $component.dokumenteTitle},
                                        click: $component.showDokumenteLiegb
                                    " type="button">
                                        <i class="bi bi-file-earmark"></i>
                                    </button>
                                    <button class="btn btn-outline-secondary btn-sm" data-bind="
                                        attr: {title: $component.ansprechpartnerTitle},
                                        click: $component.activateAnsprechpartnerLiegb.bind(undefined, $data)
                                    " type="button">
                                        <i class="bi bi-person-rolodex"></i>
                                    </button>
                                </div>
                                <small>
                                    <span data-bind="i18n: {key: 'buchhaltung_gueltig_ab', options: {date: gueltigAbFormatted}}"></span><span
                                    data-bind="
                                        i18n: {key: 'buchhaltung_gueltig_bis', options: {date: gueltigBisFormatted}},
                                        visible: gueltigBisFormatted
                                    "></span>
                                </small>
                            </li>
                        </ul>
                    </div>
                    <div class="card mb-3">
                        <div class="card-header text-bg-light">
                            <h3 class="card-title h4"
                                data-bind="i18n: {key: 'buchhaltung_personen', options: {count: personen.length}}"></h3>
                            <small data-bind="i18n: 'buchhaltung_personen_lead'"></small>
                        </div>
                        <ul class="buchhaltung-personen list-group list-group-flush" data-bind="foreach: personen">
                            <li class="list-group-item"
                                data-bind="css: {'text-schaeppi': $component.activeBuchhaltungPersonen().includes($data)}">
                                <div class="d-flex gap-1 align-items-center">
                                    <div class="flex-shrink-1 flex-fill text-truncate">
                                        <span data-bind="text: persName"></span>
                                    </div>
                                    <small class="text-muted text-nowrap" data-bind="text: persRef"></small>
                                </div>
                                <small>
                                    <span data-bind="i18n: {key: 'buchhaltung_gueltig_ab', options: {date: gueltigAbFormatted}}"></span><span
                                    data-bind="
                                        i18n: {key: 'buchhaltung_gueltig_bis', options: {date: gueltigBisFormatted}},
                                        visible: gueltigBisFormatted
                                    "></span>
                                </small>
                            </li>
                        </ul>
                    </div>
                    <div class="card mb-3">
                        <div class="card-header text-bg-light">
                            <h3 class="card-title h4 mb-0" data-bind="i18n: 'buchhaltung_glossary'"></h3>
                        </div>
                        <div class="card-body">
                            <dl class="row mb-0">
                                <dt class="col-3" data-bind="i18n: 'buchhaltung_glossary_vm'"></dt>
                                <dd class="col-9" data-bind="i18n: 'buchhaltung_glossary_vm_dd'"></dd>
                                <dt class="col-3" data-bind="i18n: 'buchhaltung_glossary_vmgmo'"></dt>
                                <dd class="col-9" data-bind="i18n: 'buchhaltung_glossary_vmgmo_dd'"></dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" data-bind="modal: isCancelPortalPlusActive" role="dialog" tabindex="-1">
    <div class="modal-dialog" id="buchhaltungPortalPlusCancelModal">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title h4" data-bind="i18n: 'buchhaltung_portalplus_cancel'"></h1>
                <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
            </div>
            <form data-bind="submit: executeCancelPortalPlus" id="buchhaltungPortalPlusCancel" novalidate>
                <div class="modal-body">
                    <p data-bind="i18n: 'buchhaltung_portalplus_cancel_lead'"></p>
                    <h2 class="h5" data-bind="i18n: 'buchhaltung_portalplus_select'"></h2>
                    <div class="overflow-auto" data-bind="foreach: buchhaltungenWithPortalPlusEnabled" style="max-height: 21rem;">
                        <div class="form-check">
                            <input class="form-check-input" data-bind="
                                attr: {id: 'buchhaltungPortalPlusCancelEigb' + eigbId},
                                checked: selected,
                                validity: 0 < $component.selectedEnabledEigbIds().length
                            " type="checkbox">
                            <label class="form-check-label text-nowrap" data-bind="
                                attr: {for: 'buchhaltungPortalPlusCancelEigb' + eigbId},
                                text: bez
                            "></label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-schaeppi" data-bind="busyButton: isPortalPlusProcessing" type="submit">
                        <span data-bind="i18n: 'buchhaltung_portalplus_cancel_submit'"></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal" data-bind="modal: isEnablePortalPlusActive" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" id="buchhaltungPortalPlusEnableModal">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title h4" data-bind="i18n: 'buchhaltung_portalplus_enable'"></h1>
                <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
            </div>
            <form data-bind="submit: executeEnablePortalPlus" id="buchhaltungPortalPlusEnable" novalidate>
                <div class="modal-body">
                    <p data-bind="i18n: {html: 'buchhaltung_portalplus_enable_lead'}"></p>
                    <div class="row">
                        <div data-bind="css: {'col-12': hasBuchhaltungenWithPortalPlusDisabledTypeMeg() === false && hasBuchhaltungenWithPortalPlusDisabledTypeStewe() === false, 'col-8': hasBuchhaltungenWithPortalPlusDisabledTypeMeg() || hasBuchhaltungenWithPortalPlusDisabledTypeStewe()}">
                            <h2 class="h5" data-bind="i18n: 'buchhaltung_portalplus_select'"></h2>
                            <div class="overflow-auto" data-bind="foreach: buchhaltungenWithPortalPlusDisabled" style="max-height: 21rem;">
                                <div class="form-check">
                                    <input class="form-check-input" data-bind="
                                        attr: {
                                            'aria-describedby': 'buchhaltungPortalPlusEnableEigb' + eigbId + 'Help',
                                            id: 'buchhaltungPortalPlusEnableEigb' + eigbId
                                        },
                                        checked: selected,
                                        validity: 0 < $component.selectedDisabledEigbIds().length
                                    " type="checkbox">
                                    <label class="form-check-label text-nowrap" data-bind="
                                        attr: {for: 'buchhaltungPortalPlusEnableEigb' + eigbId},
                                        text: bez
                                    "></label>
                                    <span class="badge eigb-meg ms-2" data-bind="
                                        i18n: 'eigb_type_meg',
                                        visible: $component.eigBuchhaltungType.MEG === bhType
                                    "></span>
                                    <span class="badge eigb-stewe ms-2" data-bind="
                                        i18n: 'eigb_type_stewe',
                                        visible: $component.eigBuchhaltungType.STEWE === bhType
                                    "></span>
                                    <div class="form-text" data-bind="
                                        attr: {id: 'buchhaltungPortalPlusEnableEigb' + eigbId + 'Help'},
                                        i18n: {key: 'buchhaltung_portalplus_enable_eigb', options: {count: noOfLiegb, price: price}}
                                    "></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4"
                            data-bind="if: hasBuchhaltungenWithPortalPlusDisabledTypeMeg() || hasBuchhaltungenWithPortalPlusDisabledTypeStewe()">
                            <div class="card mb-3" data-bind="visible: hasBuchhaltungenWithPortalPlusDisabledTypeMeg">
                                <div class="card-body">
                                    <div>
                                        <span class="badge eigb-meg" data-bind="i18n: 'eigb_type_meg'"></span>
                                    </div>
                                    <span data-bind="i18n: 'buchhaltung_portalplus_enable_meg'"></span>
                                </div>
                            </div>
                            <div class="card" data-bind="visible: hasBuchhaltungenWithPortalPlusDisabledTypeStewe">
                                <div class="card-body">
                                    <div>
                                        <span class="badge eigb-stewe" data-bind="i18n: 'eigb_type_stewe'"></span>
                                    </div>
                                    <span data-bind="i18n: 'buchhaltung_portalplus_enable_stewe'"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-schaeppi" data-bind="busyButton: isPortalPlusProcessing" type="submit">
                        <span data-bind="i18n: {key: 'buchhaltung_portalplus_enable_submit', options: {price: priceSelectedDisabledEigb}}"></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal" data-bind="modal: isPortalPlusHistoryActive" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" id="buchhaltungPortalPlusHistoryModal">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title h4" data-bind="i18n: 'buchhaltung_portalplus_history'"></h1>
                <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
            </div>
            <div class="modal-body">
                <div class="table-responsive">
                    <table class="portal-table table table-bordered table-hover align-middle">
                        <thead>
                        <tr>
                            <th data-bind="i18n: 'buchhaltung_portalplus_begin_date'" style="width: 8rem;"></th>
                            <th data-bind="i18n: 'buchhaltung_portalplus_cancel_date'" style="width: 8rem;"></th>
                            <th data-bind="i18n: 'buchhaltung_portalplus_ende_date'" style="width: 8rem;"></th>
                            <th data-bind="i18n: 'buchhaltung_portalplus_user'" style="width: 10rem;"></th>
                        </tr>
                        </thead>
                        <tbody data-bind="foreach: activeBuchhaltungPortalPlusAbos">
                        <tr class="freigabe-row">
                            <td data-bind="text: beginDateFormatted"></td>
                            <td data-bind="text: cancelDateFormatted"></td>
                            <td data-bind="text: endeDateFormatted"></td>
                            <td data-bind="text: user"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" data-bind="modal: activeShareZip" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" id="buchhaltungShareModal">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title h4" data-bind="i18n: 'buchhaltung_zip_share'"></h1>
                <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
            </div>
            <div class="modal-body" data-bind="with: activeShareZip">
                <p data-bind="i18n: {key: 'buchhaltung_zip_share_lead', options: {expiration: expirationFormatted}}"></p>
                <div class="input-group mb-2">
                    <input class="form-control" data-bind="value: url" id="buchhaltungShareZipUrl" readonly type="text">
                    <button class="btn btn-schaeppi"
                        data-bind="click: $component.copyActiveShareZipUrl"
                        id="buchhaltungShareZipCopyUrlBtn"
                        type="button">
                        <i class="bi bi-clipboard"></i>
                        <span data-bind="i18n: 'buchhaltung_zip_share_copy'"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="fixed-top">
    <div class="container-xxl toast-container position-absolute mt-5 p-3 top-0 end-0">
        <div aria-atomic="true"
            aria-live="assertive"
            class="toast text-bg-danger hide"
            data-bind="toast: hasPendingZipGeschaefsjahr"
            id="buchhaltungPendingError"
            role="alert">
            <div class="d-flex">
                <div class="toast-body">
                    <span data-bind="i18n: 'buchhaltung_pending_error'"></span>
                </div>
                <button aria-label="Close" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" type="button"></button>
            </div>
        </div>
        <div aria-atomic="true"
            aria-live="assertive"
            class="toast text-bg-success hide"
            data-bind="toast: delayedEigbs"
            id="buchhaltungPortalPlusDelayedError"
            role="alert">
            <div class="d-flex">
                <div class="toast-body">
                    <p data-bind="i18n: 'buchhaltung_portalplus_delayed_toast'"></p>
                    <ul class="mb-0" data-bind="foreach: delayedEigbs">
                        <li data-bind="text: $data"></li>
                    </ul>
                </div>
                <button aria-label="Close" class="btn-close me-2 m-auto" data-bs-dismiss="toast" type="button"></button>
            </div>
        </div>
    </div>
</div>
