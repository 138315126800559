<!-- portal-web/modules/component/admin -->
<div class="modal" data-bind="modal: viewmodel.isAdminSettingsActive" role="dialog" tabindex="-1">
    <div class="modal-dialog" id="adminSettingsModal">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title h4" data-bind="i18n: 'admin_settings'"></h1>
                <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
            </div>
            <div class="modal-body">
                <div class="d-grid pb-3">
                    <button class="btn btn-schaeppi" data-bind="
                        busyButton: isLoading,
                        click: loadOwnGroups
                    " id="adminSettingsLoadOwnGroupsBtn" type="button">
                        <span data-bind="i18n: 'admin_load_own_groups_submit'"></span>
                    </button>
                </div>
                <div class="pb-3">
                    <h2 class="h5" data-bind="i18n: 'admin_load_user_groups'"></h2>
                    <form data-bind="submit: loadUserGroups" novalidate>
                        <div class="mb-2">
                            <label class="form-label" data-bind="i18n: 'admin_load_user_name'" for="adminSettingsUserName"></label>
                            <input aria-describedby="adminSettingsUserNameHelp"
                                class="form-control"
                                data-bind="textInput: userName"
                                id="adminSettingsUserName"
                                required
                                type="text">
                            <div class="form-text" data-bind="i18n: 'admin_load_user_name_help'" id="adminSettingsUserNameHelp"></div>
                        </div>
                        <div class="d-grid">
                            <button class="btn btn-outline-schaeppi" data-bind="busyButton: isLoading" type="submit">
                                <span data-bind="i18n: 'admin_load_user_groups_submit'"></span>
                            </button>
                        </div>
                    </form>
                </div>
                <div>
                    <h2 class="h5" data-bind="i18n: 'admin_load_eigb'"></h2>
                    <form data-bind="submit: loadEigbGroups" novalidate>
                        <div class="pb-2">
                            <div class="d-flex justify-content-between mb-2">
                                <label class="form-label text-truncate"
                                    data-bind="i18n: 'admin_eigb_query'"
                                    for="adminSettingsEigbQuery"></label>
                                <div data-bind="visible: isEigbQueryValid">
                                    <button class="btn btn-schaeppi btn-sm"
                                        data-bind="click: resetEigbQuery"
                                        id="adminSettingsEigbQueryReset"
                                        type="button">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="input-group">
                                <input class="form-control" data-bind="
                                    attr: {placeholder: eigbQueryPlaceholder},
                                    hasFocus: hasEigbQueryFocus,
                                    textInput: eigbQuery
                                " id="adminSettingsEigbQuery" type="text">
                                <button aria-expanded="false"
                                    class="btn btn-outline-secondary dropdown-toggle"
                                    data-bs-auto-close="outside"
                                    data-bs-toggle="dropdown"
                                    id="adminSettingsEigbQueryFields"
                                    type="button">
                                    <i class="bi bi-list-check"></i>
                                </button>
                                <div aria-labelledby="searchQueryFields" class="dropdown-menu dropdown-menu-end p-2">
                                    <div class="form-check mb-2">
                                        <input class="form-check-input"
                                            data-bind="checked: eigbQueryIncludeEigbLiegb"
                                            id="eigbQueryIncludeEigbLiegb"
                                            type="checkbox">
                                        <label class="form-check-label"
                                            data-bind="i18n: 'admin_eigb_query_eigbliegb'"
                                            for="eigbQueryIncludeEigbLiegb"></label>
                                    </div>
                                    <div class="form-check mb-2">
                                        <input class="form-check-input"
                                            data-bind="checked: eigbQueryIncludePers"
                                            id="eigbQueryIncludePers"
                                            type="checkbox">
                                        <label class="form-check-label"
                                            data-bind="i18n: 'admin_eigb_query_pers'"
                                            for="eigbQueryIncludePers"></label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input"
                                            data-bind="checked: eigbQueryIncludeVerw"
                                            id="eigbQueryIncludeVerw"
                                            type="checkbox">
                                        <label class="form-check-label"
                                            data-bind="i18n: 'admin_eigb_query_verw'"
                                            for="eigbQueryIncludeVerw"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="mb-0 text-muted" data-bind="i18n: 'admin_eigb_results_none', visible: hasNoEigbResults()"></p>
                        <div data-bind="if: 0 < buchhaltungen().length">
                            <ul class="d-grid gap-2 list-unstyled overflow-auto"
                                data-bind="foreach: buchhaltungen"
                                style="max-height: 21rem;">
                                <li class="position-relative">
                                    <a class="icon-link" data-bind="click: $component.selectBuchhaltung" href="#">
                                        <span data-bind="text: bez"></span>
                                        <i class="bi bi-check-square" data-bind="visible: selected"></i>
                                        <i class="bi bi-plus-lg" data-bind="visible: selected() === false"></i>
                                    </a>
                                </li>
                            </ul>
                            <div class="d-grid gap-2">
                                <button class="btn btn-outline-secondary btn-sm" data-bind="
                                    click: selectAllBuchhaltungen,
                                    i18n: 'admin_eigb_select_all'
                                " id="adminSettingsEigbSelectAll" type="button"></button>
                            </div>
                        </div>
                        <h3 class="h6 pt-3" data-bind="i18n: 'admin_eigb_selected'"></h3>
                        <p class="mb-0 text-muted"
                            data-bind="i18n: 'admin_eigb_selected_none', visible: selectedBuchhaltungen().length <= 0"></p>
                        <div data-bind="if: 0 < selectedBuchhaltungen().length">
                            <ul class="d-grid gap-2 list-unstyled overflow-auto"
                                data-bind="foreach: selectedBuchhaltungen"
                                style="max-height: 21rem;">
                                <li class="position-relative">
                                    <a class="icon-link" data-bind="click: $component.deselectBuchhaltung" href="#">
                                        <span data-bind="text: bez"></span>
                                        <i class="bi bi-trash"></i>
                                    </a>
                                </li>
                            </ul>
                            <div class="d-grid gap-2">
                                <button class="btn btn-outline-secondary btn-sm" data-bind="
                                    click: deselectAllBuchhaltungen,
                                    i18n: {key: 'admin_eigb_deselect_all', options: {count: selectedBuchhaltungen().length}}
                                " id="adminSettingsEigbDeselectAll" type="button"></button>
                            </div>
                        </div>
                        <div class="d-grid pt-2">
                            <button class="btn btn-outline-schaeppi"
                                data-bind="busyButton: isLoading, buttonDisabled: selectedBuchhaltungen().length <= 0"
                                type="submit">
                                <span data-bind="i18n: 'admin_load_eigb_submit'"></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="fixed-top">
    <div class="container-xxl toast-container position-absolute mt-5 p-3 top-0 end-0">
        <div aria-atomic="true"
            aria-live="assertive"
            class="toast hide"
            data-bind="toast: viewmodel.showAdminOwnGroupsHint"
            id="adminSettingsResetOwnGroupsHint"
            role="alert">
            <div class="toast-header bg-schaeppi-dark text-white">
                <span class="fw-bold me-auto" data-bind="i18n: 'admin_own_groups_hint'"></span>
                <button aria-label="Close" class="btn-close btn-close-white" data-bs-dismiss="toast" type="button"></button>
            </div>
            <div class="toast-body">
                <p data-bind="i18n: 'admin_own_groups_hint_help'"></p>
                <div class="d-grid">
                    <button class="btn btn-sm btn-schaeppi" data-bind="
                        busyButton: isLoading,
                        click: resetOwnGroups
                    " id="adminSettingsResetOwnGroupsBtn" type="button">
                        <span data-bind="i18n: 'admin_load_own_groups_submit'"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
