/*global */
import ko from "knockout";
import model from "portal-core/modules/model";
import _ from "underscore";
import dates from "util-web/modules/dates";
import ext from "util-web/modules/ext";
import i18next from "util-web/modules/i18next";

const CURRENCY_FORMAT = new Intl.NumberFormat("de-CH", {currency: "CHF", style: "currency"});
const EIGB_KO_MAPPING = {
    copy: [
        "ansprechpartner",
        "belegTimestamp",
        "bez",
        "bhTeam",
        "bhType",
        "buchhaltungen",
        "dokumentTimestamp",
        "eigbId",
        "geschaeftsjahre",
        "gueltigAb",
        "gueltigBis",
        "importMessage",
        "importNotification",
        "personen",
        "ppOverride",
        "ref",
        "vwStandort",
        "vwTeam"
    ]
};
const FREIGABE_KO_MAPPING = {ignore: ["entries"]};

const viewmodel = {};

viewmodel.formatCurrency = (amount) => CURRENCY_FORMAT.format(amount).substring(3).trim();
viewmodel.formatDate = (date) => dates.toDateTimeString(dates.parseDateTime(date, true), "dd.MM.yyyy");
viewmodel.formatDateTime = (date) => dates.toDateTimeString(dates.parseDateTime(date, true), "dd.MM.yyyy HH:mm");

viewmodel.ansprechpartnerVm = function (ansprechpartner) {
    const vm = Object.assign({}, ansprechpartner);
    vm.rolleAnrI18n = viewmodel.i18nBezeichnungVm(vm.rolleAnr);
    vm.rolleBezI18n = viewmodel.i18nBezeichnungVm(vm.rolleBez);
    return vm;
};

viewmodel.belegVm = function (id, beleg, resolver) {
    const vm = Object.assign({
        eigb: _.get(resolver(beleg.eigbId), "bez", ""),
        id,
        liegb: _.get(resolver(beleg.liegbId), "bez", "")
    }, beleg);
    vm.belDatumFormatted = viewmodel.formatDate(beleg.belDatum);
    vm.betragFormatted = viewmodel.formatCurrency(beleg.betrag);
    vm.kreditorFormatted = (
        _.isEmpty(beleg.kredRef)
            ? ""
            : beleg.kredRef + " - " + beleg.kreditor
    );
    return vm;
};

viewmodel.toBelegVm = function (meta, resolver) {
    const beleg = model.beleg(meta.value);
    return viewmodel.belegVm(meta.id, beleg, resolver);
};

viewmodel.i18nBezeichnungVm = function (i18nBezeichnung) {
    return ko.pureComputed(function () {
        const locale = i18next.currentLocale();
        return i18nBezeichnung[locale];
    });
};

viewmodel.buchungVm = function (id, buchung, bewegung, color, resolver) {
    const vm = Object.assign({
        eigb: _.get(resolver(bewegung.eigbId), "bez", ""),
        id,
        liegb: _.get(resolver(bewegung.liegbId), "bez", "")
    }, buchung, bewegung);
    vm.buchDatumFormatted = viewmodel.formatDate(buchung.buchDatum);
    vm.color = color;
    vm.habenFormatted = (
        bewegung.haben
            ? viewmodel.formatCurrency(bewegung.haben)
            : ""
    );
    vm.kg1Bez = viewmodel.i18nBezeichnungVm(vm.kg1Bez);
    vm.kg2Bez = viewmodel.i18nBezeichnungVm(vm.kg2Bez);
    vm.kontoBez = viewmodel.i18nBezeichnungVm(vm.kontoBez);
    vm.kreditorFormatted = (
        _.isEmpty(buchung.kredRef)
            ? ""
            : buchung.kredRef + " - " + buchung.kreditor
    );
    vm.sollFormatted = (
        bewegung.soll
            ? viewmodel.formatCurrency(bewegung.soll)
            : ""
    );
    return vm;
};

viewmodel.toBuchungVm = function (colorProvider, meta, resolver) {
    const buchung = model.buchung(meta.value);
    const color = colorProvider();
    return buchung.bewegungen.map((bewegung) => viewmodel.buchungVm(meta.id, buchung, model.bewegung(bewegung), color, resolver));
};

viewmodel.dokumentVm = function (id, dokument, resolver) {
    const vm = Object.assign({
        eigb: _.get(resolver(dokument.eigbId), "bez", ""),
        id,
        liegb: _.get(resolver(dokument.liegbId), "bez", "")
    }, dokument);
    vm.dokDatumFormatted = viewmodel.formatDate(dokument.dokDatum);
    vm.read = ko.observable(vm.read);
    vm.isSelectVisible = ko.observable(false);
    vm.selected = ko.observable(false);
    return vm;
};

viewmodel.toDokumentVm = function (meta, resolver) {
    const dokument = model.dokument(meta.value);
    return viewmodel.dokumentVm(meta.id, dokument, resolver);
};

viewmodel.eigBuchhaltungVm = function (id, eigBuchhaltung) {
    const vm = ko.mapping.fromJS(eigBuchhaltung, EIGB_KO_MAPPING);
    vm.id = id;
    vm.ansprechpartner = eigBuchhaltung.ansprechpartner.map(viewmodel.ansprechpartnerVm);
    vm.belegTimestamp = (
        eigBuchhaltung.belegTimestamp
            ? dates.parseDateTime(eigBuchhaltung.belegTimestamp)
            : undefined
    );
    vm.belegTimestampFormatted = (
        eigBuchhaltung.belegTimestamp
            ? viewmodel.formatDate(eigBuchhaltung.belegTimestamp)
            : ""
    );
    vm.buchhaltungen = eigBuchhaltung.buchhaltungen.sort(ext.simpleSort.bind(undefined, "bez")).map(function (liegb) {
        return viewmodel.liegBuchhaltungVm(liegb, eigBuchhaltung.eigbId);
    });
    vm.dokumentTimestamp = (
        eigBuchhaltung.dokumentTimestamp
            ? dates.parseDateTime(eigBuchhaltung.dokumentTimestamp)
            : undefined
    );
    vm.dokumentTimestampFormatted = (
        eigBuchhaltung.dokumentTimestamp
            ? viewmodel.formatDate(eigBuchhaltung.dokumentTimestamp)
            : ""
    );
    vm.geschaeftsjahre = eigBuchhaltung.geschaeftsjahre.map(function (geschaeftsjahr) {
        const geschaeftsjahrVm = Object.assign(geschaeftsjahr, {
            geschaeftsjahrAbgeschlossenBis: geschaeftsjahr.geschaeftsjahrAbgeschlossenBis
                ? dates.parseDateTime(geschaeftsjahr.geschaeftsjahrAbgeschlossenBis)
                : undefined,
            geschaeftsjahrBegin: dates.parseDateTime(geschaeftsjahr.geschaeftsjahrBegin),
            geschaeftsjahrEnde: dates.parseDateTime(geschaeftsjahr.geschaeftsjahrEnde)
        });
        geschaeftsjahrVm.abgeschlossen = geschaeftsjahrVm.geschaeftsjahrAbgeschlossenBis && geschaeftsjahrVm.geschaeftsjahrEnde.equals(geschaeftsjahrVm.geschaeftsjahrAbgeschlossenBis);
        geschaeftsjahrVm.text = i18next.pureComputedT("geschaeftsjahr_extended", {
            begin: viewmodel.formatDate(geschaeftsjahrVm.geschaeftsjahrBegin),
            context: (
                geschaeftsjahrVm.abgeschlossen
                    ? "abgeschlossen"
                    : ""
            ),
            end: viewmodel.formatDate(geschaeftsjahrVm.geschaeftsjahrEnde),
            geschaeftsjahr: geschaeftsjahrVm.geschaeftsjahr
        });
        return geschaeftsjahrVm;
    });
    vm.gueltigBis = (
        eigBuchhaltung.gueltigBis
            ? dates.parseDateTime(eigBuchhaltung.gueltigBis)
            : undefined
    );
    vm.personen = eigBuchhaltung.personen.sort(ext.simpleSort.bind(undefined, "persName")).map(viewmodel.persVm);
    vm.ppState = ko.observable(viewmodel.eigBuchhaltungVm.ppState.DISABLED);
    vm.isAdmin = ko.observable(false);
    vm.isGueltig = (Boolean(vm.gueltigBis) === false || dates.now() <= vm.gueltigBis) && 0 < vm.buchhaltungen.filter((liegb) => liegb.isGueltig).length;
    vm.selected = ko.observable(false);
    return vm;
};

viewmodel.eigBuchhaltungVm.ppState = Object.freeze({
    CANCELED: "CANCELED",
    DELAYED: "DELAYED",
    DISABLED: "DISABLED",
    ENABLED: "ENABLED"
});

viewmodel.toEigBuchhaltungVm = function (meta) {
    const eigb = model.eigBuchhaltung(meta.value);
    return viewmodel.eigBuchhaltungVm(meta.id, eigb);
};

viewmodel.freigabeEntryVm = function (entry, buchhaltungResolver, persResolver) {
    const eigb = buchhaltungResolver(entry.eigbId);
    if (eigb) {
        return Object.assign({
            eigbBez: eigb.bez,
            eigbRef: eigb.ref,
            pers: persResolver(entry.eigbId, entry.persId)
        }, entry);
    }
};

viewmodel.freigabeVm = function (id, freigabe, buchhaltungResolver, persResolver) {
    const vm = ko.mapping.fromJS(freigabe, FREIGABE_KO_MAPPING);
    vm.id = id;
    vm.entries = freigabe.entries.map((entry) => viewmodel.freigabeEntryVm(entry, buchhaltungResolver, persResolver)).filter(Boolean);
    vm.timestampFormatted = viewmodel.formatDate(freigabe.timestamp);
    vm.isRevoking = ko.observable(false);
    return vm;
};

viewmodel.toFreigabeVm = function (meta, buchhaltungResolver, persResolver) {
    const freigabe = model.freigabe(meta.value);
    return viewmodel.freigabeVm(meta.id, freigabe, buchhaltungResolver, persResolver);
};

viewmodel.liegBuchhaltungVm = function (liegb, eigbId) {
    const vm = Object.assign({eigbId}, liegb);
    vm.ansprechpartner = vm.ansprechpartner.map(viewmodel.ansprechpartnerVm);
    vm.gueltigAbFormatted = viewmodel.formatDate(liegb.gueltigAb);
    vm.gueltigBis = (
        liegb.gueltigBis
            ? dates.parseDateTime(liegb.gueltigBis)
            : undefined
    );
    vm.gueltigBisFormatted = viewmodel.formatDate(liegb.gueltigBis);
    vm.isGueltig = (Boolean(vm.gueltigBis) === false || dates.now() <= vm.gueltigBis);
    vm.selected = ko.observable(false);
    return vm;
};

viewmodel.newsVm = function (news, read = true) {
    return Object.assign(news, {
        active: ko.observable(false),
        date: viewmodel.formatDate(news.date),
        read: ko.observable(read)
    });
};

viewmodel.persVm = function (pers) {
    const vm = Object.assign({}, pers);
    vm.gueltigAbFormatted = viewmodel.formatDate(pers.gueltigAb);
    vm.gueltigBisFormatted = viewmodel.formatDate(pers.gueltigBis);
    vm.selected = ko.observable(false);
    return vm;
};

viewmodel.portalPlusAboVm = function (id, ppAbo) {
    const vm = ko.mapping.fromJS(ppAbo);
    vm.id = id;
    vm.beginDateFormatted = viewmodel.formatDate(vm.beginDate());
    vm.cancelDateFormatted = ko.pureComputed(function () {
        const cancelDate = vm.cancelDate();
        if (cancelDate) {
            return viewmodel.formatDate(cancelDate);
        }
        return "-";
    });
    vm.endeDateFormatted = ko.pureComputed(function () {
        const endeDate = vm.endeDate();
        if (endeDate) {
            return viewmodel.formatDate(endeDate);
        }
        return "-";
    });
    return vm;
};

viewmodel.toPortalPlusAboVm = function (meta) {
    const ppAbo = model.portalPlusAbo(meta.value);
    return viewmodel.portalPlusAboVm(meta.id, ppAbo);
};

viewmodel.zipGeschaeftsjahrVm = function (id, zipGeschaeftsjahr, resolver) {
    const vm = ko.mapping.fromJS(zipGeschaeftsjahr);
    vm.id = id;
    vm.expirationFormatted = ko.pureComputed(function () {
        const expiration = vm.expiration();
        if (expiration) {
            return viewmodel.formatDateTime(expiration);
        }
        return "-";
    });
    vm.isOutdated = ko.observable(false);
    vm.persName = _.get(resolver(vm.eigbId(), vm.persId()), "persName", "");
    vm.sizeFormatted = ko.pureComputed(function () {
        const size = vm.size();
        if (0 < size) {
            return ext.formatBytes(size);
        }
    });
    vm.timestamp(dates.parseDateTime(vm.timestamp()));
    vm.timestampFormatted = viewmodel.formatDateTime(zipGeschaeftsjahr.timestamp);
    return vm;
};

viewmodel.toZipGeschaeftsjahrVm = function (meta, resolver) {
    const zipGeschaeftsjahr = model.zipGeschaeftsjahr(meta.value);
    return viewmodel.zipGeschaeftsjahrVm(meta.id, zipGeschaeftsjahr, resolver);
};

export default Object.freeze(viewmodel);
