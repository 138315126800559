<!-- portal-web/modules/component/beleg -->
<div id="beleg">
    <h1 class="display-6" data-bind="i18n: 'beleg'"></h1>
    <p class="lead mb-3" data-bind="i18n: 'beleg_intro'"></p>
    <div class="row">
        <div data-bind="visible: showFullscreen() === false, css: {'col-7': activeBeleg(), 'col': Boolean(activeBeleg()) === false}">
            <form class="mb-3 pe-1" data-bind="submit: search" id="belegSearch" novalidate>
                <div class="row g-3 mb-3">
                    <div class="col-12 col-lg-6">
                        <div class="d-flex justify-content-between mb-2">
                            <label class="form-label text-truncate" data-bind="i18n: 'beleg_search_query'" for="belegSearchQuery"></label>
                            <div data-bind="visible: isSearchQueryValid">
                                <button class="btn btn-schaeppi btn-sm"
                                    data-bind="click: resetSearchQuery"
                                    id="belegSearchQueryReset"
                                    type="button">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                        </div>
                        <div class="input-group">
                            <input class="form-control" data-bind="
                                attr: {placeholder: searchQueryPlaceholder},
                                hasFocus: hasSearchQueryFocus,
                                textInput: searchQuery
                            " id="belegSearchQuery" type="text">
                            <button aria-expanded="false"
                                class="btn btn-outline-secondary dropdown-toggle"
                                data-bs-auto-close="outside"
                                data-bs-toggle="dropdown"
                                id="searchQueryFields"
                                type="button">
                                <i class="bi bi-list-check"></i>
                            </button>
                            <div aria-labelledby="searchQueryFields" class="dropdown-menu dropdown-menu-end p-2">
                                <div class="form-check mb-2">
                                    <input class="form-check-input"
                                        data-bind="checked: searchIncludeBelegnr"
                                        id="searchIncludeBelegnr"
                                        type="checkbox">
                                    <label class="form-check-label" data-bind="i18n: 'belegnr'" for="searchIncludeBelegnr"></label>
                                </div>
                                <div class="form-check mb-2">
                                    <input class="form-check-input"
                                        data-bind="checked: searchIncludeBewegungstext"
                                        id="searchIncludeBewegungstext"
                                        type="checkbox">
                                    <label class="form-check-label"
                                        data-bind="i18n: 'bewegungstext'"
                                        for="searchIncludeBewegungstext"></label>
                                </div>
                                <div class="form-check mb-2">
                                    <input class="form-check-input"
                                        data-bind="checked: searchIncludeBuchId"
                                        id="searchIncludeBuchId"
                                        type="checkbox">
                                    <label class="form-check-label" data-bind="i18n: 'buchId'" for="searchIncludeBuchId"></label>
                                </div>
                                <div class="form-check mb-2">
                                    <input class="form-check-input"
                                        data-bind="checked: searchIncludeKonto"
                                        id="searchIncludeKonto"
                                        type="checkbox">
                                    <label class="form-check-label" data-bind="i18n: 'konto'" for="searchIncludeKonto"></label>
                                </div>
                                <div class="form-check mb-2">
                                    <input class="form-check-input"
                                        data-bind="checked: searchIncludeKg2"
                                        id="searchIncludeKg2"
                                        type="checkbox">
                                    <label class="form-check-label" data-bind="i18n: 'kg2'" for="searchIncludeKg2"></label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input"
                                        data-bind="checked: searchIncludeKreditor"
                                        id="searchIncludeKreditor"
                                        type="checkbox">
                                    <label class="form-check-label" data-bind="i18n: 'kreditor'" for="searchIncludeKreditor"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-3">
                        <div class="d-flex justify-content-between mb-2">
                            <label class="form-label text-truncate" for="belegFilterEigb">
                                <i class="bi bi-journals"></i>
                                <span data-bind="i18n: 'filter_eigb'"></span>
                            </label>
                            <div data-bind="visible: 0 < viewmodel.filterEigbSelectedIds().length">
                                <button class="btn btn-schaeppi btn-sm"
                                    data-bind="click: viewmodel.resetEigbFilter"
                                    id="belegFilterEigbReset"
                                    type="button">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                        </div>
                        <div class="dropdown d-grid">
                            <button aria-expanded="false"
                                class="btn btn-secondary dropdown-toggle"
                                data-bind="click: focusEigbFilter"
                                data-bs-auto-close="outside"
                                data-bs-toggle="dropdown"
                                id="belegFilterEigb"
                                type="button">
                                <span data-bind="i18n: 'filter_selection_none', visible: viewmodel.filterEigbSelectedIds().length <= 0"></span>
                                <span data-bind="visible: 0 < viewmodel.filterEigbSelectedIds().length">
                                    <span class="badge rounded-pill bg-schaeppi"
                                        data-bind="text: viewmodel.filterEigbSelectedIds().length"></span>
                                    <span data-bind="i18n: 'filter_selection'"></span>
                                </span>
                            </button>
                            <div aria-labelledby="belegFilterEigb" class="dropdown-menu dropdown-menu-end p-3" style="min-width: 20rem;">
                                <a data-bind="click: viewmodel.selectAllEigbVisible, i18n: 'filter_select_all'"
                                    href="#"
                                    id="belegFilterEigbSelectAll"></a>
                                <div class="input-group mt-3">
                                    <input autocomplete="off" class="form-control" data-bind="
                                        attr: {placeholder: i18next.pureComputedT('filter_search')},
                                        hasFocus: hasEigbFilterFocus,
                                        textInput: viewmodel.filterEigbQuery
                                    " id="belegFilterEigbSearch" type="text">
                                    <button class="btn btn-outline-secondary"
                                        data-bind="click: viewmodel.resetEigbQuery"
                                        id="belegFilterEigbSearchReset"
                                        type="button">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                </div>
                                <div class="overflow-y-auto" data-bind="foreach: viewmodel.filterEigbVisible" style="max-height: 21rem;">
                                    <div class="form-check mb-0 mt-2">
                                        <input class="form-check-input" data-bind="
                                            attr: {id: 'belegFilterEigb' + eigbId},
                                            checked: selected
                                        " type="checkbox">
                                        <label class="form-check-label text-nowrap"
                                            data-bind="text: bez, attr: {for: 'belegFilterEigb' + eigbId}"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-3">
                        <div class="d-flex justify-content-between mb-2">
                            <label class="form-label text-truncate" for="belegFilterLiegb">
                                <i class="bi bi-building"></i>
                                <span data-bind="i18n: 'filter_liegb'"></span>
                            </label>
                            <div data-bind="visible: 0 < viewmodel.filterLiegbIds().length">
                                <button class="btn btn-schaeppi btn-sm"
                                    data-bind="click: viewmodel.resetLiegbFilter"
                                    id="belegFilterLiegbReset"
                                    type="button">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                        </div>
                        <div class="dropdown d-grid">
                            <button aria-expanded="false"
                                class="btn btn-secondary dropdown-toggle"
                                data-bind="click: focusLiegbFilter"
                                data-bs-auto-close="outside"
                                data-bs-toggle="dropdown"
                                id="belegFilterLiegb"
                                type="button">
                                <span data-bind="i18n: 'filter_selection_none', visible: viewmodel.filterLiegbIds().length <= 0"></span>
                                <span data-bind="visible: 0 < viewmodel.filterLiegbIds().length">
                                    <span class="badge rounded-pill bg-schaeppi" data-bind="text: viewmodel.filterLiegbIds().length"></span>
                                    <span data-bind="i18n: 'filter_selection'"></span>
                                </span>
                            </button>
                            <div aria-labelledby="belegFilterLiegb" class="dropdown-menu dropdown-menu-end p-3" style="min-width: 20rem;">
                                <a data-bind="click: viewmodel.selectAllLiegbVisible, i18n: 'filter_select_all'"
                                    href="#"
                                    id="belegFilterLiegbSelectAll"></a>
                                <div class="input-group mt-3">
                                    <input autocomplete="off" class="form-control" data-bind="
                                        attr: {placeholder: i18next.pureComputedT('filter_search')},
                                        hasFocus: hasLiegbFilterFocus,
                                        textInput: viewmodel.filterLiegbQuery
                                    " id="belegFilterLiegbSearch" type="text">
                                    <button class="btn btn-outline-secondary"
                                        data-bind="click: viewmodel.resetLiegbQuery"
                                        id="belegFilterLiegbSearchReset"
                                        type="button">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                </div>
                                <div class="overflow-y-auto" data-bind="foreach: viewmodel.filterLiegbVisible" style="max-height: 21rem;">
                                    <div class="form-check mb-0 mt-2">
                                        <input class="form-check-input" data-bind="
                                            attr: {id: 'belegFilterLiegb' + liegbId},
                                            checked: selected
                                        " type="checkbox">
                                        <label class="form-check-label text-nowrap"
                                            data-bind="text: bez, attr: {for: 'belegFilterLiegb' + liegbId}"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-2 mb-3 mb-lg-0">
                        <div class="d-flex justify-content-between mb-2">
                            <label class="form-label text-truncate"
                                data-bind="i18n: 'filter_geschaeftsjahr'"
                                for="belegFilterGeschaeftsjahr"></label>
                            <div data-bind="visible: validatedFilterGeschaeftsjahr()">
                                <button class="btn btn-schaeppi btn-sm"
                                    data-bind="click: filterGeschaeftsjahrClear"
                                    id="belegFilterGeschaeftsjahrReset"
                                    type="button">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                        </div>
                        <select class="form-select" data-bind="
                            options: viewmodel.geschaeftsjahre,
                            optionsCaption: '',
                            value: filterGeschaeftsjahr
                        " id="belegFilterGeschaeftsjahr"></select>
                    </div>
                    <fieldset class="col-lg-4 mb-3 mb-lg-0" id="belegFilterDate">
                        <div class="row">
                            <div class="col-6">
                                <div class="d-flex justify-content-between mb-2">
                                    <label class="form-label text-truncate"
                                        data-bind="i18n: 'beleg_filter_date_from'"
                                        for="belegFilterDateFrom"></label>
                                    <div data-bind="visible: validatedFilterDateFrom()">
                                        <button class="btn btn-schaeppi btn-sm" data-bind="click: filterDateFromClear" type="button">
                                            <i class="bi bi-trash"></i>
                                        </button>
                                    </div>
                                </div>
                                <input class="form-control" data-bind="textInput: filterDateFrom" id="belegFilterDateFrom" type="date">
                            </div>
                            <div class="col-6">
                                <div class="d-flex justify-content-between mb-2">
                                    <label class="form-label text-truncate"
                                        data-bind="i18n: 'beleg_filter_date_to'"
                                        for="belegFilterDateTo"></label>
                                    <div data-bind="visible: validatedFilterDateTo()">
                                        <button class="btn btn-schaeppi btn-sm" data-bind="click: filterDateToClear" type="button">
                                            <i class="bi bi-trash"></i>
                                        </button>
                                    </div>
                                </div>
                                <input class="form-control" data-bind="textInput: filterDateTo" id="belegFilterDateTo" type="date">
                            </div>
                        </div>
                    </fieldset>
                    <fieldset class="col-lg-4" id="belegFilterBetrag">
                        <div class="row">
                            <div class="col-6">
                                <div class="d-flex justify-content-between mb-2">
                                    <label class="form-label text-truncate"
                                        data-bind="i18n: 'beleg_filter_betrag_from'"
                                        for="belegFilterBetragFrom"></label>
                                    <div data-bind="visible: validatedFilterBetragFrom()">
                                        <button class="btn btn-schaeppi btn-sm" data-bind="click: filterBetragFromClear" type="button">
                                            <i class="bi bi-trash"></i>
                                        </button>
                                    </div>
                                </div>
                                <input class="form-control"
                                    data-bind="textInput: filterBetragFrom"
                                    id="belegFilterBetragFrom"
                                    step="0.01"
                                    type="number">
                            </div>
                            <div class="col-6">
                                <div class="d-flex justify-content-between mb-2">
                                    <label class="form-label text-truncate"
                                        data-bind="i18n: 'beleg_filter_betrag_to'"
                                        for="belegFilterBetragTo"></label>
                                    <div data-bind="visible: validatedFilterBetragTo()">
                                        <button class="btn btn-schaeppi btn-sm" data-bind="click: filterBetragToClear" type="button">
                                            <i class="bi bi-trash"></i>
                                        </button>
                                    </div>
                                </div>
                                <input class="form-control"
                                    data-bind="textInput: filterBetragTo"
                                    id="belegFilterBetragTo"
                                    step="0.01"
                                    type="number">
                            </div>
                        </div>
                    </fieldset>
                </div>
                <button class="btn btn-schaeppi" data-bind="busyButton: isSearching" type="submit">
                    <i class="bi bi-search"></i>
                    <span data-bind="i18n: 'beleg_load'"></span>
                </button>
            </form>
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link" data-bind="
                        click: navigateToTab.bind(undefined, viewmodel.BELEG_TABS.BELEGE, null),
                        css: {active: viewmodel.BELEG_TABS.BELEGE === activeTab()}
                    " href="#belegeTab">
                        <span data-bind="i18n: 'beleg_belege'"></span>
                        <span class="badge rounded-pill bg-schaeppi" data-bind="
                            text: belegObjects.noOfSearchResults,
                            visible: 0 < belegObjects.noOfSearchResults()
                        "></span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bind="
                        click: navigateToTab.bind(undefined, viewmodel.BELEG_TABS.BUCHUNGEN, null),
                        css: {active: viewmodel.BELEG_TABS.BUCHUNGEN === activeTab()}
                    " href="#buchungenTab">
                        <span data-bind="i18n: 'beleg_buchungen'"></span>
                        <span class="badge rounded-pill bg-schaeppi" data-bind="
                            text: buchungObjects.noOfSearchResults,
                            visible: 0 < buchungObjects.noOfSearchResults()
                        "></span>
                    </a>
                </li>
            </ul>
            <div class="tab-content mb-3 overflow-auto" data-bind="fixHeight: hasSearchQueryFocus, fixHeightMargin: 20">
                <div class="tab-pane" data-bind="css: {active: viewmodel.BELEG_TABS.BELEGE === activeTab()}" id="belegeTab" role="tabpanel">
                    <div class="d-flex align-items-center justify-content-between mb-2 mt-2"
                        data-bind="if: 0 < belegObjects.noOfSearchResults()"
                        style="min-height: 2.5rem;">
                        <searcher params="
                            i18nPrefix: 'beleg',
                            loadMore: loadMore,
                            objects: belegObjects
                        "></searcher>
                        <div>
                            <button class="btn btn-light btn-sm" data-bind="
                                click: downloadCsv,
                                tooltip: 'csv_tooltip'
                            " id="belegeDownloadCsv" type="button">
                                <i class="bi bi-filetype-csv"></i>
                                <span data-bind="i18n: 'csv'"></span>
                            </button>
                            <button class="btn btn-light btn-sm" data-bind="
                                attr: {disabled: viewmodel.isZippingFiles()},
                                click: downloadZip,
                                tooltip: 'beleg_zip_files_tooltip'
                            " id="belegeDownloadZip" type="button">
                                <i class="bi bi-file-earmark-arrow-down"></i>
                                <span data-bind="i18n: 'zip_files'"></span>
                            </button>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="portal-table table table-bordered table-hover table-sm align-middle">
                            <thead>
                            <tr>
                                <th class="text-end" data-bind="i18n: 'belegnr'" style="width: 7rem;"></th>
                                <th data-bind="i18n: 'belegdatum'" style="width: 7rem;"></th>
                                <th data-bind="i18n: 'eigb'" style="width: 11rem;"></th>
                                <th data-bind="i18n: 'liegb'" style="width: 17rem;"></th>
                                <th class="text-truncate" data-bind="i18n: 'kreditor'"></th>
                                <th class="text-end" data-bind="i18n: 'betrag'" style="width: 8rem;"></th>
                                <th style="width: 13rem;"></th>
                            </tr>
                            </thead>
                            <tbody data-bind="foreach: belegObjects.objects">
                            <tr class="beleg-row" data-bind="css: {'table-active': $component.activeBuchId() === buchId}">
                                <th class="text-end" data-bind="text: belNr"></th>
                                <td data-bind="text: belDatumFormatted"></td>
                                <td class="text-truncate" data-bind="text: eigb, attr: {title: eigb}"></td>
                                <td class="text-truncate" data-bind="text: liegb, attr: {title: liegb}"></td>
                                <td class="text-truncate" data-bind="attr: {title: kreditorFormatted}">
                                    <span data-bind="text: kreditorFormatted"></span>
                                </td>
                                <td class="text-end" data-bind="text: betragFormatted"></td>
                                <td class="text-end pe-2">
                                    <button class="btn btn-schaeppi btn-sm" data-bind="
                                            attr: {id: 'belegDetailBtn' + belNr},
                                            click: $component.showBeleg
                                        " type="button">
                                        <i class="bi bi-filetype-pdf"></i>
                                        <span data-bind="i18n: 'beleg_details_btn'"></span>
                                    </button>
                                    <button class="btn btn-outline-secondary btn-sm" data-bind="
                                            attr: {id: 'belegBuchungSearchBtn' + buchId},
                                            click: $component.searchBelegBuchung
                                        " type="button">
                                        <i class="bi bi-search"></i>
                                        <span data-bind="i18n: 'beleg_buchungen'"></span>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody data-bind="visible: betragTotal">
                            <tr>
                                <td colspan="5"></td>
                                <th class="text-end" data-bind="text: betragTotal"></th>
                                <th></th>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-flex align-items-center" data-bind="if: belegObjects.canSearchMore" style="min-height: 2.5rem;">
                        <searcher params="
                            i18nPrefix: 'beleg',
                            loadMore: loadMore,
                            objects: belegObjects
                        "></searcher>
                    </div>
                </div>
                <div class="tab-pane"
                    data-bind="css: {active: viewmodel.BELEG_TABS.BUCHUNGEN === activeTab()}"
                    id="buchungenTab"
                    role="tabpanel">
                    <div class="d-flex align-items-center justify-content-between mb-2 mt-2"
                        data-bind="if: 0 < buchungObjects.noOfSearchResults()"
                        style="min-height: 2.5rem;">
                        <searcher params="
                            i18nPrefix: 'beleg',
                            loadMore: loadMore,
                            objects: buchungObjects
                        "></searcher>
                        <div>
                            <button class="btn btn-light btn-sm" data-bind="
                            click: downloadCsv,
                            tooltip: 'csv_tooltip'
                        " id="buchungenDownloadCsv" type="button">
                                <i class="bi bi-file-earmark-arrow-down"></i>
                                <span data-bind="i18n: 'csv'"></span>
                            </button>
                            <button class="btn btn-light btn-sm" data-bind="
                                attr: {disabled: viewmodel.isZippingFiles()},
                                click: downloadZip,
                                tooltip: 'beleg_zip_files_tooltip'
                            " id="buchungenDownloadZip" type="button">
                                <i class="bi bi-file-earmark-arrow-down"></i>
                                <span data-bind="i18n: 'zip_files'"></span>
                            </button>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="portal-table table table-bordered table-hover table-sm align-middle">
                            <thead>
                            <tr>
                                <th class="text-end" data-bind="i18n: 'buchId'" style="width: 7rem;"></th>
                                <th data-bind="i18n: 'buchungsdatum'" style="width: 7rem;"></th>
                                <th data-bind="i18n: 'eigb'" style="width: 11rem;"></th>
                                <th data-bind="i18n: 'liegb'" style="width: 17rem;"></th>
                                <th class="text-truncate" data-bind="i18n: 'kreditor'"></th>
                                <th data-bind="i18n: 'kg2'" style="width: 13rem;"></th>
                                <th data-bind="i18n: 'konto'" style="width: 13rem;"></th>
                                <th class="text-truncate" data-bind="i18n: 'bewegungstext'"></th>
                                <th class="text-end" data-bind="i18n: 'soll'" style="width: 8rem;"></th>
                                <th class="text-end" data-bind="i18n: 'haben'" style="width: 8rem;"></th>
                                <th style="width: 13rem;"></th>
                            </tr>
                            </thead>
                            <tbody data-bind="foreach: buchungObjects.objects">
                            <tr class="buchung-row" data-bind="css: {'table-active': $component.activeBuchId() === buchId}">
                                <th class="text-end" data-bind="style: {color: color}, text: buchId"></th>
                                <td data-bind="text: buchDatumFormatted"></td>
                                <td class="text-truncate" data-bind="text: eigb, attr: {title: eigb}"></td>
                                <td class="text-truncate" data-bind="text: liegb, attr: {title: liegb}"></td>
                                <td class="text-truncate" data-bind="attr: {title: kreditorFormatted}">
                                    <span data-bind="text: kreditorFormatted"></span>
                                </td>
                                <td class="text-truncate" data-bind="attr: {title: kg2 + ' - ' + kg2Bez()}">
                                    <span data-bind="text: kg2"></span> - <span data-bind="text: kg2Bez"></span>
                                </td>
                                <td class="text-truncate" data-bind="attr: {title: kontoNr + ' - ' + kontoBez()}">
                                    <span data-bind="text: kontoNr"></span> - <span data-bind="text: kontoBez"></span>
                                </td>
                                <td class="text-truncate" data-bind="text: text, attr: {title: text}"></td>
                                <td class="text-end" data-bind="text: sollFormatted"></td>
                                <td class="text-end" data-bind="text: habenFormatted"></td>
                                <td class="text-end pe-2">
                                    <button class="btn btn-schaeppi btn-sm" data-bind="
                                            attr: {id: 'belegBuchungDetailBtn' + belNr},
                                            click: $component.showBeleg,
                                            visible: fileKey
                                        " type="button">
                                        <i class="bi bi-filetype-pdf"></i>
                                        <span data-bind="i18n: 'beleg_details_btn'"></span>
                                    </button>
                                    <button class="btn btn-outline-secondary btn-sm" data-bind="
                                            attr: {id: 'belegBuchungBelegSearchBtn' + buchId},
                                            click: $component.searchBuchungBeleg,
                                            visible: 0 < belNr
                                        " type="button">
                                        <i class="bi bi-search"></i>
                                        <span data-bind="i18n: 'beleg_belege'"></span>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody data-bind="visible: sollTotal">
                            <tr>
                                <td colspan="8"></td>
                                <th class="text-end" data-bind="text: sollTotal"></th>
                                <th class="text-end" data-bind="text: habenTotal"></th>
                                <th></th>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-flex align-items-center" data-bind="if: buchungObjects.canSearchMore" style="min-height: 2.5rem;">
                        <searcher params="
                            i18nPrefix: 'beleg',
                            loadMore: loadMore,
                            objects: buchungObjects
                        "></searcher>
                    </div>
                </div>
            </div>
        </div>
        <div data-bind="visible: activeBeleg(), css: {'col-5': showFullscreen() === false, 'col': showFullscreen()}">
            <div class="d-flex justify-content-between mb-2" data-bind="if: activeBeleg" id="belegDetail">
                <h2 class="h4 mb-0" data-bind="i18n: {key: 'beleg_details', options: {nr: activeBeleg().belNr}}"></h2>
                <button class="btn-close"
                    data-bind="click: closeBeleg, attr: {title: closeTitle}"
                    id="belegDetailClose"
                    type="button"></button>
            </div>
            <div class="d-flex flex-wrap gap-2 pb-2">
                <button class="btn btn-schaeppi" data-bind="
                    busyButton: isLoadingBeleg,
                    click: downloadBeleg,
                    visible: hasBelegPdf
                " id="belegDownloadPdf" type="button">
                    <i class="bi bi-file-earmark-arrow-down"></i>
                    <span data-bind="i18n: 'download'"></span>
                </button>
                <button class="btn btn-schaeppi" data-bind="click: activateAnsprechpartner" id="belegAnsprechpartner" type="button">
                    <i class="bi bi-person-rolodex"></i>
                    <span data-bind="i18n: 'ansprechpartner'"></span>
                </button>
                <button class="btn btn-outline-secondary" data-bind="click: toggleFullscreen" id="belegDetailFullscreen" type="button">
                    <i class="bi bi-fullscreen"></i>
                    <span data-bind="i18n: 'fullscreen_toggle'"></span>
                </button>
                <nav class="overflow-auto" data-bind="attr: {'aria-label': pageNavLabel}">
                    <ul class="pagination mb-0" data-bind="foreach: pdfPages">
                        <li class="page-item">
                            <a class="page-link" data-bind="
                                attr: {href: '#' + pageId},
                                click: $component.scrollToPage,
                                text: pageNo
                            "></a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="overflow-auto" data-bind="fixHeight: isLoadingBeleg, fixHeightMargin: 20" id="belegPdf"></div>
        </div>
    </div>
</div>
