/*global confirm, define, window */
import Logger from "js-logger";
import ko from "knockout";
import objects from "meta-client/modules/objects";
import metaProtocol from "meta-core/modules/protocol";
import model from "portal-core/modules/model";
import coreViewmodel from "portal-core/modules/viewmodel";
import _ from "underscore";
import i18next from "util-web/modules/i18next";
import nav from "util-web/modules/nav";
import ui from "util-web/modules/ui";
import template from "./freigabe.html";

const LOG = Logger.get("portal-web/component/freigabe");
const TRUSTER_OPTIONS = Object.freeze({
    NON_ADMIN: "NON_ADMIN",
    OWN: "OWN",
    ALL: "ALL"
});

export default Object.freeze({
    template,
    viewModel: {
        createViewModel: function ({viewmodel}) {
            const vm = {
                freigabeState: model.freigabe.state,
                i18next,
                user: viewmodel.client.user,
                viewmodel
            };

            viewmodel.onEigbLoad(() => vm.freigabeObjects.clear());

            vm.freigabeObjects = objects({
                loadById: objects.loadByMetaId.bind(undefined, vm.viewmodel.client),
                toVm: (meta) => coreViewmodel.toFreigabeVm(meta, vm.viewmodel.resolveBuchhaltung, vm.viewmodel.resolvePerson),
                typeId: model.FREIGABE_TYPE_ID,
                unshift: true
            });
            vm.freigabeSub = vm.viewmodel.client.registerOnEvent(vm.freigabeObjects);
            vm.freigabeNonAdminObjects = ko.pureComputed(function () {
                return vm.freigabeObjects.objects().filter((freigabeVm) => freigabeVm.admin() === false);
            });
            vm.freigabeAdminObjects = ko.pureComputed(function () {
                return vm.freigabeObjects.objects().filter((freigabeVm) => freigabeVm.admin());
            });

            vm.freigabeEigb = ko.pureComputed(function () {
                return vm.viewmodel.eigBuchhaltungen().filter((eigb) => eigb.isAdmin()).map(function (eigb) {
                    return {
                        bez: eigb.bez,
                        eigbId: eigb.eigbId,
                        selected: ko.observable(false)
                    };
                });
            });

            vm.selectedEigb = ko.pureComputed(function () {
                return vm.freigabeEigb().filter((eigb) => eigb.selected());
            });

            vm.freigabeDescription = ko.observable();
            vm.freigabeEmail = ko.observable();
            vm.freigabeLocale = ko.observable();
            vm.isCreatingFreigabe = ko.observable(false);

            vm.createFreigabe = function (form) {
                if (ui.validateForm(form) === false) {
                    return;
                }
                vm.isCreatingFreigabe(true);
                LOG.debug("createFreigabe");
                return vm.viewmodel.client.execute(metaProtocol.executeRequest({
                    actionId: model.FREIGABE_CREATE_ACTION_ID,
                    param: model.freigabeCreateActionParam({
                        description: vm.freigabeDescription(),
                        eigbIds: vm.selectedEigb().map((eigb) => eigb.eigbId),
                        email: vm.freigabeEmail(),
                        locale: vm.freigabeLocale() || i18next.currentLocale()
                    })
                })).then(function () {
                    vm.viewmodel.errorReply(undefined);
                    vm.freigabeDescription(undefined);
                    vm.freigabeEmail(undefined);
                    vm.freigabeLocale(undefined);
                    ui.resetForm(form);
                }, function (exc) {
                    vm.viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
                    LOG.warn("createFreigabe failed", exc);
                }).then(function () {
                    vm.isCreatingFreigabe(false);
                });
            };

            vm.activeFreigabeResend = ko.observable();
            vm.hasFreigabeResendEmailFocus = ko.observable(false);

            vm.activateFreigabeResend = function (freigabe) {
                vm.activeFreigabeResend(freigabe.id);
                ko.tasks.schedule(() => vm.hasFreigabeResendEmailFocus(true));
            };

            vm.freigabeResendEmail = ko.observable();
            vm.freigabeResendLocale = ko.observable();
            vm.isResendingFreigabe = ko.observable(false);

            vm.resendFreigabe = function (form) {
                if (ui.validateForm(form) === false) {
                    return;
                }
                vm.isResendingFreigabe(true);
                LOG.debug("resendFreigabe");
                return vm.viewmodel.client.execute(metaProtocol.executeRequest({
                    actionId: model.FREIGABE_RESEND_ACTION_ID,
                    param: model.freigabeResendActionParam({
                        email: vm.freigabeResendEmail(),
                        locale: vm.freigabeResendLocale() || i18next.currentLocale(),
                        metaId: vm.activeFreigabeResend()
                    })
                })).then(function () {
                    vm.activeFreigabeResend(undefined);
                    vm.viewmodel.errorReply(undefined);
                    vm.freigabeResendEmail(undefined);
                    vm.freigabeResendLocale(undefined);
                    ui.resetForm(form);
                }, function (exc) {
                    vm.viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
                    LOG.warn("resendFreigabe failed", exc);
                }).then(function () {
                    vm.isResendingFreigabe(false);
                });
            };

            vm.isRevokingFreigabe = ko.observable(false);

            vm.revokeFreigabe = function (freigabeVm) {
                if (confirm(i18next.t("freigabe_revoke_confirmation")) === false) {
                    return;
                }
                freigabeVm.isRevoking(true);
                LOG.debug("revokeFreigabe");
                return vm.viewmodel.client.execute(metaProtocol.executeRequest({
                    actionId: model.FREIGABE_REVOKE_ACTION_ID,
                    param: model.freigabeRevokeActionParam({
                        metaId: freigabeVm.id
                    })
                })).then(function () {
                    vm.viewmodel.errorReply(undefined);
                }, function (exc) {
                    vm.viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
                    LOG.warn("revokeFreigabe failed", exc);
                }).then(function () {
                    freigabeVm.isRevoking(false);
                });
            };

            vm.hasEigbFilterFocus = ko.observable(false);
            vm.focusEigbFilter = function () {
                vm.hasEigbFilterFocus(true);
                return true;
            };

            vm.stateOptions = Object.keys(vm.freigabeState).map(function (key) {
                return {
                    name: i18next.computedT("freigabe_state_" + key),
                    state: key
                };
            });
            vm.filterState = ko.observable();
            vm.validatedFilterState = ko.pureComputed(function () {
                const filterState = vm.filterState();
                if (_.isEmpty(filterState) === false) {
                    return filterState;
                }
            });
            vm.filterStateClear = () => vm.filterState(undefined);

            vm.trusterOptions = Object.keys(TRUSTER_OPTIONS).map(function (key) {
                return {
                    name: i18next.computedT("freigabe_filter_truster_" + key),
                    truster: key
                };
            });
            vm.filterTruster = ko.observable(viewmodel.isSchaeppiUser()
                ? TRUSTER_OPTIONS.NON_ADMIN
                : TRUSTER_OPTIONS.OWN);
            vm.validatedFilterTruster = ko.pureComputed(function () {
                const filterTruster = vm.filterTruster();
                if (_.isEmpty(filterTruster) === false && filterTruster !== TRUSTER_OPTIONS.ALL) {
                    return filterTruster;
                }
            });
            vm.filterTrusterClear = () => vm.filterTruster(TRUSTER_OPTIONS.ALL);

            vm.isLoadingFreigaben = ko.observable(false);

            vm.search = function () {
                const selectedEigbIds = vm.viewmodel.filterEigbSelectedIds();
                const eigbIds = (
                    _.isEmpty(selectedEigbIds)
                        ? vm.freigabeEigb().map((eigb) => eigb.eigbId)
                        : selectedEigbIds
                );
                LOG.debug(`search, eigbIds=${eigbIds}`);
                if (eigbIds.length <= 0) {
                    return;
                }
                const queryParts = [model.lucene.newEigbIdQueryPart(eigbIds)];
                const filterState = vm.validatedFilterState();
                if (filterState) {
                    queryParts.push("state:" + filterState);
                }
                const filteTruster = vm.validatedFilterTruster();
                if (filteTruster) {
                    queryParts.push((
                        TRUSTER_OPTIONS.OWN === filteTruster
                            ? `truster:"${vm.user()}"`
                            : "admin:false"
                    ));
                }
                vm.isLoadingFreigaben(true);
                vm.freigabeObjects.clear();
                return vm.freigabeObjects.processSearch(
                    vm.viewmodel.client.search(metaProtocol.searchRequest({
                        cursor: vm.freigabeObjects.searchCursor(),
                        limit: 0,
                        query: vm.freigabeObjects.newSearchQuery(queryParts),
                        sort: metaProtocol.searchRequestSort({field: "timestamp", reverse: true})
                    }))
                ).then(function () {
                    vm.viewmodel.errorReply(undefined);
                }, function (exc) {
                    vm.viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
                    LOG.warn("search failed", exc);
                }).then(function () {
                    vm.isLoadingFreigaben(false);
                });
            };

            vm.onNavUpdate = function () {
                LOG.debug("onNavUpdate");
                if (_.has(nav.currentState(), "search")) {
                    const selectedEigbIds = vm.viewmodel.filterEigbSelectedIds();
                    vm.freigabeEigb().forEach(function (freigabe) {
                        if (selectedEigbIds.includes(freigabe.eigbId)) {
                            freigabe.selected(true);
                        }
                    });
                    return vm.search();
                }
            };

            nav.register({
                id: vm.viewmodel.COMPONENTS.FREIGABE,
                onUpdate: vm.onNavUpdate
            });

            vm.dispose = function () {
                LOG.debug("dispose");
                vm.viewmodel.client.unregisterOnEvent(vm.freigabeSub);
            };

            ko.when(() => 0 < vm.viewmodel.eigBuchhaltungen().length).then(vm.search);

            return vm;
        }
    }
});
