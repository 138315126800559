<!-- portal-web/modules/component/onboarding -->
<div class="card" id="onboarding">
    <div class="card-body">
        <div class="align-items-center d-flex justify-content-between mb-3">
            <h1 class="card-title h4" data-bind="i18n: 'onboarding'"></h1>
            <div class="dropdown">
                <button aria-expanded="false"
                    class="btn btn-outline-secondary dropdown-toggle"
                    data-bs-toggle="dropdown"
                    id="onboardingI18nChange"
                    type="button">
                    <i class="bi bi-globe"></i>
                    <span data-bind="i18n: 'locale_' + i18next.currentLocale()"></span>
                </button>
                <ul aria-labelledby="loginI18nChange" class="dropdown-menu">
                    <li>
                        <a class="dropdown-item"
                            data-bind="click: changeLocale.bind(undefined, 'de'), i18n: 'locale_de'"
                            href="#locale-de"></a>
                    </li>
                    <li>
                        <a class="dropdown-item"
                            data-bind="click: changeLocale.bind(undefined, 'en'), i18n: 'locale_en'"
                            href="#locale-en"></a>
                    </li>
                </ul>
            </div>
        </div>
        <div data-bind="if: isInvalidParam">
            <div class="alert alert-danger" data-bind="i18n: 'onboarding_invalid'" id="onboardingInvalidParam"></div>
        </div>
        <div class="mb-5" data-bind="visible: !tokenValidationState()">
            <img class="img-fluid rounded mb-2" src="static/onboarding_letter.jpg">
            <form data-bind="submit: validateCode" id="validateCode" novalidate>
                <fieldset data-bind="attr: {disabled: isApprovingNewStarted}">
                    <div data-bind="foreach: codes, attr: {disabled: isApprovingNewStarted}">
                        <div class="mb-2">
                            <label class="form-label" data-bind="
                                attr: {for: 'onboardingCode' + ($index() + 1)},
                                i18n: {key: 'onboarding_code', options: {nr: $index() + 1}}
                            "></label>
                            <input autocomplete="off" class="form-control" data-bind="
                                attr: {id: 'onboardingCode' + ($index() + 1)},
                                hasFocus: hasFocus,
                                textInput: code,
                                validity: isValid
                            " type="text">
                            <div class="invalid-feedback" data-bind="i18n: 'onboarding_code_invalid'"></div>
                        </div>
                    </div>
                    <div class="d-grid gap-2">
                        <button class="btn btn-outline-secondary" data-bind="click: addCode.bind(undefined, undefined)" type="button">
                            <span data-bind="i18n: 'onboarding_code_add'"></span>
                        </button>
                        <button class="btn btn-schaeppi" data-bind="busyButton: isValidatingFreigabe" type="submit">
                            <span data-bind="i18n: 'onboarding_validate_code'"></span>
                        </button>
                    </div>
                </fieldset>
            </form>
        </div>
        <div data-bind="if: isInvalidToken()">
            <div class="alert alert-danger" data-bind="i18n: 'onboarding_token_invalid'" id="onboardingInvalidToken"></div>
        </div>
        <div class="mb-5" data-bind="visible: hasValidFreigabe" id="validateResult">
            <h2 class="h5" data-bind="i18n: 'onboarding_eigb'"></h2>
            <ul class="list-unstyled mb-0" data-bind="foreach: validEntries">
                <li>
                    <i class="bi bi-journals"></i>
                    <span data-bind="text: eigbBez"></span><br>
                    <small class="text-muted" data-bind="text: persName"></small>
                </li>
            </ul>
            <h2 class="h5 mt-5" data-bind="i18n: 'onboarding_account'"></h2>
            <div class="d-grid gap-2">
                <button class="btn btn-schaeppi"
                    data-bind="click: startApproveNew, hasFocus: hasValidFreigabe"
                    id="approveNewUserBtn"
                    type="button">
                    <span data-bind="i18n: 'onboarding_account_new'"></span>
                </button>
                <button class="btn btn-schaeppi" data-bind="click: approveExisting" id="approveExistingUserBtn" type="button">
                    <span data-bind="i18n: 'onboarding_account_existing'"></span>
                </button>
            </div>
        </div>
        <div data-bind="visible: isApprovingNewStarted">
            <h2 class="h5" data-bind="i18n: 'onboarding_account_new'"></h2>
            <form data-bind="submit: approveNew" id="approveNew" novalidate>
                <div class="mb-2">
                    <label class="form-label" data-bind="i18n: 'onboarding_new_user'" for="onboardingUser"></label>
                    <input aria-describedby="onboardingUserHelp" autocomplete="username" class="form-control" data-bind="
                        attr: {'aria-label': i18next.pureComputedT('onboarding_new_user_help')},
                        hasFocus: hasUserFocus,
                        textInput: user,
                        validity: isUserValid
                    " id="onboardingUser" pattern="[a-zA-Z0-9][a-zA-Z0-9.\-_@+]{2,}" required type="text">
                    <div class="form-text" data-bind="i18n: 'onboarding_new_user_help'" id="onboardingUserHelp"></div>
                    <div class="invalid-feedback" data-bind="i18n: 'onboarding_new_user_invalid'"></div>
                </div>
                <div class="mb-2">
                    <label class="form-label" data-bind="i18n: 'onboarding_new_email'" for="onboardingEmail"></label>
                    <input aria-describedby="onboardingEmailHelp" autocomplete="email" class="form-control" data-bind="
                        attr: {'aria-label': i18next.pureComputedT('onboarding_new_email_help')},
                        textInput: email
                    " id="onboardingEmail" required type="email">
                    <div class="form-text" data-bind="i18n: 'onboarding_new_email_help'" id="onboardingEmailHelp"></div>
                </div>
                <h3 class="h6" data-bind="i18n: 'password'"></h3>
                <div class="mb-2">
                    <label class="form-label" data-bind="i18n: 'new_password'" for="onboardingPassword"></label>
                    <div class="input-group">
                        <input aria-describedby="onboardingPasswordHelp"
                            autocomplete="new-password"
                            class="form-control"
                            data-bind="textInput: password, attr: {type: unmaskPasswords() ? 'text' : 'password'}, validity: isPasswordValid"
                            id="onboardingPassword"
                            required>
                        <button class="btn btn-secondary" data-bind="click: togglePasswordMasking" tabindex="-1" type="button">
                            <i class="bi bi-eye"></i>
                        </button>
                    </div>
                    <div class="form-text" data-bind="i18n: 'new_password_help'" id="onboardingPasswordHelp"></div>
                </div>
                <div class="mb-2" data-bind="visible: !unmaskPasswords()">
                    <label class="form-label" data-bind="i18n: 'new_password_confirm'" for="onboardingPasswordConfirm"></label>
                    <input autocomplete="new-password" class="form-control" data-bind="
                        textInput: passwordConfirm,
                        validity: isPasswordValid
                    " id="onboardingPasswordConfirm" type="password">
                </div>
                <h3 class="h6" data-bind="i18n: 'onboarding_new_2fa'"></h3>
                <div class="mb-2">
                    <label class="form-label" data-bind="i18n: 'method'" for="onboardingOtpType"></label>
                    <select class="form-select" data-bind="
                        options: otpTypeOptions,
                        optionsText: 'name',
                        optionsValue: 'type',
                        value: otpType
                    " id="onboardingOtpType"></select>
                </div>
                <div class="mb-2" data-bind="visible: otpTypes.EMAIL === otpType()">
                    <p class="mb-0">
                        <i class="bi bi-shield-plus"></i>
                        <span data-bind="i18n: 'onboarding_new_2fa_email'"></span>
                    </p>
                </div>
                <div class="mb-2" data-bind="visible: otpTypes.TOTP === otpType()">
                    <p class="mb-0">
                        <i class="bi bi-shield-plus"></i>
                        <span data-bind="i18n: 'onboarding_new_2fa_totp'"></span>
                    </p>
                </div>
                <div class="d-grid gap-2">
                    <button class="btn btn-schaeppi" data-bind="busyButton: isApprovingNew" type="submit">
                        <span data-bind="i18n: 'onboarding_new'"></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="mt-2" id="onboardingBottom"></div>
<div class="fixed-top">
    <div class="toast-container position-absolute p-3 top-0 start-50 translate-middle-x">
        <div aria-atomic="true"
            aria-live="assertive"
            class="toast text-bg-danger hide"
            data-bind="toast: isVerifyFailed"
            id="onboardingVerifyError"
            role="alert">
            <div class="d-flex">
                <div class="toast-body" data-bind="i18n: 'onboarding_verify_failed'"></div>
                <button aria-label="Close" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" type="button"></button>
            </div>
        </div>
    </div>
</div>
<verify params="id: 'onboarding', otpType: otpType, onVerify: onVerify, onVerifyDone: onVerifyDone"></verify>
