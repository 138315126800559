/*global */
export default Object.freeze(function fetchConfig(forgeStammdatenUrl) {
    return fetch("./config.json").then(function (response) {
        return response.json();
    }).then(function (config) {
        return Promise.all([config, fetch(forgeStammdatenUrl(config.engine))]);
    }).then(function (result) {
        const config = result[0];
        const stammdatenResponse = result[1];
        return Promise.all([config, stammdatenResponse.json()]);
    }).then(function (result) {
        const config = result[0];
        const stammdaten = result[1];
        return Object.assign(config, stammdaten);
    });
});
