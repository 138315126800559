/*global */
export default {
    de: {
        translation: {
            freigabe_state_APPROVED: "Freigegeben",
            freigabe_state_PENDING: "Hängig",
            freigabe_state_REVOKED: "Widerrufen",
            geschaeftsjahr_extended: "{{geschaeftsjahr}} - Aktuell ({{begin}} - {{end}})",
            geschaeftsjahr_extended_abgeschlossen: "{{geschaeftsjahr}} - Abgeschlossen ({{begin}} - {{end}})",
            login_title: "Eigentümerportal"
        }
    },
    en: {
        translation: {
            freigabe_state_APPROVED: "Approved",
            freigabe_state_PENDING: "Pending",
            freigabe_state_REVOKED: "Revoked",
            geschaeftsjahr_extended: "{{geschaeftsjahr}} - Current ({{begin}} - {{end}})",
            geschaeftsjahr_extended_abgeschlossen: "{{geschaeftsjahr}} - Completed ({{begin}} - {{end}})",
            login_title: "Eigentümerportal"
        }
    }
};
