/*global $productFruits, window */
import Logger from "js-logger";
import ko from "knockout";
import metaClient from "meta-client/modules/client";
import loginComponent from "meta-client/modules/component/login";
import searcherComponent from "meta-client/modules/component/searcher";
import securityComponent from "meta-client/modules/component/security";
import verifyComponent from "meta-client/modules/component/verify";
import clientI18n from "meta-client/modules/i18n";
import logging from "meta-client/modules/logging";
import metaProtocol from "meta-core/modules/protocol";
import coreI18n from "portal-core/modules/i18n";
import model from "portal-core/modules/model";
import protocol from "portal-core/modules/protocol";
import coreViewmodel from "portal-core/modules/viewmodel";
import adminComponent from "portal-web/modules/component/admin";
import ansprechpartnerComponent from "portal-web/modules/component/ansprechpartner";
import belegComponent from "portal-web/modules/component/beleg";
import buchhaltungComponent from "portal-web/modules/component/buchhaltung";
import dokumentComponent from "portal-web/modules/component/dokument";
import freigabeComponent from "portal-web/modules/component/freigabe";
import onboardingComponent from "portal-web/modules/component/onboarding";
import _ from "underscore";
import crypto from "util-web/modules/crypto";
import dates from "util-web/modules/dates";
import ext from "util-web/modules/ext";
import i18next from "util-web/modules/i18next";
import keyval from "util-web/modules/keyval";
import nav from "util-web/modules/nav";
import ui from "util-web/modules/ui";
import config from "./config";
import i18n from "./i18n";
import viewmodel from "./viewmodel";

const LOG = Logger.get("portal-web/index");
const LOGIN_TIMESTAMP_STORAGE_KEY_PREFIX = "logintimestamp-";
const NEWS_STORAGE_KEY = "news";
const PRODUCTFRUIT_DUMMY_STORAGE_KEY = "profruit";
const newSubscriptionRequest = function (clientId, isAdmin) {
    const channels = [clientId, protocol.ZIP_CHANNEL];
    const typeId = [model.ZIPGESCHAEFTSJAHR_TYPE_ID];
    if (isAdmin) {
        channels.push(protocol.FREIGABE_CHANNEL);
        channels.push(protocol.PORTALPLUS_CHANNEL);
        typeId.push(model.FREIGABE_TYPE_ID);
        typeId.push(model.PORTAL_PLUS_ABO_TYPE_ID);
    }
    return metaProtocol.subscriptionRequest({
        channels,
        client: true,
        meta: true,
        metaCriteria: metaProtocol.subscriptionRequestMetaCriteria({typeId})
    });
};
const processNews = function (config, feed, observableArray, read) {
    observableArray(_.get(config, ["feeds", feed], []).map((news) => coreViewmodel.newsVm(news, read(news))).sort(ext.simpleSort.bind({
        property: "date",
        reverse: true
    })));
};
const vm = {
    activateAdminSettings: () => vm.viewmodel.isAdminSettingsActive(true),
    activateFreigabeApprove() {
        vm.isFreigabeApproveActive(true);
        ko.tasks.schedule(() => vm.hasFreigabeApproveFocus(true));
    },
    changeLocale(locale) {
        i18next.setLocale(locale);
    },
    code: ko.observable(),
    currentLocale: i18next.currentLocale,
    doLogout: ko.observable(false),
    hasFreigabeApproveFocus: ko.observable(false),
    hasLoaded: ko.observable(false),
    hasLoadedEigb: ko.observable(false),
    isApprovingFreigabe: ko.observable(false),
    isCodeValid: ko.pureComputed(function () {
        const code = vm.code();
        return _.isEmpty(code) === false && 14 === code.length;
    }),
    isFreigabeApproveActive: ko.observable(false),
    isNewsActive: ko.observable(false),
    isOnboardingExistingSuccess: ko.observable(false),
    isOnboardingFailed: ko.observable(false),
    isOnboardingLoginRequired: ko.observable(false),
    isOnboardingNewSuccess: ko.observable(false),
    isSaveSettingsSuccess: ko.observable(false),
    isSavingSettings: ko.observable(false),
    isSettingsActive: ko.observable(false),
    news: ko.observableArray(),
    newsAds: ko.observableArray(),
    newsAdsForLocale: ko.pureComputed(() => vm.newsAds().filter((news) => vm.currentLocale() === news.locale)),
    newsMeter: undefined,
    newsRead: ko.pureComputed(() => vm.news().filter((news) => vm.currentLocale() === news.locale && news.read() && (_.some(news.bhTypes, (bhType) => vm.viewmodel.eigBuchhaltungenTypes().includes(bhType))))),
    newsUnread: ko.pureComputed(() => vm.news().filter((news) => vm.currentLocale() === news.locale && news.read() === false && (_.some(news.bhTypes, (bhType) => vm.viewmodel.eigBuchhaltungenTypes().includes(bhType))))),
    settingsEmail: ko.observable(),
    settingsIgnoredDokTypes: ko.pureComputed(function () {
        const dokTypes = vm.config.dokTypes;
        return dokTypes.map(function (dokType, index) {
            return {
                id: index,
                isSelected: ko.observable(false),
                value: dokType
            };
        });
    }),
    settingsLocale: ko.observable(),
    showReadNews: ko.observable(false),
    storedNavParams: ko.observable()
};

let helpUrlTemplate;
let isProductFruitsEnabled = false;
let productFruitsDummyUser;

vm.callbackPromise = function () {
    const callback = vm.viewmodel.authCallback();
    if (callback) {
        return callback().then(function () {
            LOG.debug("onAuth callback success");
            vm.isOnboardingExistingSuccess(true);
        }, function (exc) {
            LOG.warn("onAuth callback failed", exc);
            vm.isOnboardingFailed(true);
        }).then(function () {
            vm.viewmodel.authCallback(undefined);
        });
    }
    return Promise.resolve();
};

vm.reloadProductFruitsSession = function () {
    if (isProductFruitsEnabled) {
        const destroy = _.get(window, ["productFruits", "services", "destroy"]);
        if (_.isFunction(destroy)) {
            destroy();
        }
        $productFruits.push(["init", vm.config.productFruitsWorkspaceCode, vm.currentLocale(), {
            props: {
                egtmType: vm.viewmodel.eigBuchhaltungenTypes(),
                portalPlus: vm.viewmodel.hasPortalPlus()
            },
            username: vm.client.user() || productFruitsDummyUser
        }]);
    }
};

vm.currentLocale.subscribe(() => vm.reloadProductFruitsSession());

vm.onAuth = function () {
    vm.hasLoadedEigb(false);
    vm.isOnboardingExistingSuccess(false);
    vm.isOnboardingLoginRequired(false);
    const user = vm.client.user();
    return vm.callbackPromise().then(function () {
        vm.viewmodel.isActive(true);
        return vm.viewmodel.loadEigb(true);
    }).then(function () {
        return vm.client.subscribe(newSubscriptionRequest(vm.client.clientId(), vm.viewmodel.isEigbAdmin()));
    }).catch(function (exc) {
        const excString = JSON.stringify(exc, undefined, 4);
        vm.viewmodel.errorReply(excString);
        LOG.error(`onAuth failed, user=${user}, exc=${excString}`);
    }).then(function () {
        vm.hasLoadedEigb(true);
        return keyval.get(LOGIN_TIMESTAMP_STORAGE_KEY_PREFIX + user);
    }).then(function (loginTimestamp) {
        vm.reloadProductFruitsSession();
        if (loginTimestamp) {
            vm.viewmodel.loginTimestamp(dates.parseDateTime(loginTimestamp));
        }
        return keyval.set(LOGIN_TIMESTAMP_STORAGE_KEY_PREFIX + user, dates.nowDateTimeString());
    }).then(function () {
        if (Boolean(vm.client.otpType()) === false) {
            ko.when(() => Boolean(vm.client.otpType())).then(() => vm.viewmodel.navigateToBuchhaltung());
            return vm.viewmodel.navigateToSecurity();
        }
        if (_.isEmpty(vm.newsUnread()) === false) {
            vm.isNewsActive(true);
        }
        if (vm.storedNavParams()) {
            return nav.pushState(vm.storedNavParams());
        }
        return vm.viewmodel.navigateToBuchhaltung();
    });
};

vm.logout = function () {
    vm.doLogout(true);
    vm.viewmodel.isActive(false);
    return vm.viewmodel.navigateToIndex().then(function () {
        vm.viewmodel.liegBuchhaltungen.removeAll();
        vm.viewmodel.eigBuchhaltungen.removeAll();
        if (isProductFruitsEnabled) {
            const identifyUser = _.get(window, ["productFruits", "identifyUser"]);
            if (_.isFunction(identifyUser)) {
                identifyUser({username: productFruitsDummyUser});
            }
        }
    });
};

vm.activateHelp = function () {
    if (helpUrlTemplate) {
        window.open(helpUrlTemplate({locale: vm.currentLocale()}), "_blank");
    }
};

vm.activateNews = function () {
    vm.showReadNews(true);
    vm.isNewsActive(true);
};

vm.markNewsRead = function (news) {
    vm.newsMeter.mark();
    return keyval.get(NEWS_STORAGE_KEY).then(function (readNews) {
        const newReadNews = [news.url];
        if (readNews) {
            newReadNews.push(readNews);
        }
        return keyval.set(NEWS_STORAGE_KEY, newReadNews.join(";"));
    }).then(function () {
        news.read(true);
        ko.tasks.schedule(function () {
            if (vm.showReadNews() === false && _.isEmpty(vm.newsUnread())) {
                vm.isNewsActive(false);
            }
        });
    });
};

vm.readNews = function (news) {
    window.open(news.url, "_blank");
    return vm.markNewsRead(news);
};

vm.activateSettings = function () {
    const userConfig = vm.viewmodel.userConfig();
    vm.settingsEmail(_.get(userConfig, ["config", "email"]));
    const ignoredDokTypes = vm.viewmodel.ignoredDokTypes();
    vm.settingsIgnoredDokTypes().forEach(function (dokType) {
        dokType.isSelected(ignoredDokTypes.find((ignoredDokType) => dokType.value === ignoredDokType));
    });
    vm.settingsLocale(_.get(userConfig, ["config", "locale"], i18next.currentLocale()));
    vm.isSettingsActive(true);
};

vm.saveSettings = function (form) {
    if (ui.validateForm(form) === false) {
        return;
    }
    vm.isSavingSettings(true);
    const ignoredDokTypes = vm.settingsIgnoredDokTypes().filter((ignoredDokType) => ignoredDokType.isSelected()).map((ignoredDokType) => ignoredDokType.value);
    const newUserConfig = model.portalUserConfig({
        email: vm.settingsEmail(),
        ignoredDokTypes,
        locale: vm.settingsLocale()
    });
    const user = vm.client.user();
    LOG.debug(`saveSettings, user=${user}`, newUserConfig);
    return vm.client.configWrite(metaProtocol.userConfigWriteRequest({
        action: metaProtocol.WRITE_ACTION.UPDATE,
        config: Object.assign({}, vm.viewmodel.userConfig(), {config: newUserConfig})
    })).then(function (reply) {
        LOG.info(`saveSettings success, user=${user}`);
        vm.viewmodel.errorReply(undefined);
        vm.viewmodel.userConfig(reply.config);
        vm.isSaveSettingsSuccess(true);
        vm.isSettingsActive(false);
        vm.settingsEmail(undefined);
        vm.settingsLocale(undefined);
        ui.resetForm(form);
    }, function (exc) {
        vm.viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
        LOG.warn(`saveSettings failed, user=${user}`, exc);
    }).then(function () {
        vm.isSavingSettings(false);
    });
};

vm.approveFreigabe = function (form) {
    if (ui.validateForm(form) === false) {
        return;
    }
    vm.isApprovingFreigabe(true);
    return vm.client.execute(metaProtocol.executeRequest({
        actionId: model.FREIGABE_APPROVE_ACTION_ID,
        param: model.freigabeApproveActionParam({
            codes: [vm.code()],
            locale: i18next.currentLocale()
        })
    })).then(function (reply) {
        vm.viewmodel.onboardingGroups(reply.result.groups);
        vm.viewmodel.errorReply(undefined);
        vm.code(undefined);
        vm.isFreigabeApproveActive(false);
        ui.resetForm(form);
        return vm.viewmodel.loadEigb();
    }, function (exc) {
        vm.viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
        LOG.warn("approveFreigabe failed", exc);
    }).then(function () {
        vm.isApprovingFreigabe(false);
    });
};

vm[metaProtocol.CLIENT_NOTIFICATION] = function (clientNotification) {
    vm.viewmodel.onClientNotification(clientNotification);
    logging.onClientNotification(clientNotification);
};

vm.onNavRemove = function () {
    LOG.debug("onNavRemove");
    vm.viewmodel.activeComponent(undefined);
    vm.viewmodel.isZippingFiles(false);
    vm.isOnboardingFailed(false);
    vm.isOnboardingNewSuccess(false);
};

vm.onNavUpdate = function (param) {
    LOG.debug(`onNavUpdate, param=${param}`);
    vm.viewmodel.activeComponent(param || vm.viewmodel.COMPONENTS.INDEX);
};

ext.loadConfig(config, (engine) => metaClient.forgeEngineRestUrl(engine) + protocol.STAMMDATEN_RESOURCE).then(function (loadedConfig) {
    vm.config = loadedConfig;
    vm.client = metaClient(vm.config.engine);
    logging.installEngineLogger(vm.client, (
        vm.config.debug
            ? Logger.DEBUG
            : Logger.INFO
    ));
    vm.client.registerOnEvent(vm);
    vm.client.isConnected.subscribe(function (isConnected) {
        if (isConnected === false) {
            vm.viewmodel.isActive(false);
            vm.viewmodel.navigateToIndex();
        }
    });
    vm.viewmodel = viewmodel({client: vm.client, config: vm.config});
    nav.register({
        id: vm.viewmodel.COMPONENTS.INDEX,
        onRemove: vm.onNavRemove,
        onUpdate: vm.onNavUpdate
    });
    return Promise.all([
        keyval.get(NEWS_STORAGE_KEY),
        keyval.get(PRODUCTFRUIT_DUMMY_STORAGE_KEY),
        ui.bind({
            components: [
                {module: adminComponent, name: "admin"},
                {module: ansprechpartnerComponent, name: "ansprechpartner"},
                {module: belegComponent, name: "beleg"},
                {module: buchhaltungComponent, name: "buchhaltung"},
                {module: dokumentComponent, name: "dokument"},
                {module: freigabeComponent, name: "freigabe"},
                {module: loginComponent, name: "login"},
                {module: onboardingComponent, name: "onboarding"},
                {module: searcherComponent, name: "searcher"},
                {module: securityComponent, name: "security"},
                {module: verifyComponent, name: "verify"}
            ],
            i18NextOptions: {
                debug: vm.config.debug,
                options: {interpolation: {skipOnVariables: false}},
                resources: ext.merge(clientI18n, coreI18n, i18n, vm.config.i18n)
            },
            viewmodel: vm
        }),
        nav.init({})
    ]);
}).then(function (results) {
    document.title = i18next.t("title");
    helpUrlTemplate = _.template(vm.config.help);
    const readNews = results[0];
    const readNewsUrls = (
        readNews
            ? readNews.split(";")
            : []
    );
    processNews(vm.config, protocol.ADS_FEED, vm.newsAds, () => false);
    if (_.isEmpty(vm.newsAds()) === false) {
        vm.newsAds()[0].active(true);
    }
    processNews(vm.config, protocol.NEWS_FEED, vm.news, (news) => readNewsUrls.includes(news.url));
    vm.newsMeter = logging.newMetricMeter(vm.client, "portal-web.index.news");
    vm.viewmodel.localeOptions([
        {locale: "de", text: i18next.t("locale_de")},
        {locale: "en", text: i18next.t("locale_en")}
    ]);
    vm.hasLoaded(true);
    LOG.info(`loaded, version=${vm.viewmodel.version}`);
    if (nav.currentParams().has(protocol.ONBOARDING_PARAM)) {
        vm.viewmodel.navigateToOnboarding();
    } else {
        if (nav.currentParams().has(vm.viewmodel.COMPONENTS.INDEX) && vm.viewmodel.COMPONENTS.INDEX !== nav.currentParams().get(vm.viewmodel.COMPONENTS.INDEX)) {
            vm.storedNavParams(nav.currentParams());
        }
        vm.viewmodel.navigateToIndex();
    }
    const storedDummyUser = results[1];
    if (storedDummyUser) {
        return Promise.resolve(storedDummyUser);
    }
    const newDummyUser = crypto.newUuid();
    return Promise.all([
        Promise.resolve(newDummyUser),
        keyval.set(PRODUCTFRUIT_DUMMY_STORAGE_KEY, newDummyUser)
    ]);
}).then(function (results) {
    productFruitsDummyUser = results[0];
    LOG.debug(`productFruitsDummyUser=${productFruitsDummyUser}`);
    isProductFruitsEnabled = vm.config.productFruitsWorkspaceCode && _.isArray($productFruits);
    if (isProductFruitsEnabled) {
        $productFruits.push(["init", vm.config.productFruitsWorkspaceCode, vm.currentLocale(), {username: productFruitsDummyUser}]);
    }
}).catch((exc) => LOG.error("load failed", exc));
