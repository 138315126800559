/*global */
import Logger from "js-logger";
import ko from "knockout";
import metaProtocol from "meta-core/modules/protocol";
import model from "portal-core/modules/model";
import _ from "underscore";
import ext from "util-web/modules/ext";
import i18next from "util-web/modules/i18next";
import ui from "util-web/modules/ui";
import template from "./admin.html";

const LOG = Logger.get("portal-web/component/admin");
const EIGB_SEARCH_LIMIT = 50;

export default Object.freeze({
    template,
    viewModel: {
        createViewModel: function ({viewmodel}) {
            const vm = {
                buchhaltungen: ko.observableArray(),
                eigbQuery: ko.observable(),
                eigbQueryIncludeEigbLiegb: ko.observable(true),
                eigbQueryIncludePers: ko.observable(false),
                eigbQueryIncludeVerw: ko.observable(false),
                hasEigbQueryFocus: ko.observable(true),
                i18next,
                isLoading: ko.observable(false),
                selectedBuchhaltungen: ko.observableArray(),
                userName: ko.observable(),
                viewmodel
            };
            const resetEigbSearch = function () {
                vm.eigbQuery(undefined);
                vm.eigbQueryIncludeEigbLiegb(true);
                vm.eigbQueryIncludePers(false);
                vm.eigbQueryIncludeVerw(false);
                vm.selectedBuchhaltungen.removeAll();
                vm.buchhaltungen.removeAll();
            };
            const reset = function () {
                vm.viewmodel.errorReply(undefined);
                vm.userName(undefined);
                resetEigbSearch();
            };

            vm.viewmodel.adminLoadOwnGroups.subscribe(function (load) {
                if (load) {
                    vm.resetOwnGroups().then(() => vm.viewmodel.adminLoadOwnGroups(false));
                }
            });

            vm.userName.subscribe(function (userName) {
                if (userName) {
                    resetEigbSearch();
                }
            });

            /**
             * @param form {HTMLFormElement}
             * @param actionId {string}
             * @param param {object}
             * @returns {Promise.<void>}
             */
            vm.loadGroupsInternal = function (form, actionId, param) {
                if (form && ui.validateForm(form) === false) {
                    return Promise.resolve(undefined);
                }
                vm.isLoading(true);
                return vm.viewmodel.client.execute(metaProtocol.executeRequest({actionId, param})).then(function (reply) {
                    ui.resetForm(form);
                    vm.viewmodel.onboardingGroups(reply.result.groups);
                    reset();
                    return vm.viewmodel.loadEigb();
                }).then(function () {
                    viewmodel.isAdminSettingsActive(false);
                }, function (exc) {
                    vm.viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
                    LOG.warn("loadGroupsInternal failed", exc);
                }).then(function () {
                    vm.isLoading(false);
                });
            };

            /**
             * @param form {HTMLFormElement}
             * @param user {string}
             * @returns {Promise.<void>}
             */
            vm.loadUserGroupsInternal = function ({
                form = null,
                user
            }) {
                return vm.loadGroupsInternal(form, model.ADMIN_LOAD_USER_ACTION_ID, model.adminLoadUserActionParam({user}));
            };

            vm.resetOwnGroups = () => vm.loadOwnGroups().then(() => vm.viewmodel.showAdminOwnGroupsHint(false));
            vm.loadOwnGroups = () => vm.loadUserGroupsInternal({user: vm.viewmodel.client.user()});

            /**
             * @param form {HTMLFormElement}
             * @returns {Promise.<void>}
             */
            vm.loadUserGroups = function (form) {
                return vm.loadUserGroupsInternal({form, user: vm.userName()});
            };

            vm.eigbQueryPlaceholder = ko.pureComputed(function () {
                const fields = [];
                if (vm.eigbQueryIncludeEigbLiegb()) {
                    fields.push("admin_eigb_query_eigbliegb");
                }
                if (vm.eigbQueryIncludePers()) {
                    fields.push("admin_eigb_query_pers");
                }
                if (vm.eigbQueryIncludeVerw()) {
                    fields.push("admin_eigb_query_verw");
                }
                if (fields.length <= 0) {
                    return i18next.pureComputedT("admin_eigb_query_placeholder_invalid");
                }
                return i18next.pureComputedT("admin_eigb_query_placeholder", {fields: fields.map((f) => `$t(${f})`).join(", ")})();
            });
            const lazySearchEigb = _.debounce(() => vm.searchEigb(), 300);
            vm.eigbQuery.subscribe(function (query) {
                if (_.isEmpty(query) || query.length < 2) {
                    return;
                }
                vm.userName(undefined);
                lazySearchEigb();
            });
            vm.isEigbQueryValid = ko.pureComputed(() => _.isEmpty(vm.eigbQuery()) === false);
            vm.resetEigbQuery = function () {
                vm.eigbQuery(undefined);
                vm.buchhaltungen.removeAll();
            };
            vm.hasNoEigbResults = ko.observable(false);

            /**
             * @returns {Promise.<void>}
             */
            vm.searchEigb = function () {
                const eigbQuery = vm.eigbQuery();
                const parts = [];
                if (_.isFinite(parseInt(eigbQuery))) {
                    if (vm.eigbQueryIncludeEigbLiegb()) {
                        parts.push(`ref:${eigbQuery}*`);
                    }
                    if (vm.eigbQueryIncludePers()) {
                        parts.push(`persRef:${eigbQuery}*`);
                    }
                } else {
                    if (vm.eigbQueryIncludeEigbLiegb()) {
                        parts.push(`bez:${eigbQuery}*`);
                    }
                    if (vm.eigbQueryIncludePers()) {
                        parts.push(`persName:${eigbQuery}*`);
                    }
                    if (vm.eigbQueryIncludeVerw()) {
                        parts.push(`bhTeam:${eigbQuery}*`);
                        parts.push(`vwStandort:${eigbQuery}*`);
                        parts.push(`vwTeam:${eigbQuery}*`);
                    }
                }
                vm.hasNoEigbResults(false);
                return viewmodel.client.search(metaProtocol.searchRequest({
                    limit: EIGB_SEARCH_LIMIT,
                    purge: model.lucene.EIGB_PURGE,
                    query: model.lucene.EIGBUCHHALTUNG_QUERY + ` AND (${parts.join(" OR ")})`
                })).then(function (searchReply) {
                    vm.hasNoEigbResults(_.isEmpty(searchReply.metas));
                    vm.buchhaltungen(searchReply.metas.map(function (meta) {
                        const eigb = meta.value;
                        eigb.selected = ko.pureComputed(function () {
                            return vm.isBuchhaltungSelected(eigb);
                        });
                        return eigb;
                    }).sort(ext.simpleSort.bind(undefined, "bez")));
                }, function (exc) {
                    LOG.warn("searchEigb failed", exc);
                });
            };

            vm.selectBuchhaltung = function (eigb) {
                if (vm.isBuchhaltungSelected(eigb) === false && vm.selectedBuchhaltungen().length <= EIGB_SEARCH_LIMIT) {
                    vm.selectedBuchhaltungen.push(eigb);
                }
            };

            vm.selectAllBuchhaltungen = function () {
                vm.buchhaltungen().forEach(vm.selectBuchhaltung);
            };

            vm.deselectBuchhaltung = function (eigb) {
                vm.selectedBuchhaltungen.remove((e) => e.eigbId === eigb.eigbId);
            };

            vm.deselectAllBuchhaltungen = function () {
                vm.selectedBuchhaltungen.removeAll();
            };

            vm.isBuchhaltungSelected = function (eigb) {
                return Boolean(vm.selectedBuchhaltungen().find((e) => e.eigbId === eigb.eigbId));
            };

            /**
             * @param form {HTMLFormElement}
             * @returns {Promise.<void>}
             */
            vm.loadEigbGroups = function (form) {
                const groups = _.flatten(vm.selectedBuchhaltungen().map((eigb) => eigb.personen)).map((person) => model.PERSON_GROUP_PREFIX + person.persId);
                vm.selectedBuchhaltungen().forEach(function (eigb) {
                    groups.push(model.EIGBUCHHALTUNG_GROUP_PREFIX + eigb.eigbId);
                    groups.push(model.EIGBUCHHALTUNG_ADMIN_GROUP_PREFIX + eigb.eigbId);
                });
                if (groups.length <= 0) {
                    return Promise.resolve(undefined);
                }
                return vm.loadGroupsInternal(form, model.ADMIN_LOAD_EIGB_ACTION_ID, model.adminLoadEigbActionParam({groups}));
            };

            return vm;
        }
    }
});
