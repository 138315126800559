<!-- portal-web/modules/component/dokument -->
<div id="dokument">
    <h1 class="display-6" data-bind="i18n: 'dokument'"></h1>
    <div class="row">
        <div data-bind="visible: showFullscreen() === false, css: {'col-7': activeDokument(), 'col': Boolean(activeDokument()) === false}">
            <form class="mb-3 pe-1" data-bind="submit: search" id="dokumentSearch" novalidate>
                <div class="row g-3 mb-3">
                    <div class="col-6 col-lg-4">
                        <div class="d-flex justify-content-between mb-2">
                            <label class="form-label text-truncate" for="dokumenteFilterEigb">
                                <i class="bi bi-journals"></i>
                                <span data-bind="i18n: 'filter_eigb'"></span>
                            </label>
                            <div data-bind="visible: 0 < viewmodel.filterEigbSelectedIds().length">
                                <button class="btn btn-schaeppi btn-sm"
                                    data-bind="click: viewmodel.resetEigbFilter"
                                    id="dokumenteFilterEigbReset"
                                    type="button">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                        </div>
                        <div class="dropdown d-grid">
                            <button aria-expanded="false"
                                class="btn btn-secondary dropdown-toggle"
                                data-bind="click: focusEigbFilter"
                                data-bs-auto-close="outside"
                                data-bs-toggle="dropdown"
                                id="dokumenteFilterEigb"
                                type="button">
                                <span data-bind="i18n: 'filter_selection_none', visible: viewmodel.filterEigbSelectedIds().length <= 0"></span>
                                <span data-bind="visible: 0 < viewmodel.filterEigbSelectedIds().length">
                                    <span class="badge rounded-pill bg-schaeppi"
                                        data-bind="text: viewmodel.filterEigbSelectedIds().length"></span>
                                    <span data-bind="i18n: 'filter_selection'"></span>
                                </span>
                            </button>
                            <div aria-labelledby="dokumenteFilterEigb"
                                class="dropdown-menu dropdown-menu-end p-3"
                                style="min-width: 20rem;">
                                <a data-bind="click: viewmodel.selectAllEigbVisible, i18n: 'filter_select_all'"
                                    href="#"
                                    id="dokumenteFilterEigbSelectAll"></a>
                                <div class="input-group mt-3">
                                    <input autocomplete="off" class="form-control" data-bind="
                                        attr: {placeholder: i18next.pureComputedT('filter_search')},
                                        hasFocus: hasEigbFilterFocus,
                                        textInput: viewmodel.filterEigbQuery
                                    " id="dokumenteFilterEigbSearch" type="text">
                                    <button class="btn btn-outline-secondary"
                                        data-bind="click: viewmodel.resetEigbQuery"
                                        id="dokumenteFilterEigbSearchReset"
                                        type="button">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                </div>
                                <div class="overflow-y-auto" data-bind="foreach: viewmodel.filterEigbVisible" style="max-height: 21rem;">
                                    <div class="form-check mb-0 mt-2">
                                        <input class="form-check-input" data-bind="
                                            attr: {id: 'dokumenteFilterEigb' + eigbId},
                                            checked: selected
                                        " type="checkbox">
                                        <label class="form-check-label text-nowrap"
                                            data-bind="text: bez, attr: {for: 'dokumenteFilterEigb' + eigbId}"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-4">
                        <div class="d-flex justify-content-between mb-2">
                            <label class="form-label text-truncate" for="dokumenteFilterLiegb">
                                <i class="bi bi-building"></i>
                                <span data-bind="i18n: 'filter_liegb'"></span>
                            </label>
                            <div data-bind="visible: 0 < viewmodel.filterLiegbIds().length">
                                <button class="btn btn-schaeppi btn-sm"
                                    data-bind="click: viewmodel.resetLiegbFilter"
                                    id="dokumenteFilterLiegbReset"
                                    type="button">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                        </div>
                        <div class="dropdown d-grid">
                            <button aria-expanded="false"
                                class="btn btn-secondary dropdown-toggle"
                                data-bind="click: focusLiegbFilter"
                                data-bs-auto-close="outside"
                                data-bs-toggle="dropdown"
                                id="dokumenteFilterLiegb"
                                type="button">
                                <span data-bind="i18n: 'filter_selection_none', visible: viewmodel.filterLiegbIds().length <= 0"></span>
                                <span data-bind="visible: 0 < viewmodel.filterLiegbIds().length">
                                    <span class="badge rounded-pill bg-schaeppi" data-bind="text: viewmodel.filterLiegbIds().length"></span>
                                    <span data-bind="i18n: 'filter_selection'"></span>
                                </span>
                            </button>
                            <div aria-labelledby="dokumenteFilterLiegb"
                                class="dropdown-menu dropdown-menu-end p-3"
                                style="min-width: 20rem;">
                                <a data-bind="click: viewmodel.selectAllLiegbVisible, i18n: 'filter_select_all'"
                                    href="#"
                                    id="dokumenteFilterLiegbSelectAll"></a>
                                <div class="input-group mt-3">
                                    <input autocomplete="off" class="form-control" data-bind="
                                        attr: {placeholder: i18next.pureComputedT('filter_search')},
                                        hasFocus: hasLiegbFilterFocus,
                                        textInput: viewmodel.filterLiegbQuery
                                    " id="dokumenteFilterLiegbSearch" type="text">
                                    <button class="btn btn-outline-secondary"
                                        data-bind="click: viewmodel.resetLiegbQuery"
                                        id="dokumenteFilterLiegbSearchReset"
                                        type="button">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                </div>
                                <div class="overflow-y-auto" data-bind="foreach: viewmodel.filterLiegbVisible" style="max-height: 21rem;">
                                    <div class="form-check mb-0 mt-2">
                                        <input class="form-check-input" data-bind="
                                            attr: {id: 'dokumenteFilterLiegb' + liegbId},
                                            checked: selected
                                        " type="checkbox">
                                        <label class="form-check-label text-nowrap"
                                            data-bind="text: bez, attr: {for: 'dokumenteFilterLiegb' + liegbId}"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-4" data-bind="visible: 1 < viewmodel.userPersonen().length">
                        <div class="d-flex justify-content-between mb-2">
                            <label class="form-label text-truncate" for="dokumenteFilterPers">
                                <i class="bi bi-people"></i>
                                <span data-bind="i18n: 'filter_person'"></span>
                            </label>
                            <div data-bind="visible: 0 < viewmodel.filterPersSelectedIds().length">
                                <button class="btn btn-schaeppi btn-sm"
                                    data-bind="click: viewmodel.resetPersFilter"
                                    id="dokumenteFilterPersReset"
                                    type="button">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                        </div>
                        <div class="dropdown d-grid">
                            <button aria-expanded="false"
                                class="btn btn-secondary dropdown-toggle"
                                data-bind="click: focusPersFilter"
                                data-bs-auto-close="outside"
                                data-bs-toggle="dropdown"
                                id="dokumenteFilterPers"
                                type="button">
                                <span data-bind="i18n: 'filter_selection_none', visible: viewmodel.filterPersSelectedIds().length <= 0"></span>
                                <span data-bind="visible: 0 < viewmodel.filterPersSelectedIds().length">
                                    <span class="badge rounded-pill bg-schaeppi"
                                        data-bind="text: viewmodel.filterPersSelectedIds().length"></span>
                                    <span data-bind="i18n: 'filter_selection'"></span>
                                </span>
                            </button>
                            <div aria-labelledby="dokumenteFilterPers"
                                class="dropdown-menu dropdown-menu-end p-3"
                                style="min-width: 20rem;">
                                <a data-bind="click: viewmodel.selectAllPersVisible, i18n: 'filter_select_all'"
                                    href="#"
                                    id="dokumenteFilterPersSelectAll"></a>
                                <div class="input-group mt-3">
                                    <input autocomplete="off" class="form-control" data-bind="
                                        attr: {placeholder: i18next.pureComputedT('filter_search')},
                                        hasFocus: hasPersFilterFocus,
                                        textInput: viewmodel.filterPersQuery
                                    " id="dokumenteFilterPersSearch" type="text">
                                    <button class="btn btn-outline-secondary"
                                        data-bind="click: viewmodel.resetPersQuery"
                                        id="dokumenteFilterPersSearchReset"
                                        type="button">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                </div>
                                <div class="overflow-y-auto" data-bind="foreach: viewmodel.filterPersVisible" style="max-height: 21rem;">
                                    <div class="form-check mb-0 mt-2">
                                        <input class="form-check-input" data-bind="
                                            attr: {id: 'dokumenteFilterPers' + persId},
                                            checked: selected
                                        " type="checkbox">
                                        <label class="form-check-label text-nowrap"
                                            data-bind="text: persName, attr: {for: 'dokumenteFilterPers' + persId}"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-2 mb-3 mb-lg-0">
                        <div class="d-flex justify-content-between mb-2">
                            <label class="form-label text-truncate"
                                data-bind="i18n: 'filter_geschaeftsjahr'"
                                for="dokumentFilterGeschaeftsjahr"></label>
                            <div data-bind="visible: validatedFilterGeschaeftsjahr()">
                                <button class="btn btn-schaeppi btn-sm"
                                    data-bind="click: filterGeschaeftsjahrClear"
                                    id="dokumentFilterGeschaeftsjahrReset"
                                    type="button">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                        </div>
                        <select class="form-select" data-bind="
                            options: viewmodel.geschaeftsjahre,
                            optionsCaption: '',
                            value: filterGeschaeftsjahr
                        " id="dokumentFilterGeschaeftsjahr"></select>
                    </div>
                    <fieldset class="col-lg-4 mb-3 mb-lg-0" id="dokumentFilterDate">
                        <div class="row">
                            <div class="col-6">
                                <div class="d-flex justify-content-between mb-2">
                                    <label class="form-label text-truncate"
                                        data-bind="i18n: 'dokument_filter_date_from'"
                                        for="dokumentFilterDateFrom"></label>
                                    <div data-bind="visible: validatedFilterDateFrom()">
                                        <button class="btn btn-schaeppi btn-sm" data-bind="click: filterDateFromClear" type="button">
                                            <i class="bi bi-trash"></i>
                                        </button>
                                    </div>
                                </div>
                                <input class="form-control" data-bind="textInput: filterDateFrom" id="dokumentFilterDateFrom" type="date">
                            </div>
                            <div class="col-6">
                                <div class="d-flex justify-content-between mb-2">
                                    <label class="form-label text-truncate"
                                        data-bind="i18n: 'dokument_filter_date_to'"
                                        for="dokumentFilterDateTo"></label>
                                    <div data-bind="visible: validatedFilterDateTo()">
                                        <button class="btn btn-schaeppi btn-sm"
                                            data-bind="click: filterDateToClear"
                                            id="dokumentFilterDateToReset"
                                            type="button">
                                            <i class="bi bi-trash"></i>
                                        </button>
                                    </div>
                                </div>
                                <input class="form-control" data-bind="textInput: filterDateTo" id="dokumentFilterDateTo" type="date">
                            </div>
                        </div>
                    </fieldset>
                    <div class="col-6 col-lg-2">
                        <div class="d-flex justify-content-between mb-2">
                            <label class="form-label text-truncate"
                                data-bind="i18n: 'dokument_filter_dokumenttyp'"
                                for="dokumentFilterDokType"></label>
                            <div data-bind="visible: validatedFilterDokType()">
                                <button class="btn btn-schaeppi btn-sm"
                                    data-bind="click: filterDokTypeClear"
                                    id="dokumentFilterDokTypeReset"
                                    type="button">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                        </div>
                        <select class="form-select" data-bind="
                            options: dokTypes,
                            optionsCaption: '',
                            value: filterDokType
                        " id="dokumentFilterDokType"></select>
                    </div>
                    <div class="col-6 col-lg-2">
                        <div class="d-flex justify-content-between mb-2">
                            <label class="form-label text-truncate"
                                data-bind="i18n: 'dokument_filter_read'"
                                for="dokumentFilterRead"></label>
                            <div data-bind="visible: validatedFilterRead()">
                                <button class="btn btn-schaeppi btn-sm"
                                    data-bind="click: filterReadClear"
                                    id="dokumentFilterReadReset"
                                    type="button">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                        </div>
                        <select class="form-select" data-bind="
                            options: readOptions,
                            optionsText: 'name',
                            optionsValue: 'read',
                            value: filterRead
                        " id="dokumentFilterRead"></select>
                    </div>
                </div>
                <button class="btn btn-schaeppi"
                    data-bind="busyButton: dokumentObjects.isSearching, hasFocus: hasSubmitFocus"
                    type="submit">
                    <i class="bi bi-search"></i>
                    <span data-bind="i18n: 'dokument_load'"></span>
                </button>
            </form>
            <div class="mb-3 overflow-auto" data-bind="fixHeight: hasSubmitFocus, fixHeightMargin: 20">
                <div class="d-flex align-items-center justify-content-between mb-2"
                    data-bind="if: 0 < dokumentObjects.noOfSearchResults()"
                    style="min-height: 2.5rem;">
                    <searcher params="objects: dokumentObjects, loadMore: loadMore, i18nPrefix: 'dokument'"></searcher>
                    <div>
                        <button class="btn btn-schaeppi btn-sm" data-bind="
                            click: markDokumenteRead,
                            visible: 0 < noOfSelectedUnreadDocuments()
                        " id="dokumentMarkSelectedRead" type="button">
                            <i class="bi bi-envelope-open"></i>
                            <span data-bind="i18n: {key: 'dokument_mark_read', options: {count: noOfSelectedUnreadDocuments()}}"></span>
                        </button>
                        <button class="btn btn-light btn-sm" data-bind="
                            attr: {disabled: viewmodel.isZippingFiles()},
                            click: downloadZip,
                            tooltip: 'dokumente_zip_files_tooltip'
                        " id="dokumentDownloadZip" type="button">
                            <i class="bi bi-file-earmark-arrow-down"></i>
                            <span data-bind="i18n: {key: 'zip_files', options: {count: noOfSelectedDocuments()}}"></span>
                        </button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="portal-table table table-bordered table-hover table-sm align-middle">
                        <thead>
                        <tr>
                            <th style="width: 3rem;"></th>
                            <th data-bind="i18n: 'dokumentdatum'" style="width: 7rem;"></th>
                            <th data-bind="i18n: 'eigb'" style="width: 12rem;"></th>
                            <th data-bind="i18n: 'liegb'" style="width: 17rem;"></th>
                            <th data-bind="i18n: 'dokumenttyp'" style="width: 20rem;"></th>
                            <th class="text-truncate" data-bind="i18n: 'dokumentbezeichnung'"></th>
                            <th style="width: 7rem;"></th>
                        </tr>
                        </thead>
                        <tbody data-bind="foreach: dokumentObjectsByMonth">
                        <tr class="dokument-month-row"
                            data-bind="event: {mouseover: $component.enableMonthSelect, mouseout: $component.disableMonthSelect}">
                            <td class="text-center">
                                <input class="form-check-input" data-bind="
                                    attr: {'aria-label': $component.selectMonthLabel},
                                    checked: selected,
                                    visible: selected() || isSelectVisible()
                                " type="checkbox">
                            </td>
                            <th colspan="6" data-bind="text: month"></th>
                        </tr>
                        <!-- ko foreach: dokumente -->
                        <tr class="dokument-row" data-bind="
                                css: {'table-active': $component.activeDokumentId() === id},
                                event: {mouseover: $component.enableSelect, mouseout: $component.disableSelect}
                            ">
                            <td class="text-center">
                                <i class="bi" data-bind="
                                    css: {'text-schaeppi': read() === false, 'bi-envelope-fill': read() === false, 'bi-envelope-open': read()},
                                    visible: selected() === false && isSelectVisible() === false
                                "></i>
                                <input class="form-check-input" data-bind="
                                    attr: {'aria-label': $component.selectLabel, id: 'dokumentSelect' + id},
                                    checked: selected,
                                    visible: selected() || isSelectVisible()
                                " type="checkbox">
                            </td>
                            <td data-bind="text: dokDatumFormatted"></td>
                            <td class="text-truncate" data-bind="text: eigb, attr: {title: eigb}"></td>
                            <td class="text-truncate" data-bind="text: liegb, attr: {title: liegb}"></td>
                            <td class="text-truncate" data-bind="text: dokType"></td>
                            <td class="text-truncate" data-bind="text: bez, attr: {title: bez}"></td>
                            <td class="text-center">
                                <button class="btn btn-sm" data-bind="
                                    attr: {id: 'dokumentDetailBtn' + id},
                                    click: $component.showDokument,
                                    css: {'btn-light': read(), 'btn-schaeppi': read() === false}
                                " type="button">
                                    <i class="bi bi-filetype-pdf"></i>
                                    <span data-bind="i18n: 'dokument_details_btn'"></span>
                                </button>
                            </td>
                        </tr>
                        <!-- /ko -->
                        </tbody>
                    </table>
                </div>
                <div class="d-flex align-items-center" data-bind="if: dokumentObjects.canSearchMore" style="min-height: 2.5rem;">
                    <searcher params="objects: dokumentObjects, loadMore: loadMore, i18nPrefix: 'dokument'"></searcher>
                </div>
            </div>
        </div>
        <div data-bind="visible: activeDokument(), css: {'col-5': showFullscreen() === false, 'col': showFullscreen()}">
            <div class="d-flex justify-content-between mb-2" id="dokumentDetail">
                <h2 class="h4 mb-0" data-bind="i18n: 'dokument_details'"></h2>
                <button class="btn-close"
                    data-bind="click: closeDokument, attr: {title: closeTitle}"
                    id="dokumentDetailClose"
                    type="button"></button>
            </div>
            <div class="d-flex flex-wrap gap-2 pb-2">
                <button class="btn btn-schaeppi" data-bind="
                    busyButton: isLoadingDokument,
                    click: downloadDokument,
                    visible: hasDokumentPdf
                " id="dokumentDownloadPdf" type="button">
                    <i class="bi bi-file-earmark-arrow-down"></i>
                    <span data-bind="i18n: 'download'"></span>
                </button>
                <button class="btn btn-schaeppi" data-bind="click: activateAnsprechpartner" id="dokumentAnsprechpartner" type="button">
                    <i class="bi bi-person-rolodex"></i>
                    <span data-bind="i18n: 'ansprechpartner'"></span>
                </button>
                <button class="btn btn-outline-secondary" data-bind="click: toggleFullscreen" id="dokumentDetailFullscreen" type="button">
                    <i class="bi bi-fullscreen"></i>
                    <span data-bind="i18n: 'fullscreen_toggle'"></span>
                </button>
                <nav class="overflow-auto" data-bind="attr: {'aria-label': pageNavLabel}">
                    <ul class="pagination mb-0" data-bind="foreach: pdfPages">
                        <li class="page-item">
                            <a class="page-link" data-bind="
                                attr: {href: '#' + pageId},
                                click: $component.scrollToPage,
                                text: pageNo
                            "></a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="overflow-auto" data-bind="fixHeight: isLoadingDokument, fixHeightMargin: 20" id="dokumentPdf"></div>
        </div>
    </div>
</div>
